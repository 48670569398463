import React from 'react';
import Estrelas from '../Estrelas/Estrelas';

// css
import c from './Avaliacao.module.css';

const Avaliacao = props => {
	const {
		classNameWrap = '',
		nome,
		estrelas,
		data,
		desc,
	} = props;

	return (
		<div className={`${classNameWrap}`}>
			<div className={`${c['boxbg']}`}>
				<div className={`${c['nome-data-box']}`}>
					<div className={`${c['nome']} bold`}>
						{nome}
					</div>
					<div className={`${c['data']}`}>
						{data}
					</div>
				</div>

				<div className={`${c['estrelas']}`}>
					<Estrelas
						qtd={estrelas}
						corAtivo="#FDA63F"
					/>
				</div>

				<div className={`${c['desc']}`}>
					{desc}
				</div>
			</div>
		</div>
	);
};

export { Avaliacao };
