import React, { useEffect, useRef, useState } from 'react';

// Libs
import { Element, animateScroll as scroll } from 'react-scroll';
import TextareaAutosize from 'react-textarea-autosize';
import { toast as msg } from 'react-toastify';

// CSS
import './Chat.css';

// Mensagem
import ChatMensagem from './ChatMensagem/ChatMensagem.js';

// Ícones
import imgLogoCrowd from '@img/logo.png';
import imgIconeProposta from '@svg/light/comments-question-check.svg';
import imgIconeContrato from '@svg/light/file-signature.svg';
import imgIconeGif from '@svg/light/gif.svg';
import imgIconeInicio from '@svg/light/message-smile.svg';
import imgIconeChatSemMensagem from '@svg/light/messages.svg';
import imgIconeEnviar from '@svg/light/paper-plane-top.svg';
import imgIconeAtivarNotificacao from '@svg/regular/bell-on.svg';

// Components
import Button2 from '@cpnt/Button2/Button2';
import FotosAgrupadas from '@cpnt/FotosAgrupadas/FotosAgrupadas';
import Icone from '@cpnt/Icone/Icone';
import Info from '@cpnt/Info/Info';
import Input2 from '@cpnt/Input2/Input2';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';
import Tenor from '@cpnt/Tenor/Tenor';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';

//  Partes
import ChatMensagemPersonalizada from './ChatMensagemPersonalizada/ChatMensagemPersonalizada';
import ChatPropostaFreelancer from './ChatPropostaFreelancer/ChatPropostaFreelancer';

// Funções
import { DEV } from '@_config/config';
import Tip from "@cpnt/Tip/Tip";
import { requestPermissionNotificacao } from '@_func/notification';
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import ListaTemplates from '@cpnt/ListaTemplates/ListaTemplates';
import textoTermosDeUso from '@texto/textoTermosDeUso';
import { hash } from "@_func/generate";
import { primeiroNome } from "@_func/regex";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const Chat = props => {
console.log('props:', props)

	const refTextoChat = useRef();
	const screen = useWindowDimension();

	/*
	** Props
	** ================================================== */
	const {
		className = '',
		wrap = '',
		classNameWrap = 'cpnt-chat-wrapdefault',

		// CHAT
		enviarMensagem = () => null,
		enviarProposta = () => null,
		loadingEnviarProposta,
		mensagem = '',
		setMensagem = () => null,
		mensagens = [],
		setDigitando = () => null,

		loadingGetMensagens,
		conversandoCom = [],
		conversandoComExibirOnline,

		isDigitando,
		setIsDigitando = () => null,
		isDigitandoInfo,
		showTelaInicial,

		layoutExibicao = 'freelancer',
		exibirBotaoProposta = true,

		onClickBtnRespostaProposta = () => null,
		onClickInfo = () => null,

		bloquearChat,
		setBloquearChat = () => null,

		bloquearChatMotivo,
		setbloquearChatMotivo = () => null,

		taxaPorcentagem = 0,
		headerRight,
	} = props;

	/*
	** States
	** ================================================== */
	const [stateContainerId, setStateContainerId] = useState('id');
	const [showPortalInfoOnline, setShowPortalInfoOnline] = useState(false);
	const [showPortalResposta, setShowPortalResposta] = useState(false);
	const [stateDadosPreviewResposta, setStateDadosPreviewResposta] = useState({mensagem: {}, botao: {}});

	/*
	** Atualizar o container caso tenha novas mensagens para fazer o scroll
	** ================================================== */
	useEffect(() => {
		setStateContainerId(`container-chat-id-${hash()}`);
	}, [mensagens]);

	useEffect(() => {
		if(isDigitando) {
			const timeOutId = setTimeout(() => {
				setIsDigitando(false);
			}, 3000);
			return () => clearTimeout(timeOutId);
		}
	}, [isDigitando]);

	/*
	** Faz scroll conforme muda o container, e as mensagens
	** ================================================== */
	useEffect(() => {
		scroll.scrollToBottom({
			containerId: stateContainerId,
			duration: 400,
			isDynamic: true,
			smooth: 'easeInOutQuart',
			spy: true,
			delay: 100,
			// offset: 200
		});
	}, [stateContainerId]);

	/*
	** Portal
	** ================================================== */
	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalMensagemCustomizada, setShowPortalMensagemCustomizada] = useState(false);
	const [showPortalTenor, setShowPortalTenor] = useState(false);
	const [stateIdUsuarioPerfilCompleto, setStateIdUsuarioPerfilCompleto] = useState(false);

	const [stateDetalhesMensagem, setStateDetalhesMensagem] = useState(false);
	const [showPortalDetalhesMensagem, setShowPortalDetalhesMensagem] = useState(false);
	const [showPortalContrato, setShowPortalContrato] = useState(false);

	/*
	** States
	** ================================================== */
	const [statePerfilCompletoId, setStatePerfilCompletoId] = useState(false);
	const [statePerfilCompletoTipo, setStatePerfilCompletoTipo] = useState(false);

	const [stateTipoMensagemCustomizada, setStateTipoMensagemCustomizada] = useState('freelancer');

	const [showPortalPropostaJobFreelancer, setShowPortalPropostaJobFreelancer] = useState(false);

	/*
	** Validar tipo de usuário para exibir overlay
	** ================================================== */
	const getTipoIdUsuario = dados => {
		var id = 0;
		var tipo = 'usuario';

		if(dados._ && dados._._ && dados._._.id_freelancer) {
			tipo = 'freelancer';
			id = dados._id;
		} else if(dados._ && !dados._.id_freelancer) {
			tipo = 'usuario';
			id = dados._id;
		} else if(dados._ && dados._.id_freelancer) {
			id = dados._.id_freelancer;
			tipo = 'freelancer';
		} else if(dados._ && dados._._ && dados._._.id_freelancer && dados._._.id_freelancer)  {
			id = dados._._.id_freelancer;
			tipo = 'freelancer';
		}

		return {
			id: id,
			tipo: tipo,
		};
	}

	const inserirEmoji = e => {
		setMensagem(e.native);
	}

	/*
	** Ativar notificações
	** ================================================== */
	const Notificacoes = () => {
		return (
			<Tip
				titulo="Notificações na área de trabalho"
				tip={
					<div onClick={() => requestPermissionNotificacao(true)} className="cpnt-chat-notificacoes cursor-pointer inline-flex flex-center">
						<Icone
							imagem={imgIconeAtivarNotificacao}
							tamanho="28px"
							cor="#eab20e"
						/>
						<div className="margin-l-10 padding-b-4 none-980">Notificações na área de trabalho</div>
						<div className="cpnt-chat-notificacoes-txt">Notificações</div>
					</div>
				}
				info
			>
				Receba as notificações de novas mensagens na sua área de trabalho mesmo com a janela minimizada. Clique para ativar.
			</Tip>
		)
	}

	return (
		<div className="cpnt-chat-wrap">
			<div className={`cpnt-chat ${classNameWrap} ${wrap}`}>
				{showTelaInicial ? (
					<>
						<div className="cpnt-chat-titulo-wrap">
							<div className="cpnt-chat-titulo">Nenhuma conversa selecionada</div>
							<div className="cpnt-chat-notificacao">
								<Notificacoes />
								{headerRight}
							</div>
						</div>
						<div className="cpnt-chat-box flex flex-center">
							<div className="flex flex-column flex-center align-center">
								<Icone
									imagem={imgIconeInicio}
									cor="#2596e1"
									tamanho="100px"
								/>
								<h3 className="margin-b-0">Você já fez sua parte!</h3>
								<div className="max-w-600 padding-20">Agora, é só aguardar enquanto os profissionais entram em contato. Não se preocupe, isso é rápido e logo você receberá as respostas.</div>
							</div>
						</div>
						<div className="cpnt-chat-footer">
							<div className="cpnt-chat-footer-wrap flex-end">
								<img src={imgLogoCrowd} alt="logo-crowd" className="w-100" />
							</div>
						</div>
					</>
				) : (
					mensagens === 400 || mensagens === 403 ? (
						<>
							<div className="cpnt-chat-titulo-wrap">
								<div data-tour="chat-online">{mensagens === 403 ? 'Briefing arquivado' : 'Sem permissão.'}</div>
							</div>
							<div className="cpnt-chat-box-wrap flex-center">
								<Info
									icone="erro"
									texto={
										<>
											<h3 className="titulo">{mensagens === 403 ? 'Briefing arquivado' : 'Sem permissão'}</h3>
											<div>{mensagens === 403 ? <>O cliente arquivou o briefing. Agradecemos sua dedicação.<br />Mantenha sempre seu perfil atualizado para receber mais oportunidades na CROWD.</> : 'Você não tem permissão para visualizar essas informações'}</div>
										</>
									}
								/>
							</div>
						</>
					) : (
						<>
							<div className="cpnt-chat-titulo-wrap">
								{!conversandoCom.length ? (
									<div data-tour="chat-online">Chat</div>
								) : (
									conversandoCom.length === 1 ? (
										<div
											className="cursor-pointer"
											onClick={() => {
												const info = getTipoIdUsuario(conversandoCom[0]);
												setStatePerfilCompletoTipo(info.tipo);
												setStatePerfilCompletoId(info.id);
												setShowPortalPerfilCompleto(true);
											}}
										>
											<ItemFotoDesc
												titulo={primeiroNome(conversandoCom[0].nome)}
												foto={conversandoCom[0].foto}
												{...(conversandoCom[0].desc ? {subtitulo: conversandoCom[0].desc} : {})}
												// {...(conversandoComExibirOnline ? {subtitulo: <span className="color-green-alt">Online</span>} : {})}
												// subtitulo={<span className="color-green-alt">Online</span>}
												// corAlt={1}
											/>
										</div>
									) : (
										<div
											// className="cursor-pointer"
											// onClick={() => {
											// 	setShowPortalInfoOnline(true);
											// }}
										>
											{/* <FotosAgrupadas
												fotos={[...conversandoCom.map(item => item.foto)]}
											/> */}
										</div>
									)
								)}
								<div className="cpnt-chat-notificacao">
									<Notificacoes />
									{headerRight}
								</div>
							</div>
							<div className="cpnt-chat-box-wrap">
								<Element className={`cpnt-chat-box ${className} scroll scroll-alt`} id={stateContainerId}>
									{loadingGetMensagens ? (
										<Loading texto="Carregando mensagens, aguarde..." alt absolute />
									) : (
										mensagens && mensagens.length ? (
											mensagens.map((val, key) => {
												return (
													<div key={key} className="cpnt-chat-item">
														<ChatMensagem
															key={key}
															{...val}
															onClickFoto={e => {
																setStatePerfilCompletoTipo(e.tipo);
																setStatePerfilCompletoId(e.id);
																setShowPortalPerfilCompleto(true);
															}}
															enviarMensagem={enviarMensagem}
															idProposta={val.id_propose}
															onClickBtnRespostaProposta={onClickBtnRespostaProposta}
															onClickInfo={onClickInfo}
															setDetalhesMensagem={setStateDetalhesMensagem}
															setShowDetalhesMensagem={setShowPortalDetalhesMensagem}
														/>
													</div>
												)}
											)
										) : (
											<div className="cpnt-chat-sem-mensagem-wrap">
												<Info
													icone={imgIconeChatSemMensagem}
													iconeCor="#2596e1"
													texto="Nenhuma mensagem encontrada."
													alt
												/>
											</div>
										)
									)}
								</Element>
								{isDigitando ? (
									<div className="cpnt-chat-digitando">
										<ItemFotoDesc
											foto={isDigitandoInfo.foto}
											subtitulo={`${isDigitandoInfo.nome ? isDigitandoInfo.nome.split(' ')[0] : ''} está digitando...`}
											corAlt={3}
											min
										/>
									</div>
								) : null}
							</div>

							{!loadingGetMensagens ? (
								<div className="cpnt-chat-footer">
									<div className="cpnt-chat-footer-wrap">
										{bloquearChat ? (
											<div
												className="cpnt-chat-mensagem-bloqueado"
												onClick={() => {
													msg.warning(`${bloquearChatMotivo && bloquearChatMotivo !== '' ? bloquearChatMotivo : 'Chat bloqueado: motivo não informado'}`);
												}}
											>
												{bloquearChatMotivo}
												{/* <span className="border border-red padding-5 margin-5 margin-l-30" onClick={() => setShowPortalMensagemCustomizada(true)}>[AÇÕES]</span> */}
											</div>
										) : null}
										<div className="cpnt-chat-acoes">

											{/* EMOJIS */}
											{/* <div className="cpnt-chat-emoji-box">
												<div className="cpnt-chat-emoji-wrap">
													{stateShowEmojiPopup && (
														<Picker
															onSelect={inserirEmoji}
															title="Tom de pele"
															emoji={false}
															native={true}
															emojiSize={20}
															// set='google'
															useButton={false}
															showPreview={false}
															i18n={{
																search: 'Pesquisar',
																clear: 'Limpar',
																notfound: 'Nenhum emoji encontrado',
																skintext: 'Selecione a cor preferida',
																	categories: {
																	search: 'Resultados da pesquisa',
																	recent: 'Usados frequentemente',
																	smileys: 'Sorrisos & Emoções',
																	people: 'Emoticons & Pessoas',
																	nature: 'Animais & Natureza',
																	foods: 'Comidas & Bebidas',
																	activity: 'Atividades',
																	places: 'Viagens & Lugares',
																	objects: 'Objetos',
																	symbols: 'Simbolos',
																	flags: 'Bandeiras',
																	custom: 'Customizados',
																},
																categorieslabel: 'Categorias',
																skintones: {
																	1: 'Tom de pele padrão',
																	2: 'Tom de pele claro',
																	3: 'Tom de pele claro-médio',
																	4: 'Tom de pele médio',
																	5: 'Tom de pele médio-escuro',
																	6: 'Tom de pele escuro',
																},
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="cpnt-chat-btn"
													onClick={() => setStateShowEmojiPopup(!stateShowEmojiPopup)}
												>
													<Icone
														tamanho="24px"
														cor={false}
														className="cpnt-chat-btn-icone"
														imagem={imgIconeSmile}
													/>
												</button>
											</div> */}

											{/* ANEXOS */}
											{/* <label
												className="cpnt-chat-btn margin-l-5"
											>
												<input
													type="file"
													className="none"
													onChange={selecionarArquivo}
												/>
												<Icone
													tamanho="24px"
													cor={false}
													className="cpnt-chat-btn-icone"
													imagem={imgIconeAnexo}
												/>
											</label> */}
											{layoutExibicao === 'usuario' ? (
												<>
													<button
														type="button"
														className="cpnt-chat-btn btn-min border-color-1 color-black bg-white border-blue-hover"
														onClick={() => {
															setStateTipoMensagemCustomizada('hunter');
															setShowPortalMensagemCustomizada(true);
														}}
													>
														<Icone
															tamanho="28px"
															cor={false}
															className="cpnt-chat-btn-icone"
															imagem={imgIconeProposta}
														/>
														<span className="margin-l-10">Templates</span>
													</button>
												</>
											) : (
												exibirBotaoProposta ? (
													<Btn
													_={{
															value: 'Enviar proposta',
															onClick: () => {
																setShowPortalPropostaJobFreelancer(true);
															}
														}}
													/>
												) : null
											)}
										</div>
										<div className="cpnt-chat-mensagem-box">
											<TextareaAutosize
												value={mensagem}
												onKeyDown={e => {
													if(e.key === "Enter") {
														if(!e.shiftKey) {
															if(bloquearChat) {
																msg.error('O chat está bloqueado, não é possível enviar novas mensagens.');
															} else {
																enviarMensagem({
																	mensagem: e.target.value,
																});
															}
															setMensagem('');
															e.preventDefault();
														} else {
															setMensagem(e.target.value);
														}
													} else {
														setMensagem(e.target.value);
													}
												}}
												onChange={e => {
													setMensagem(e.target.value);
													setDigitando(e.target.value);
												}}
												onClick={() => {
													// setStateShowEmojiPopup(false);
												}}
												className="cpnt-chat-mensagem"
												minRows={1}
												maxRows={6}
												ref={refTextoChat}
											/>
										</div>
										<div className="cpnt-chat-btnenviar-box">
											<div className="flex">
												<div className="cpnt-chat-btn-box">
													<button
														type="button"
														className="cpnt-chat-btn"
														onClick={() => {
															if(bloquearChat) {
																msg.error('O chat está bloqueado, não é possível enviar novas mensagens.');
															} else {
																enviarMensagem({});
															}
														}}
													>
														<Icone
															tamanho="24px"
															cor={false}
															className="cpnt-chat-btn-icone"
															imagem={imgIconeEnviar}
														/>
													</button>
												</div>
											</div>
										</div>

									</div>
								</div>
							) : null}
						</>
					)

				)}
			</div>

			{/* Portal informações hunter */}
			<PortalPerfil
				idUsuario={stateIdUsuarioPerfilCompleto}
				show={showPortalPerfilCompleto}
				tipo={statePerfilCompletoTipo}
				{...(statePerfilCompletoTipo === 'usuario' ? {
					idUsuario: statePerfilCompletoId
				} : {
					idFreelancer: statePerfilCompletoId
				})}
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

			{/* Portal Mensagem customizada */}
			<Portal
				titulo="Mensagem personalizada"
				show={showPortalMensagemCustomizada}
				onClose={() => {
					setShowPortalMensagemCustomizada(false);
				}}
				className="w-1000"
			>
				<ChatMensagemPersonalizada
					tipo={stateTipoMensagemCustomizada}

					layoutExibicao={layoutExibicao}
					setShowPortal={setShowPortalMensagemCustomizada}
					enviarMensagem={enviarMensagem}
					mensagem={mensagem}
					setMensagem={setMensagem}

					bloquearChat={bloquearChat}
					setBloquearChat={setBloquearChat}
					bloquearChatMotivo={bloquearChatMotivo}
					setbloquearChatMotivo={setbloquearChatMotivo}

					taxaPorcentagem={taxaPorcentagem}
				/>
			</Portal>

			{/* Portal proposta job */}
			<Portal
				titulo="Enviar proposta para o Job"
				show={showPortalPropostaJobFreelancer}
				onClose={() => {
					setShowPortalPropostaJobFreelancer(false);
				}}
				className="box-txt w-1000"
			>
				<ChatPropostaFreelancer
					setShowPortal={setShowPortalPropostaJobFreelancer}
					enviarProposta={enviarProposta}
					loadingEnviarProposta={loadingEnviarProposta}
					mensagem={mensagem}
					setMensagem={setMensagem}
					taxaPorcentagem={taxaPorcentagem}
				/>
			</Portal>

			{/* Portal Gifs */}
			<Portal
				titulo="Pesquisar GIF"
				show={showPortalTenor}
				onClose={() => {
					setShowPortalTenor(false);
				}}
				className="w-800"
			>
				<Tenor
					enviarMensagem={e => {
						if(bloquearChat) {
							msg.error('O chat está bloqueado, não é possível enviar novas mensagens.');
						} else {
							enviarMensagem(e);
						}
					}}
					setShowPortal={setShowPortalTenor}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							type: 'button',
							onClick: e => {
								setShowPortalTenor(false);
							}
						}}
					/>
				</div>
			</Portal>

			{/* Portal Quem está online */}
			<Portal
				show={showPortalInfoOnline}
				onClose={() => {
					setShowPortalInfoOnline(false);
				}}
				className="box-txt w-600"
			>
				<h3>Usuários online ({conversandoCom.length})</h3>
				{!conversandoCom || !conversandoCom.length ? (
					<Info
						texto="Nenhum usuário online no momento"
					/>
				) : (
					<div className="wrap-5">
						{conversandoCom.map((val, key) => {
							return (
								<ItemFotoDesc
									key={key}
									className="margin-5 cursor-pointer"
									foto={val.foto}
									titulo={val.nome}
									onClick={() => {
										const info = getTipoIdUsuario(val);
										setStatePerfilCompletoTipo(info.tipo);
										setStatePerfilCompletoId(info.id);
										setShowPortalPerfilCompleto(true);
									}}
								/>
							)
						})}
					</div>
				)}
			</Portal>

			{/* Portal Preview da imagem */}
			<Portal
				show={showPortalDetalhesMensagem}
				onClose={() => {
					setShowPortalDetalhesMensagem(false);
				}}
				className="cpnt-chatmsg-portal-preview"
				classNameDefault="cpnt-portal-default-alt"
				classNameX="x"
			>
				<div className="cpnt-chatmsg-portal-img-preview">
					{stateDetalhesMensagem && <img src={stateDetalhesMensagem.url} alt="gif" className="block w-100p margin-auto relative z-index-2" />}
					<Loading texto="" absolute wrap="cpnt-chatmsg-portal-img-preview-loading" />
				</div>
			</Portal>

			{/* Portal respostas das ações */}
			<Portal
				show={showPortalResposta}
				onClose={() => {
					setShowPortalResposta(false);
				}}
			>
				<div className="cpnt-chatmsg-resposta-preview">
					<div className="cpnt-chatmsg-resposta-preview-msg">
						<h3 className="margin-0">Enviar resposta para a pergunta</h3>
						<div className="margin-t-20">
							<ChatMensagem
								{...{
									...stateDadosPreviewResposta.mensagem,
									propria: false,
								}}
							/>
							<div className="margin-t-20">Você selecionou a opção "{stateDadosPreviewResposta.botao.texto}".</div>
						</div>
					</div>
					<div className="cpnt-chatmsg-resposta-preview-acoes">
						<h3 className="margin-0">Alguma observação para sua resposta?</h3>
						<div className="margin-t-20">
							<Input2
								_={{
									defaultValue: 'Ok, combinado!',
									type: 'textarea',
								}}
							/>
						</div>
						<div className="flex flex-end margin-t-30">
							<Button2
								_={{
									value: 'Cancelar',
									className: 'btn btn-1',
									type: 'button',
									onClick: e => null,
								}}
								classExtra="btn-1"
							/>
							<Button2
								_={{
									value: 'Enviar resposta',
								}}
								// loading={loadingCadastrarCliente}
								wrap="margin-l-15"
							/>
						</div>
					</div>
				</div>
			</Portal>

			{/* Portal respostas das ações */}
			<Portal
				titulo="Contrato de prestação de serviço"
				show={showPortalContrato}
				onClose={() => {
					setShowPortalContrato(false);
				}}
				className="w-800"
			>
				<ListaTemplates
					tituloPrevisualizacao="Prévia do contrato:"
					tituloPesquisar="Pesquisar contrato"
					closePortal={() => {
						setShowPortalContrato(false);
					}}
					onConfirm={e => {
						setShowPortalContrato(false);
						enviarMensagem({
							mensagem: 'Contrato',
							type: 'contrato',
							text: 'Contrato',
							metadata: {
								titulo: e.label,
							},
						});
					}}
					radioName="radioTemplateContrato"
					btnTexto="Enviar contrato"
					className=""
					exibirPesquisa={true}
					scroll={false}
					itens={[
						{
							id: 1,
							title: 'Contrato para iniciar um job',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt.',
							text: textoTermosDeUso(),
						},
						{
							id: 2,
							title: 'Contrato para desistência de um job',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt.',
							text: textoTermosDeUso(),
						},
						{
							id: 3,
							title: 'Contrato com valor do job',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt.',
							text: textoTermosDeUso(),
						},
						{
							id: 4,
							title: 'Contrato para alterar valor do job',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt.',
							text: textoTermosDeUso(),
						},
						{
							id: 5,
							title: 'Contrato de acordo de confidencialidade',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt.',
							text: textoTermosDeUso(),
						},
						{
							id: 6,
							title: 'Contrato de acordo de prazo',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt.',
							text: textoTermosDeUso(),
						},
						{
							id: 7,
							title: 'Contrato de nota fiscal',
							excerpt: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic sapiente vitae atque deserunt repellendus a qui cupiditate dolore quo voluptates? Ipsum inventore rem voluptates quos vero eveniet accusamus aliquid voluptatibus?',
							text: textoTermosDeUso(),
						},
					]}
				/>
			</Portal>

		</div>
	);
}

export default Chat;