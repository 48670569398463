import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './PortalFreelancersSelecionadosBriefing.css';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { msgs, msgStatus } from "@_func/request";
import apiBriefingFreelancers from "@api/briefingFreelancers/briefingFreelancers";
import apiBriefings from "@api/briefings/briefings";
import Button2 from '@cpnt/Button2/Button2';
import ItemFotoDesc from "@cpnt/ItemFotoDesc/ItemFotoDesc";
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";
import { caminhoFoto, dataPorExtenso } from '@func/utils';
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from "@route/routeUtils";

const PortalFreelancersSelecionadosBriefing = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		show,
		id,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-freelancers-selecionados-proposta',
		portalId = 'portal-freelancers-selecionados-proposta',
		before,
		after,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);
	const [loadingGeFreelancers, setLoadingGeFreelancers] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [stateFreelancers, setStateFreelancers] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setStateDados({});
			setStateFreelancers([]);
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** FREELANCER
	** ================================================== */
	const inicializarGets = async () => {
		if(show === true) {
			const retGetBriefing = await apiBriefings.getOne.get(id);

			if(retGetBriefing.status === 200) {
				var autorizado = true;

				if(store.usuario.role !== 1) {
					if(retGetBriefing.data.id_company !== store.usuario.id_company) {
						autorizado = false;
					}
				}

				if(autorizado) {
					setStateDados(retGetBriefing.data);
					_setStateDados(msgStatus(retGetBriefing));

					setLoadingGeFreelancers(true);
					const retGetItem = await apiBriefingFreelancers.getAll.get(id);

					if(retGetItem.status === 200) {
						setStateFreelancers(retGetItem.data);
					}

					setLoadingGeFreelancers(false);

				} else {
					_setStateDados({
						status: 400,
						msg: 'Você não tem permissão para acessar essas informações',
					});
				}

			} else {
				_setStateDados(msgStatus(retGetBriefing));
				msgs([retGetBriefing]);
			}

			setLoading(false);
		}

	}

	return (
		<Portal
			titulo="Profissionais selecionados"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							loadingGeFreelancers ? (
								<Loading />
							) : (
								<>
									{before}
									<div>
										<div className="titulo">Proposta: {stateDados.title}</div>
										<div className="margin-t-10">
											<div className="desc"><strong>Projeto:</strong> {stateDados.project_name}</div>
											<div className="desc"><strong>ID da proposta:</strong> {stateDados.id}</div>
											<div className="desc"><strong>Publicado em:</strong> {dataPorExtenso(stateDados.created_at)}</div>
										</div>
									</div>
									<div className="padding-t-10 margin-t-15 sep-t">Profissionais selecionados: <strong>{stateFreelancers.length}</strong></div>
									<div className="cpnt-portallistafreela-wrap margin-t-15">
										{stateFreelancers.map((val, key) => {
											return (
												<ItemFotoDesc
													key={key}
													className="margin-3 cursor-pointer"
													foto={caminhoFoto(val.photo).url}
													titulo={val.name_freelancer}
													subtitulo={val.area}
													onClick={() => {
														showPortal({
															portal: portalId,
															dados: val.id_freelancer,
															stateGet,
															navigate,
														});
													}}
												/>
											)
										})}
									</div>
									{after}
								</>
							)

						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}

						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									type: 'button',
									onClick: onClose,
								}}
							/>
						</div>
					</div>
				</div>
			)}

			<PortalPerfilFreelancer
				id={getPortalDados({portal: portalId, stateGet})}
				show={isShowPortal({portal: portalId, stateGet})}
				onClose={() => closePortal({portal: portalId, stateGet, navigate})}
			/>

		</Portal>
	);
};

export { PortalFreelancersSelecionadosBriefing };
