import React from 'react';

// CSS
import c from './Editor.module.scss';

// Config
import { JoditEditor } from "./JoditEditor/JoditEditor";

export const Editor = props => {

	const {
		label,
		value,
		setValue,
		className,
	} = props;

	return (
		<div className={`${c['container']} ${className}`}>
			{label && <label>{label}</label>}
			<JoditEditor value={value} setValue={setValue} onChange={setValue} />
		</div>
	)

};
