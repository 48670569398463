import { config, validarRetorno } from '@_api/axios';
import api from 'axios';

const apiTasks = {
	approve: {
		put: async dados => {
			return await api.put(`/tasks/approve`, dados, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	getOne: {
		get: async id => {
			return await api.get(`/tasks/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiTasks };
