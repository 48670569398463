import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './CadastrarProcessoSeletivoSelecionados.css';

// API
import { apiGetClientes, apiGetSelectProjetosClientes, apiGetTemplate, apiGetUsuarios, apiValidarAcesso } from '@api/_functions/geral';
import apiBriefingFreelancers from '@api/briefingFreelancers/briefingFreelancers';
import apiBriefings from '@api/briefings/briefings';
import { api } from "@_api/api";

// Shared components

// Imagens
import imgIconeSetaDir from '@svg/padrao/arrow-right.svg';
import imgIconeIniciarNovo from '@svg/regular/file-plus.svg';
import imgIconeSalvar from '@svg/regular/floppy-disk.svg';
import imgIconePublicar from '@svg/solid/circle-check.svg';
import imgIconeNovo from '@svg/solid/file.svg';

// Components
import { BtnAdd } from '@cpnt/BtnAdd/BtnAdd';
import Button2 from '@cpnt/Button2/Button2';
import { Editor } from '@_cpnt/Inputs/Editor/Editor';
import Icone from "@cpnt/Icone/Icone";
import Info from "@cpnt/Info/Info";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import Loading from "@cpnt/Loading/Loading";
import Portal from "@cpnt/Portal/Portal";
import { Select2 } from '@cpnt/Select2/Select2';
import TextoIconeLink from "@cpnt/TextoIconeLink/TextoIconeLink";
import Tip from '@cpnt/Tip/Tip';

// Partes
import { NavSteps } from "./Steps/NavSteps/NavSteps";

// Funções
import { getAllUsuariosLocal, limparListaLocal } from '@func/selecaoUsuariosBriefings';
import { arrValue, camposInvalidos, conveterRealParaNumero, dataPorExtenso, formatarSelect, isNumeroDiferente, msg, removerKeysVazias, sanitize, unificarArr } from '@func/utils';
import { qualStep } from './funcCadastrarProcessoSeletivo';

// Objetos
import { BYPASS_BRIEFING_STEPS, LIMITE_QTD_FREELANCERS_NOVO_BRIEFING } from "@_config/config";

// Rota
import AvisoAcessoRestrito from "@cpnt/AvisoAcessoRestrito/AvisoAcessoRestrito";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { PortalTemplates } from "@cpnt/Portais/PortaisTemplates/PortalTemplates/PortalTemplates";
import { removerBriefingSelecionadoLocal, salvarBriefingSelecionadoLocal, temBriefingSelecionadoLocal } from "@func/local";
import { closePortal, gets, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';
import { CadastrarProcessoSeletivoSelecionadosFormCadastro } from "./Steps/CadastrarProcessoSeletivoSelecionadosFormCadastro/CadastrarProcessoSeletivoSelecionadosFormCadastro";
import { RevisaoBriefingSelecionados } from "./Steps/RevisaoBriefingSelecionados/RevisaoBriefingSelecionados";
import { isNumeric } from "@_func/regex";
import { msgs, msgStatus } from "@_func/request";

const CadastrarProcessoSeletivoSelecionados = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();

	/*
	** Em caso de abrir com uma página específica e não tiver o briefing
	** ================================================== */
	useEffect(() => {
		var _gets = gets();
		if(_gets.step && !_gets.edit_id_briefing) {
			setGetNavigate({
				gets: {},
				navigate,
				stateGet,
				location,
				uniq: true,
			});
		}
	}, []);

	/*
	** Validar acesso
	** ================================================== */
	const [stateAcesso, setStateAcesso] = useState({});
	const [stateIsAcessoValido, setStateIsAcessoValido] = useState(true);
	const [loadingStart, setLoadingStart] = useState(false);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		const validarAcesso = await apiValidarAcesso({setStateAcesso, setStateIsAcessoValido});

		if(validarAcesso.sucesso) {
			initFuncExec([
				/*
				** Select no topo da págna
				** ================================================== */
				{
					func: getEmpresas,
					condicao: store.usuario.role === 1,
					init: true,
				},
				{
					func: getBriefingsSelect,
					param: getsAtualizados.edit_id_company || store.usuario.id_company, // admin editando, ou hunter direto
					condicao: !init || isNumeroDiferente(stateGet.edit_id_company, getsAtualizados.edit_id_company),
				},

				/*
				** STEP 1 - INIT
				** Campos do cadastro
				** ================================================== */
				{
					func: getCamposFormularioInit,
					condicao: !getsAtualizados.edit_id_briefing,
					init: true,
				},

				/*
				** STEP 1 - EDICAO
				** Campos do cadastro
				** ================================================== */
				{
					func: getCamposFormularioEdicao,
					condicao: getsAtualizados.edit_id_briefing && isNumeroDiferente(stateGet.edit_id_briefing, getsAtualizados.edit_id_briefing),
					param: {
						id_company: getsAtualizados.edit_id_company || store.usuario.id_company, // admin editando, ou hunter direto
						id_briefing: getsAtualizados.edit_id_briefing, // admin editando, ou hunter direto
					}
				},

			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		page: 1,
		step: '1',
		edit_id_briefing: null,
		edit_id_company: null,
		limit: 50,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);

		/*
		** Verificar se tem alho salvo na sessão
		** ================================================== */
		var idBriefingLocal = temBriefingSelecionadoLocal();
		if(idBriefingLocal) {
			resetarCamposCadastro();
			setGetNavigate({
				gets: {
					edit_id_company: null,
					edit_id_briefing: idBriefingLocal,
				},
				navigate,
				stateGet,
				location,
				uniq: true,
			});
		}
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Ao iniciar, verifica se tem salvo e envia para o passo 3
	** ================================================== */
	useEffect(() => {
		if(temBriefingSelecionadoLocal()) {
			setLoadingStart(true);
			setTimeout(() => {
				definirStep(3);
				setLoadingStart(false);
			}, 2000);
		}
	}, []);

	/*
	** States
	** ================================================== */
	const camposDefault = {
		title: '',
		id_project: null,
		id_user: null,
		id_customer: null,
		id_company: store.usuario.id_company,
		id_profession: null,
		text: '',
		excerpt: '',
		sended: 0,
		id_freelancer: 0,
		id_status: null,
		budget: 0,
		id_area: null,
		id_skill_level: 0,
		availability: 0,
		gender: 0,
		delivery_at: '',
		seniority: ''
	};

	/*
	** Portais
	** ================================================== */
	const [showPortalPropostaPublicada, setShowPortalPropostaPublicada] = useState(false);
	const [showPortalConfirmacaoNovaProposta, setShowPortalConfirmacaoNovaProposta] = useState(false);
	const [showPortalAvisoLimite, setShowPortalAvisoLimite] = useState(false);

	/*
	** Loadings
	** ================================================== */
	const [loadingNextStep, setLoadingNextStep] = useState(false);
	const [loadingGetBriefingsSelect, setLoadingGetBriefingsSelect] = useState(false);
	const [loadingGetEmpresasSelect, setLoadingGetEmpresasSelect] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({status: 200});
	const [stateBriefingsSelect, setStateBriefingsSelect] = useState([]);
	const [stateEmpresasSelect, setStateEmpresasSelect] = useState([]);

	// Step
	const maxStep = 3;

	/*
	** COMPONENTES DE LISTA LOCAL
	** ================================================== */

	/*
	** Lista local
	** ================================================== */
	const [stateListaAtual, setStateListaAtual] = useState('Lista padrão');
	const [stateListas, setStateListas] = useState([]);
	const [stateSelecionados, setStateSelecionados] = useState(getAllUsuariosLocal(stateListaAtual));

	/*
	** SATES STEP 1
	** ================================================== */
	const [stateEmpresasStep1, setStateEmpresasStep1] = useState([]);
	const [stateClientesStep1, setStateClientesStep1] = useState([]);
	const [stateProjetosStep1, setStateProjetosStep1] = useState([]);
	const [stateUsuariosStep1, setStateUsuariosStep1] = useState([]);
	const [stateFreelancersStep1, setStateFreelancersStep1] = useState([]);
	const [stateTemplatesStep1, setStateTemplatesStep1] = useState([]);
	const [stateTemplate, setStateTemplate] = useState({});

	/*
	** LOADING STEP 1
	** ================================================== */
	const [loadingOverlayCadastroEdicao, setLoadingOverlayCadastroEdicao] = useState(false);
	const [loadingGetEmpresasStep1, setLoadingGetEmpresasStep1] = useState(false);
	const [loadingGetClientesStep1, setLoadingGetClientesStep1] = useState(false);
	const [loadingGetProjetosStep1, setLoadingGetProjetosStep1] = useState(false);
	const [loadingGetUsuariosStep1, setLoadingGetUsuariosStep1] = useState(false);
	const [loadingGetTemplate, setLoadingGetTemplate] = useState(false);

	/*
	** STATES
	** ================================================== */
	const [stateDescricaoBriefing, setStateDescricaoBriefing] = useState('');

	/*
	** API - Get empresas
	** ================================================== */
	const getEmpresas = async () => {
		setLoadingGetEmpresasSelect(true);
		setLoadingGetEmpresasStep1(true);
		const retGetEmpresas = await api.select.company.get();
		if(retGetEmpresas.status === 200) {
			const dadosFormatados = formatarSelect({dados: retGetEmpresas.data, keyValue: 'id'});
			setStateEmpresasStep1(dadosFormatados);
			setStateEmpresasSelect(dadosFormatados);
		} else {
			msgs([retGetEmpresas]);
		}
		setLoadingGetEmpresasSelect(false);
		setLoadingGetEmpresasStep1(false);
	}

	/*
	** INIT - Inicializa os campos do formulário
	** ================================================== */
	const getCamposFormularioInit = async (idCompany = store.usuario.id_company) => {
		apiGetUsuarios({id: idCompany, setLoadingGetUsuarios: setLoadingGetUsuariosStep1, setStateUsuarios: setStateUsuariosStep1});

		const tpl = await apiGetTemplate({id: 1, setLoadingGetTemplate, setStateTemplate});
		const cli = await apiGetClientes({id: idCompany, setLoadingGetClientes: setLoadingGetClientesStep1, setStateClientes: setStateClientesStep1});

		if(cli.status === 200) {
			if(cli.data.length === 1) {

				setStateDados(prev => ({
					...prev,
					id_customer: cli.data[0].id,
				}));

				const proj = await apiGetSelectProjetosClientes({
					idCliente: cli.data[0].id,
					idEmpresa: stateDados.id_company,
					setLoadingGetProjetos: setLoadingGetProjetosStep1,
					setStateProjetos: setStateProjetosStep1,
				});

				if(proj.status === 200) {
					if(proj.data.length === 1) {
						setStateDados(prev => ({
							...prev,
							id_project: proj.data[0].id,
						}));
					}
				}
			}
		}

		if(tpl.status === 200) {
			setStateDescricaoBriefing(tpl.data.text);
		}

		setStateDados(prev => ({
			...prev,
			id_template: tpl?.data?.id || null,
			id_user: store.usuario.id,
		}));
	}

	/*
	** EDICAO - Inicializa os campos do formulário
	** ================================================== */
	const getCamposFormularioEdicao = async ({id_company, id_briefing}) => {

		setLoadingOverlayCadastroEdicao(true);

		const retGetBriefing = await apiBriefings.getOne.get(id_briefing);

		// id: 4 => "Em Andamento"
		// id: 5 => "Pausado"
		// id: 6 => "Contratado"
		// id: 7 => "Arquivado"
		// id: 8 => "Em rascunho"

		var opcoes = {};

		if(store.usuario.role !== 1) {
			if(retGetBriefing.data.id_company !== store.usuario.id_company) {
				opcoes = {
					tipo: 'nao-autorizado',
					status: 0,
				};
			}
		}

		if(retGetBriefing.status === 200 && retGetBriefing.data.id_status !== 8) {
			setStateDados({
				...camposDefault,
				...retGetBriefing.data,
			});

			_setStateDados({
				...msgStatus(retGetBriefing),
				tipo: 'nao-rascunho',
				...opcoes,
			});
		} else {

			_setStateDados({
				...msgStatus(retGetBriefing),
				...opcoes,
			});

			/*
			** Define os dados para o briefing atual
			** ================================================== */
			setStateDados({
				...camposDefault,
				...retGetBriefing.data,
			});

			/*
			** Descrição
			** ================================================== */
			setStateDescricaoBriefing(sanitize(retGetBriefing.data.text || ''));

			/*
			** Dados gerais
			** ================================================== */
			const retGetProjetos = await api.select.projects.get({id_customer: retGetBriefing.data.id_customer, id_company: retGetBriefing.data.id_company});
			const retGetClientes = await api.select.customers.get(id_company);
			const retGetUsuarios = await api.users.getAllUsers.get(id_company, 2);

			/*
			** Dados que estão associados a esse briefing em específico
			** ================================================== */
			const retGetFreelancers = await apiBriefingFreelancers.getAll.get(id_briefing);

			/*
			** Guarda o id_freelancer na chave id para usar nos subcomponentes
			** ================================================== */
			var freela = formatarSelect({dados: retGetFreelancers.data, keyValue: 'id'});
			freela = freela.map(item => {return {...item, id: item.id_freelancer}});
			setStateFreelancersStep1(freela);

			/*
			** Campos do select
			** ================================================== */
			setStateClientesStep1(formatarSelect({dados: retGetClientes.data, keyValue: 'id'}));
			setStateUsuariosStep1(formatarSelect({dados: retGetUsuarios.data, keyValue: 'id', keyIcone: 'photo', iconClass: 'border-radius-50'}));
			setStateProjetosStep1(formatarSelect({dados: retGetProjetos.data, keyValue: 'id'}));

		}

		setLoadingOverlayCadastroEdicao(false);
	}

	/*
	** API - Get briefings do select
	** ================================================== */
	const getBriefingsSelect = async (idCompany = store.usuario.id_company) => {
		setLoadingGetBriefingsSelect(true);

		const retGetBriefingsRascunho = await apiBriefings.all.get({
			id_company: idCompany,
			limit: 999,
			id_status: 8,
		});
		setLoadingGetBriefingsSelect(false);

		if(retGetBriefingsRascunho.status === 200) {
			const briefingsSelect = formatarSelect({
				dados: retGetBriefingsRascunho.data,
				keyName: 'title',
				keyValue: 'id',
			});

			setStateBriefingsSelect(briefingsSelect);
		} else {
			setStateBriefingsSelect([]);
		}
	}

	/*
	** Definir steps / passo a passo / passos steps / passo-a-passo / step-step / step-by-step
	** ================================================== */
	const definirStep = (acao, byPass = false) => {
		var novoStep = qualStep({
			acao: acao,
			step: stateGet.step,
			maxStep,
		});
		if(byPass) {
			if(novoStep > maxStep) {
				setShowPortalPropostaPublicada(true);
			} else {
				setGetNavigate({
					gets: {step: novoStep},
					navigate,
					stateGet,
					location,
				});
			}
		} else {
			if(acao === 'next') {
				if(novoStep === 2) acaoStepCadastroUpdateBriefing(true);
				if(novoStep === 3) acaoStepDescricaoBriefing();
			} else if(acao === 'prev') {
				if(novoStep === 1) acaoStepVoltarParaDadosBriefing();
				if(novoStep === 2) acaoStepVoltarParaDescricao();
				if(novoStep === 3) {
					setGetNavigate({
						gets: {step: novoStep},
						navigate,
						stateGet,
						location,
					});
				};
			} else {
				if(isNumeric(acao)) {
					if(acao <= 1 || acao <= maxStep) {
						setGetNavigate({
							gets: {step: acao},
							navigate,
							stateGet,
							location,
						});
						window.scrollTo(0, 0);
					}
				} else {
					msg.error('Step não encontrado');
				}
			}
		}
	}

	/*
	** STEP 1 para o 2 - Cadastro / Update de briefing [1-2]
	** ================================================== */
	const acaoStepCadastroUpdateBriefing = async () => {

		// id: 4 => "Em Andamento"
		// id: 5 => "Pausado"
		// id: 6 => "Contratado"
		// id: 7 => "Arquivado"
		// id: 8 => "Em rascunho"

		if(loadingNextStep || loadingOverlayCadastroEdicao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		var dadosEnviar = {
			...stateDados,
			id_company: stateDados.id_company,
			id_customer: stateDados.id_customer,
			title: stateDados.title,
			id_project: stateDados.id_project,
			id_user: stateDados.id_user,
			delivery_at: stateDados.delivery_at,
			budget: conveterRealParaNumero(stateDados.budget),
			id_status: 8, // em rascunho
			text: sanitize(stateDescricaoBriefing),
			...(stateDados.id_template ? {
				id_template: stateDados.id_template,
			} : {}),
		}

		if(camposInvalidos(dadosEnviar, {}, [
			'title',
			'id_company',
			'id_customer',
			'id_project',
			'id_user',
		])) return;

		dadosEnviar = removerKeysVazias(dadosEnviar);

		if(!stateSelecionados.length) {
			msg.error('Nenhum usuário selecionado. Primeiro selecione os freelancers para continuar com o cadastro da proposta.');
			return;
		}

		setLoadingNextStep(true);

		if(!isNumeric(stateDados.id)) {
			/*
			** CADASTRO
			** ================================================== */
			var retInsertBriefing = await apiBriefings.insert.post(dadosEnviar);

			if(retInsertBriefing.status === 200) {
				msg.success('Proposta de job pré-cadastrada com sucesso, insira o restante das informações.');

				salvarBriefingSelecionadoLocal(retInsertBriefing.data.id);

				const retInsertFreelancers = await apiBriefingFreelancers.insertMany.post({
					id_briefing: retInsertBriefing.data.id,
					dados: [...stateSelecionados.map(val => {
						return {
							id_freelancer: val.id
						}
					})],
				});

				if(retInsertFreelancers.status !== 200) {
					msg.warning('Não foi possível adicionar os freelancers selecionados a proposta.');
					msgs([retInsertFreelancers]);
				}

				setGetNavigate({
					gets: {step: 2, edit_id_briefing: retInsertBriefing.data.id},
					navigate,
					stateGet,
					location,
				});

				getBriefingsSelect();
			} else {
				msgs([retInsertBriefing]);
			}
		} else {
			/*
			** EDIÇÃO
			** ================================================== */
			var retUpdateBriefing = await apiBriefings.update.put(dadosEnviar);

			if(retUpdateBriefing.status === 200) {
				msg.success('Proposta de job atualizada com sucesso');

				salvarBriefingSelecionadoLocal(stateDados.id);

				const retInsertFreelancers = await apiBriefingFreelancers.insertMany.post({
					id_briefing: stateDados.id,
					dados: [...stateSelecionados.map(val => {
						return {
							id_freelancer: val.id
						}
					})],
				});

				if(retInsertFreelancers.status !== 200) {
					msg.warning('Não foi possível adicionar os freelancers selecionados a proposta.');
					msgs([retInsertFreelancers]);
				}

				setGetNavigate({
					gets: {step: 2},
					navigate,
					stateGet,
					location,
				});

			} else {
				msgs([retUpdateBriefing]);
			}
		}

		setLoadingNextStep(false);
		window.scrollTo(0, 0);
	}

	/*
	** STEP 2 para o 3 - Descrição do briefing [2-3]
	** ================================================== */
	const acaoStepDescricaoBriefing = async () => {
		if(loadingNextStep || loadingOverlayCadastroEdicao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id: stateDados.id,
			text: sanitize(stateDescricaoBriefing),
			...(stateDados.id_template ? {
				id_template: stateDados.id_template,
			} : {}),
		}

		if(camposInvalidos(dadosEnviar, {
			text: 'Descrição da proposta',
			id: 'ID da proposta salva em rascunho',
		}, [
			'id',
			'text',
		])) return;

		/*
		** Cadastro ou update de briefing
		** ================================================== */
		setLoadingNextStep(true);

		const retUpdateBriefing = await apiBriefings.update.put(dadosEnviar);

		if(retUpdateBriefing.status === 200) {
			setGetNavigate({
				gets: {step: 3},
				navigate,
				stateGet,
				location,
			});
		} else {
			msgs([retUpdateBriefing]);
		}

		setLoadingNextStep(false);
		window.scrollTo(0, 0);
	}

	/*
	** STEP 2 para o 1 - Descrição do briefing [2-1]
	** ================================================== */
	const acaoStepVoltarParaDadosBriefing = async () => {
		if(!stateDescricaoBriefing || stateDescricaoBriefing === '') {
			setGetNavigate({
				gets: {step: 1},
				navigate,
				stateGet,
				location,
			});
			return;
		}

		if(loadingNextStep || loadingOverlayCadastroEdicao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id: stateDados.id,
			text: sanitize(stateDescricaoBriefing),
		}

		if(camposInvalidos(dadosEnviar, {
			text: 'Descrição da proposta',
			id: 'ID da proposta salva em rascunho',
		}, [
			'id',
			'text',
		])) return;

		/*
		** Cadastro ou update de briefing
		** ================================================== */
		setLoadingNextStep(true);

		const retUpdateBriefing = await apiBriefings.update.put(dadosEnviar);

		if(retUpdateBriefing.status === 200) {
			msg.success('A descrição da proposta de job foi atualizada.');
			setGetNavigate({
				gets: {step: 1},
				navigate,
				stateGet,
				location,
			});
		} else {
			msgs([retUpdateBriefing]);
		}

		setLoadingNextStep(false);
		window.scrollTo(0, 0);
	}

	/*
	** STEP 3 para o 2 - Salvar profissionais [3-2]
	** ================================================== */
	const acaoStepVoltarParaDescricao = async () => {
		if(loadingNextStep || loadingOverlayCadastroEdicao) {
			msg.warning('Por favor, aguarde...');
			return false;
		}
		setGetNavigate({
			gets: {step: 2},
			navigate,
			stateGet,
			location,
		});
		window.scrollTo(0, 0);
	}

	/*
	** API - Publicar proposta
	** ================================================== */
	const publicarProposta = async () => {
		const lista = unificarArr([stateFreelancersStep1, stateSelecionados]);

		if(!lista || !lista.length) {
			msg.error('É necessário selecionar pelo menos um profissional para a proposta de job.');
			return;
		}

		if(store.usuario.id_company !== 1 && lista.length > LIMITE_QTD_FREELANCERS_NOVO_BRIEFING) {
			msg.error(`Você ultrapassou o limite máximo de ${LIMITE_QTD_FREELANCERS_NOVO_BRIEFING} profissionais por proposta de job. Remova o excedente para poder finalizar a publicação da proposta.`);
			return;
		}

		if(camposInvalidos(stateDados, {
			title: 'Título da proposta de job',
			id_customer: 'Cliente da proposta',
			id_project: 'Projeto da proposta',
			id_user: 'Usuário responsável',
		}, [
			'title',
			'id_company',
			'id_customer',
			'id_project',
			'id_user',
		])) return;

		var texto = sanitize(stateDescricaoBriefing);
		if(!texto || typeof(texto) !== 'string' || texto.trim().length <= 0) {
			msg.error('É necessário preencher a descrição da proposta de job');
			return;
		}

		setLoadingNextStep(true);

		// id: 4 => "Em Andamento"
		// id: 5 => "Pausado"
		// id: 6 => "Contratado"
		// id: 7 => "Arquivado"
		// id: 8 => "Em rascunho"

		const retPublicarBriefing = await apiBriefings.update.put({
			id: stateDados.id,
			id_status: 4, // em andamento
		});

		if(retPublicarBriefing.status === 200) {
			msg.success('Proposta de job publicada com sucesso');
			setShowPortalPropostaPublicada(true);
			limparListaLocal({
				stateListaAtual: 'Lista padrão',
				setStateSelecionados,
			}, false);
			removerBriefingSelecionadoLocal();
		} else {
			msgs([retPublicarBriefing]);
		}

		setLoadingNextStep(false);
	}

	/*
	** Redefine todos os campos para o estado inicial
	** ================================================== */
	const resetarCamposCadastro = () => {
		setStateDados(camposDefault);
		setStateDescricaoBriefing('');
		_setStateDados({status: 200});
	}

	const renderTelaCorreta = () => {

		if(!stateIsAcessoValido && stateIsAcessoValido !== null) {
			return <AvisoAcessoRestrito {...stateAcesso} />
		}

		if(loadingStart) {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Loading />
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.tipo === 'nao-rascunho') {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-t-0">Essa proposta já foi publicada</h3>
										<div className="flex flex-column flex-center">
											<LinhaItem
												titulo="Proposta"
												desc={stateDados.title}
												alt
											/>
											<LinhaItem
												titulo="Publicada em"
												desc={dataPorExtenso(stateDados.created_at, true, 'há ', '', '')}
												alt
											/>
										</div>
										<p>Essa proposta de job que você está tentando editar já foi publicada. Caso você queira complementar as informações ou adicionar novos profissionais, utilize a tela de <Link to={`/briefing/complementar?id=${stateDados.id}`}>complementar propostas de jobs</Link>.</p>
										<div>
											<p>
												Se você <strong>não</strong> está editando uma proposta anterior, inicie uma nova com os profissionais selecionados clicando no botão abaixo:
											</p>
											<Button2
												_={{
													value: 'Iniciar nova proposta',
													onClick: () => {
														setGetNavigate({
															gets: {},
															navigate,
															stateGet,
															location,
															uniq: true,
														});
														resetarCamposCadastro();
														setShowPortalConfirmacaoNovaProposta(false);
														msg.success('Pronto! Você já pode preencher sua proposta. Os profissionais selecionados serão adicionados.');
													}
												}}
											/>
										</div>
									</div>
								}
								classNameWrap="relative"
								icone="info"
								before={loadingNextStep || loadingOverlayCadastroEdicao ? <Loading absolute bgAlt3 /> : null}
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.status === 403) {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Sem permissão</h3>
										<div>{_stateDados.msg}</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.tipo === 'nao-autorizado') {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Sem permissão</h3>
										<div>Você não tem permissão para editar essa proposta de job.</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(_stateDados.status === 400) {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-0">Proposta não encontrada</h3>
										<div>{_stateDados.msg}</div>
									</div>
								}
								icone="erro"
							/>
						</div>
					</div>
				</div>
			);
		}

		if(!stateSelecionados || !stateSelecionados.length) {
			return (
				<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
					<div className="base-m">
						<div className="base-content">
							<Info
								texto={
									<div>
										<h3 className="margin-t-0">Nenhum profissional selecionado</h3>
										<p>Para publicar uma proposta de forma direta, é necessário selecionar os freelancers que farão parte da proposta. Você pode selecionar os freelancers através da tela de <Link to={"/buscar-profissionais"}>buscar profissionais</Link>.</p>
										<p>Ou você também pode criar uma <Link to={"/briefing/cadastrar"}>proposta de job completa</Link>, buscando os profissinoais por área, interesses, disponibilidade, etc.</p>
									</div>
								}
								classNameWrap="relative"
								icone="info"
								before={loadingNextStep || loadingOverlayCadastroEdicao ? <Loading absolute bgAlt3 /> : null}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(_stateDados.status === 200 || !stateDados.id) {
			return (
				<>
					{stateGet.step === '1' ? (
						<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-1">
							<div className="base-m">
								<div className="base-content">
									<CadastrarProcessoSeletivoSelecionadosFormCadastro
										onSubmit={() => null}
										isRascunho={false}
										isCadastroSelecionados={true}
										loading={loadingOverlayCadastroEdicao}
										getClientes={e => apiGetClientes({
											id: e,
											setLoadingGetClientes: setLoadingGetClientesStep1,
											setStateClientes: setStateClientesStep1,
										})}
										getUsuarios={e => apiGetUsuarios({
											id: e,
											setLoadingGetUsuarios: setLoadingGetUsuariosStep1,
											setStateUsuarios: setStateUsuariosStep1,
										})}
										getProjetos={e => {
											apiGetSelectProjetosClientes({
												idCliente: e.id_customer,
												idEmpresa: e.id_company,
												setLoadingGetProjetos: setLoadingGetProjetosStep1,
												setStateProjetos: setStateProjetosStep1,
											});
										}}
										onClickResetarProposta={() => setShowPortalConfirmacaoNovaProposta(true)}
										stateDados={stateDados}
										setStateDados={setStateDados}
										stateEmpresas={stateEmpresasStep1}
										stateClientes={stateClientesStep1}
										stateProjetos={stateProjetosStep1}
										stateUsuarios={stateUsuariosStep1}
										stateTemplates={stateTemplatesStep1}
										loadingGetEmpresas={loadingGetEmpresasStep1}
										loadingGetClientes={loadingGetClientesStep1}
										loadingGetProjetos={loadingGetProjetosStep1}
										loadingGetUsuarios={loadingGetUsuariosStep1}

										stateSelecionados={stateSelecionados}
										setStateSelecionados={setStateSelecionados}
									/>
								</div>
							</div>
						</div>
					) : null}

					{stateGet.step === '2' ? (
						<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-2">
							<div className="base-m">
								<div className="base-content">
									<div className="cadprocsel-p2-titulo-acao">
										<div className="cadprocsel-p2-titulo-box">
											<h3 className="titulo">Descrição da proposta de job</h3>
											<BtnAdd
												titulo="Selecionar template"
												onClick={() => showPortal({portal: 'template', stateGet, navigate})}
												className="cadprocsel-p2-btn-template"
											/>
										</div>
										<div className="desc">Detalhe aqui as informações essenciais sobre o job para que o prestador de serviços consiga entender a sua necessidade e enviar um orçamento para você. Para facilitar, você pode usar um dos templates disponíveis.</div>
									</div>
									<div className="margin-t-15">
										<Editor
											value={stateDescricaoBriefing}
											setValue={e => {
												setStateDescricaoBriefing(e);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}

					{stateGet.step === '3' ? (
						<div className="cadprocsel-box cadprocsel-passo cadprocsel-passo-3">
							<div className="base-m">
								<div className="base-content">
									<RevisaoBriefingSelecionados
										stateGet={stateGet}
										navigate={navigate}
										stateDados={stateDados}
										stateDescricao={stateDescricaoBriefing}
										setStep={definirStep}
										stateListaAtual={stateListaAtual}
										setStateListaAtual={setStateListaAtual}
										stateListas={stateListas}
										setStateListas={setStateListas}
										stateSelecionados={stateSelecionados}
										setStateSelecionados={setStateSelecionados}
										stateClientes={stateClientesStep1}
										stateProjetos={stateProjetosStep1}
										stateUsuarios={stateUsuariosStep1}
										stateFreelancers={stateFreelancersStep1}
										setStateFreelancers={setStateFreelancersStep1}
										loading={loadingOverlayCadastroEdicao}
										definirStep={definirStep}
									/>
								</div>
							</div>
						</div>
					) : null}
				</>
			)
		}

		return <div>Passo não encontrado.</div>;
	}

	return (
		<>
			<div className="pg-cadprocsel base-full">
				<div className="conteudo-box">
					<div className="titulo-box">
						{/* <Voltar /> */}
						<div className="cadprocsel-topbar-info flex flex-center-v">
							{BYPASS_BRIEFING_STEPS ? (
								<Tip
									tip={
										<h3 className="titulo">Cadastrar proposta de job</h3>
									}
									closeOnLeave={false}
									delay={200}
								>
									<NavSteps
										className=""
										value={stateGet.step}
										onChange={e => () => null}
										onClickPrev={e => definirStep('prev', true)}
										onClickNext={e => definirStep('next', true)}
									/>
								</Tip>
							) : (
								<h3 className="titulo">Cadastrar proposta de job</h3>
							)}
							<div className="cadprocsel-info-box flex flex-center-v">
								<div className="cadprocsel-info-steps">
									<Tip
										titulo="Passos para publicar a proposta de job"
										tip={
											<div className="color-1 fs-14">Passo <strong>{stateGet.step}</strong>/<strong>{maxStep}</strong></div>
										}
									>
										<div className="flex flex-center-v">
											<div className="circle">1</div>
											<div className="margin-l-10">Dados do briefing</div>
										</div>
										<div className="flex flex-center-v margin-t-10">
											<div className="circle">2</div>
											<div className="margin-l-10">Descrição</div>
										</div>
										<div className="flex flex-center-v margin-t-10">
											<div className="circle">3</div>
											<div className="margin-l-10">Revisão e publicação</div>
										</div>
										<div className="sep-t margin-t-10 padding-t-10 align-center">Você está no passo <strong>{stateGet.step}</strong> de <strong>{maxStep}</strong></div>
									</Tip>
								</div>
								{stateGet.edit_id_briefing && _stateDados.status === 200 && stateDados.id_status === 8 ? (
									<div className="cadprocsel-info-rascunho">
										<Tip
											titulo="Proposta ainda não publicada"
											tip={
												<div className="color-1 fs-14">Rascunho <strong>(ID {stateDados.id})</strong></div>
											}
										>
											<div className="max-w-350">
												<LinhaItem titulo="ID" desc={stateDados.id} alt />
												<LinhaItem titulo="Título" desc={stateDados.title} alt />
												<LinhaItem titulo="Cliente" desc={arrValue(stateClientesStep1, 'id', stateDados.id_customer, 'name', '(não informado)')} alt />
												<LinhaItem titulo="Projeto" desc={arrValue(stateProjetosStep1, 'id', stateDados.id_project, 'name', '(não informado)')} alt />
												<LinhaItem titulo="Responsável" desc={arrValue(stateUsuariosStep1, 'id', stateDados.id_user, 'name', '(não informado)')} alt />
												<LinhaItem titulo="Criado em" desc={dataPorExtenso(stateDados.created_at, true, 'há ', '', '')} alt />
												<div className="sep-t margin-t-10 padding-t-10">Essa proposta atualmente está salva como <strong>rascunho</strong>. Para enviá-la aos profissionais selecionados, é necessário que ela seja publicada.</div>
											</div>
										</Tip>
									</div>
								) : null}
							</div>
						</div>
						<div className="cadprocsel-select-box">
							<Tip
								titulo="Propostas em rascunho"
								tip={
									<div className="q-alt"></div>
								}
								closeOnLeave={false}
								_={{
									delayLeave: 100,
								}}
								info
								classNameTip="margin-r-10 cadprocsel-q-info"
							>
								<div>Quando se inicia uma nova proposta, ao clicar em <strong>Salvar e continuar</strong>, a proposta é salva como rascunho. </div>
								<div className="margin-t-10">Todas as propostas em rascunho ficam disponíveis aqui para você poder editá-las e publicá-las quando quiser. </div>
								<div className="margin-t-10">Para as propostas já publicadas, você poderá complementá-las usando a página de Complementar propostas.</div>
							</Tip>
							{store.usuario.role === 1 ? (
								<Select2
									label="Empresa"
									_={{
										value: stateGet.edit_id_company,
										options: stateEmpresasSelect,
										onChange: e => {
											if(!e || isNumeroDiferente(e.value, stateGet.edit_id_company)) {
												resetarCamposCadastro();
												setGetNavigate({
													gets: {
														edit_id_company: e ? e.value : null,
														edit_id_briefing: null,
													},
													navigate,
													stateGet,
													location,
													uniq: true,
												});

											}
										},
										// onClick: e => {
										// 	if(isObjDiferente(stateDados, camposDefault, false)) {
										// 		msg.info('Ao alterar esse filtro, os dados da proposta atual que não foram salvos serão perdidos.');
										// 	}
										// }
									}}

									wrap="cpnt-select2-min cadprocsel-select"
									loading={loadingGetEmpresasSelect}
								/>
							) : null}
							<div className="cadprocsel-select-boxbtn">
								<Select2
									label="Propostas em rascunho"
									_={{
										value: stateGet.edit_id_briefing,
										options: stateBriefingsSelect,
										onChange: e => {
											salvarBriefingSelecionadoLocal(e.value);
											setGetNavigate({
												gets: {
													edit_id_briefing: e ? e.value : null,
													edit_id_company: stateGet.edit_id_company,
												},
												navigate,
												stateGet,
												location,
												uniq: true,
											});
											if(!e || isNumeroDiferente(e.value, stateGet.edit_id_briefing)) {
												resetarCamposCadastro();
											}
										},
										// onClick: e => {
										// 	if(isObjDiferente(stateDados, camposDefault, false)) {
										// 		msg.info('Ao alterar esse filtro, os dados da proposta atual que não foram salvos serão perdidos.');
										// 	}
										// }
									}}
									wrap="cpnt-select2-min cadprocsel-select cadprocsel-select-rascunho margin-l-10"
									loading={loadingGetBriefingsSelect}
								/>
								<Tip
									titulo="Inicia uma nova proposta de job"
									tip={
										<Icone
											imagem={imgIconeIniciarNovo}
											className="cursor-pointer"
											cor="#0277BD"
											tamanho="26px"
											wrap="margin-l-10"
											onClick={e => setShowPortalConfirmacaoNovaProposta(true)}
										/>
									}
									info
								>
									Caso queira manter as alterações da proposta atual, salve as alterações antes de iniciar uma nova.
								</Tip>
							</div>
						</div>
					</div>

					<div className="cadprocsel-box-wrapper">
						{renderTelaCorreta()}
					</div>
				</div>

				{(_stateDados.status === 200 && _stateDados.tipo !== 'nao-rascunho' && stateIsAcessoValido && stateSelecionados.length) ? (
					<div className="bottombar padding-l-0 padding-r-0">
						<div className="base-m">
							<div className="base-content">
								<div className="pg-carprocsel-bottombar">
									<div className="pg-carprocsel-bottombar-btn-l-box">
										<Button2
											_={{
												value: 'Voltar',
												onClick: e => {
													if(stateGet.step <= 1) {
														navigate('/buscar-profissionais');
													} else {
														definirStep('prev');
													}
												},
												// disabled: stateGet.step <= 1 ? true : false,
											}}
											loading={loadingNextStep || loadingOverlayCadastroEdicao}
										/>
									</div>
									<div className="pg-carprocsel-bottombar-btn-r-box">
										{stateGet.step === '3' ? (
											<Button2
												_={{
													value: <TextoIconeLink texto="Publicar proposta" icone={imgIconePublicar} iconeCor="#1cb53f" />,
													onClick: publicarProposta,
												}}
												loading={loadingNextStep || loadingOverlayCadastroEdicao}
											/>
										) : (
											<Button2
												_={{
													value: <TextoIconeLink texto="Salvar e continuar" icone={imgIconeSalvar} />,
													onClick: e => definirStep('next')
												}}
												loading={loadingNextStep || loadingOverlayCadastroEdicao}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>

			{/* Portal de templates de briefing */}
			<Portal
				titulo="Proposta de job publicada com sucesso"
				show={showPortalPropostaPublicada}
				className="cadprocsel-overlay-sucesso"
				x={false}
				closeOnEsc={false}
			>
				<Info
					icone="sucesso"
					texto={
						<>
							<h3>Parabéns, sua proposta de job foi publicada com sucesso!</h3>
							<div>
								<p>Você será notificado por email sempre que um profissional enviar uma mensagem no chat desta proposta de job.</p>
							</div>
						</>
					}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: <TextoIconeLink texto="Cadastrar nova proposta de job" icone={imgIconeNovo} />,
							onClick: () => {
								setShowPortalPropostaPublicada(false);
								resetarCamposCadastro();
								setStateBriefingsSelect(stateBriefingsSelect.filter(item => item.id !== stateDados.id));
								setGetNavigate({
									gets: [],
									navigate,
									stateGet,
									location,
									uniq: true,
								});
							},
						}}
					/>
					<Button2
						_={{
							value: <TextoIconeLink texto="Visualizar propostas publicadas" icone={imgIconeSetaDir} reverso={true} />,
							onClick: () => {
								navigate('/briefing');
							},
						}}
						wrap="margin-l-auto"
					/>
				</div>
			</Portal>

			<PortalTemplates
				idTipo={1}
				show={isShowPortal({portal: 'template', stateGet})}
				onClose={() => closePortal({portal: 'template', stateGet, navigate})}
				onSelect={e => {
					setStateDados({
						...stateDados,
						id_template: e.id,
					});
					setStateDescricaoBriefing(e.text);
					closePortal({portal: 'template', stateGet, navigate})
				}}
			/>

			<PortalConfirmacao
				show={showPortalConfirmacaoNovaProposta}
				titulo="Deseja realmente iniciar uma nova proposta?"
				desc={
					<>
						<div>Ao iniciar uma nova proposta, os dados da proposta atual que não foram salvos serão perdidos.</div>
						<div className="margin-t-10">Caso você tenha salvo, é possível continuar editando a proposta posteriormente. Para isso, basta selecionar ela no menu chamado "Propostas em rascunho" disponível no topo desta página.</div>
					</>
				}
				onClose={e => {
					setShowPortalConfirmacaoNovaProposta(false);
				}}
				onClickCancelar={e => {
					setShowPortalConfirmacaoNovaProposta(false);
				}}
				onClickConfirmar={e => {
					setGetNavigate({
						gets: {},
						navigate,
						stateGet,
						location,
						uniq: true,
					});
					resetarCamposCadastro();
					removerBriefingSelecionadoLocal();
					setShowPortalConfirmacaoNovaProposta(false);
					msg.success('Pronto! Você já pode preencher os campos novamente. Todos os campos referentes a proposta anterior foram limpos.');
				}}
			/>

		</>
	);
}

export default CadastrarProcessoSeletivoSelecionados;
