import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';
import { DEV } from "@_config/config";

const PlaceholderJobsAdmin = props => {

	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
					<Placeholder tipo="linha-min-2" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
					<Placeholder tipo="linha-min" wrap="w-80p" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
					<Placeholder tipo="linha-min-2" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" wrap="max-w-100" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="circulo" />
					<Placeholder tipo="linha-min-2" wrap="max-w-100" />
				</div>
			</td>
			<td className="col-acao">
				<div className="td-wrap">
					<Placeholder tipo="retangulo-alt" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderJobsAdmin };
