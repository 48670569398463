import { URL_BLOG } from "@_config/config";

/*
** Sidebar Lite
** ================================================== */
const sidebarLite = {
	menu: [
		{
			title: 'Contratações',
			itens: [

				{
					title: 'Página inicial',
					path: '/inicio',
					icon: 'solid/house.svg',
				},
				{
					title: 'Briefing/Vaga',
					path: '/briefing',
					icon: 'match.svg',
					custom: true,
				},
				{
					title: 'Contratações',
					path: '/contratacoes',
					icon: 'solid/shield-halved.svg',
				},
			],
		},
		{
			title: 'Gerenciar',
			itens: [
				{
					title: 'Meu Perfil',
					path: '/perfil',
					icon: 'solid/user-large.svg',
				},
			]
		},
		{
			title: 'Outros',
			itens: [
				{
					title: 'Blog',
					path: URL_BLOG,
					icon: 'solid/newspaper.svg',
				},
				{
					title: 'Termos de uso',
					path: '/perfil?secao=termos',
					icon: 'regular/file-lines.svg',
				},
			]
		},
		{
			title: '',
			itens: []
		},
		{
			title: '',
			itens: [
				{
					title: 'Central de ajuda',
					path: '/perfil?secao=ajuda',
					icon: 'solid/circle-question.svg',
				},
				{
					title: 'Logout',
					path: '/logout',
					icon: 'solid/right-from-bracket.svg',
				},
			]
		},
	],
	btn: {
		title: 'Upgrade Premium',
		path: 'https://crowd.br.com/recrutamento-e-selecao/',
	}
};

export { sidebarLite };
