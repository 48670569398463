import moment from 'moment';
import 'moment/locale/pt-br';

/*
** Define o idioma da lib "moment" como PT-BR
** ================================================== */
moment.locale('pt-br');

export const dataSemTZ = (date) => {
	if(!date) return date;

    if (date.includes('T') && date.includes('Z')) {
        return date.replace('T', ' ').replace('.000Z', '');
    }
    return date;
};

/*
** Valida data
** ================================================== */
export const validarDataAPI = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && (e.length === 10 || e.length === 19 || e.length === 24) && e !== '0000-00-00 00:00:00') {
		return true;
	}
	return false;
}

/*
** Pega a data atual
** ================================================== */
export const dateNow = (props) => {
	const {
		horas,
		lang = 'br',
	} = props;

	if(horas) {
		return moment().format('YYYY-MM-DD H:mm:ss').locale(lang);
	}
	return moment().format('YYYY-MM-DD').locale(lang);
}

/*
** Data por extenso
** ================================================== */
export const dataPorExtenso = (props) => {

	const {
		data,
		prefixo = 'Há '
	} = props;

	if(!validarDataAPI(data)) {
		return '(data não informada)';
	}

	var dataOk = dataSemTZ(data);

	return `${prefixo}${moment(dataOk).fromNow(true)}`;
}

/*
** Data BR
** ================================================== */
export const dataBr = (props) => {

	const {
		data,
	} = props;

	if(!validarDataAPI(data)) {
		return '(data não informada)';
	}

	var dataOk = dataSemTZ(data);

	return `${moment(dataOk).format('DD/MM/YYYY')}`;
}
/*
** Data BR
** ================================================== */
export const dataBrFull = (props) => {

	const {
		data,
	} = props;
	console.log('data:', data)

	if(!validarDataAPI(data)) {
		return '(data não informada)';
	}

	var dataOk = dataSemTZ(data);

	return `${moment(dataOk).format('DD/MM/YYYY [às] H:mm:ss')}`;
}

export const dataApiAlterarDia = ({data, dia}) => {

	if(!data || !dia) return '';

	if(!validarDataAPI(data)) {
		return '';
	}

	data = dataSemTZ(data);

    // Criar um objeto Date a partir da string fornecida
    let dataObj = new Date(data);

    // Alterar o dia
    dataObj.setDate(dia);

    // Formatando a data de volta para 'YYYY-MM-DD HH:mm:ss'
    let ano = dataObj.getFullYear();
    let mes = String(dataObj.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    let day = String(dataObj.getDate()).padStart(2, '0');
    let horas = String(dataObj.getHours()).padStart(2, '0');
    let minutos = String(dataObj.getMinutes()).padStart(2, '0');
    let segundos = String(dataObj.getSeconds()).padStart(2, '0');

    return `${ano}-${mes}-${day} ${horas}:${minutos}:${segundos}`;
};

export const dateGetDay = ({date, placeholder = '-', int}) => {
	if(!validarDataAPI(date)) return placeholder;
    let dia = date.substring(8, 10);

	if(int) {
		return Number(dia);
	}

    return dia;
}

/*
** Exporta todos em um único objeto data
** ================================================== */
export const data = {
	get: {
		day: dateGetDay,
	},
	set: {},
	apiParaDate: dataSemTZ,
	apiAlterarDia: dataApiAlterarDia,
	agora: dateNow,
	porExtenso: dataPorExtenso,
	default: dataBr,
	full: dataBrFull,
};
