export const SELECT_SENIORIDADE = [
	{
		label: 'Júnior',
		value: 'junior',
	},
	{
		label: 'Pleno',
		value: 'pleno',
	},
	{
		label: 'Sênior',
		value: 'senior',
	},
];

export const SELECT_ROLES_EMPRESAS = [
	{
		value: 0,
		label: 'Todos',
	},
	{
		value: 1,
		label: 'CROWD Projetos',
	},
	{
		value: 3,
		label: 'CROWD Premium',
	},
	{
		value: 9,
		label: 'Crowd Lite',
	},
];

export const SELECT_ROLES_TIPO_EMPRESAS = [
	// {
	// 	value: 1,
	// 	label: 'CROWD Projetos',
	// },
	{
		value: 3,
		label: 'CROWD Premium',
	},
	{
		value: 9,
		label: 'Crowd Lite',
	},
];

export const ROLES_NOMES_BY_NIVEL = {0: 'Não logado', 1: 'CROWD Projetos', 2: 'Profissional', 3: 'CROWD Premium', 9: 'Crowd Lite'};
export const ROLES_CLASS_SLUGS_BY_NIVEL = {0: 'perf-notlogged', 1: 'perf-adm', 2: 'perf-freela', 3: 'perf-premium', 9: 'perf-lite'};