import { validarRetorno, config } from '@_api/axios';
import api from 'axios';
import qs from 'qs';

const apiFreelancerRatings = {
	all: {
		get: async (filtrosObj = {}) => {
			var filtros = qs.stringify(filtrosObj, { encode: false });
			return await api.get(`/freelancerRating/all?${filtros}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
	ratingMedia: {
		get: async (filtrosObj = {}) => {
			var filtros = qs.stringify(filtrosObj, { encode: false });
			return await api.get(`/freelancerRating/ratingMedia?${filtros}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiFreelancerRatings };
