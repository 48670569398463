import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import { Avaliacao } from "@cpnt/Avaliacao/Avaliacao";
import AvaliacoesBarra from "@cpnt/AvaliacoesBarra/AvaliacoesBarra";
import Estrelas from "@cpnt/Estrelas/Estrelas";
import c from './TodasAvaliacoes.module.css';
import { arredondarNumero, dataApiParaBrExtenso, singularPlural } from "@func/utils";

const TodasAvaliacoes = props => {

	const navigate = useNavigate();
	const location = useLocation();
	const [store, setStore] = useContext(StoreContext);

	const {
		stateDados,
		avaliacoes = [],
		after,
		hideTotal,
	} = props;

	return (
		<>
			<div className={`${c['box-topo']}`}>
				<div className={`${c['left']}`}>
					<div className={`${c['total']}`}>{arredondarNumero(stateDados.total)} de 5.0</div>
					<div className={`${c['avaliacoes']}`}>
						<Estrelas
							qtd={arredondarNumero(stateDados.total)}
							tamanho="170px"
							wrap="flex flex-center"
							corAtivo="#FDA63F"
						/>
						{!hideTotal && <div className="desc margin-t-5">{stateDados.ratings_count} {singularPlural(stateDados.ratings_count, 'avaliação', 'avaliações')} no total</div>}
					</div>
				</div>
				<div className={`${c['right']}`}>
					<AvaliacoesBarra
						ratings={stateDados.percent}
					/>
				</div>
			</div>

			<div className={`${c['boxes-avaliacoes']}`}>
				{avaliacoes.map((val, key) => {
					return (
						<Avaliacao
							key={key}
							nome={val.user_name}
							estrelas={val.total}
							data={dataApiParaBrExtenso(val.created_at)}
							desc={val.comment}
							classNameWrap={c['pt-aval-box-avaliacao']}
						/>
					)
				})}
			</div>

			{after}

		</>
	);
};

export default TodasAvaliacoes;