import React, { useContext, useEffect, useState } from 'react';

// Libs
import { useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import c from './MatchDeProfissionaisScreen.module.scss';

// Request
import { api } from "@_api/api";
import { apiObj, msgs } from "@_func/request";

// Img
import imgPasta from '@_img/fa/solid/folder.svg';

// Cpnt
import { Br } from "@_cpnt/Br/Br";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { Editor } from "@_cpnt/Inputs/Editor/Editor";
import { Input } from "@_cpnt/Inputs/Input/Input";
import { Radio } from "@_cpnt/Inputs/Radio/Radio";
import { Select } from "@_cpnt/Inputs/Select/Select";
import { Loading } from "@_cpnt/Loading/Loading";
import { PortalAnimacaoMatchProfissionais } from "@_cpnt/Portais/PortalAnimacaoMatchProfissionais/PortalAnimacaoMatchProfissionais";
import { IconeTexto } from "@_cpnt/Texto/IconeTexto/IconeTexto";
import { IconeTextoRoot } from "@_cpnt/Texto/IconeTexto/IconeTextoRoot/IconeTextoRoot";
import { Topbar } from "@_cpnt/Topo/Topbar/Topbar";
import { TopbarRoot } from "@_cpnt/Topo/Topbar/TopbarRoot/TopbarRoot";
import { msg } from "@_func/cpnt";

// Old
import { PortalTemplates } from "@cpnt/Portais/PortaisTemplates/PortalTemplates/PortalTemplates";

// Objetos
import { SELECT_SENIORIDADE } from "@_obj/select";
import { camposInvalidos } from "@func/utils";

export const MatchDeProfissionaisScreen = props => {
	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	const {
		className = '',
	} = props;

	const camposDefault = {
		type: null, // Não é salvo na API

		text: '',
		id_project: null,
		title: '',
		id_area: null,
		seniority: null,
		id_company: store.usuario.id_company,
		id_customer: null,
		id_model_work: null,
		id_type_contract: null,
		id_user: store.usuario.id,
		id_profession: null,
		state: null,
		segments: [],
		skills: [],
		languages: [],

		custom_keyword: '',
	};

	const DEFAULT_STATE_OBJ = apiObj();

	const [showPortalTemplates, setShowPortalTemplates] = useState(false);
	const [showPortalCadastradoComSucesso, setShowPortalCadastradoComSucesso] = useState(false);

	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetClientes, setLoadingGetClientes] = useState(false);
	const [loadingGetProjetos, setLoadingGetProjetos] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);
	const [loadingGetTiposContrato, setLoadingGetTiposContrato] = useState(false);
	const [loadingGetModelosDeTrabalho, setLoadingGetModelosDeTrabalho] = useState(false);
	const [loadingGetProfissoes, setLoadingGetProfissoes] = useState(false);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	const [loadingGetEstados, setLoadingGetEstados] = useState(false);

	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateClientes, setStateClientes] = useState(DEFAULT_STATE_OBJ);
	const [stateProjetos, setStateProjetos] = useState(DEFAULT_STATE_OBJ);
	const [stateUsuarios, setStateUsuarios] = useState(DEFAULT_STATE_OBJ);
	const [stateAreas, setStateAreas] = useState(DEFAULT_STATE_OBJ);
	const [stateTiposContrato, setStateTiposContrato] = useState(DEFAULT_STATE_OBJ);
	const [stateModelosDeTrabalho, setStateModelosDeTrabalho] = useState(DEFAULT_STATE_OBJ);
	const [stateProfissoes, setStateProfissoes] = useState(DEFAULT_STATE_OBJ);
	const [stateSegmentos, setStateSegmentos] = useState(DEFAULT_STATE_OBJ);
	const [stateIdiomas, setStateIdiomas] = useState(DEFAULT_STATE_OBJ);
	const [stateCompetencias, setStateCompetencias] = useState(DEFAULT_STATE_OBJ);
	const [stateEstados, setStateEstados] = useState(DEFAULT_STATE_OBJ);

	const [stateDescricao, setStateDescricao] = useState('');
	const [stateIdBriefingCadastrado, setStateIdBriefingCadastrado] = useState(0);

	const [stateTypeEditor, setStateTypeEditor] = useState('default');

	useEffect(() => {
		getClientes();
		getProjetos({}, true);
		getUsuarios();

		api._.select.areas.get({setStateAreas, setLoadingGetAreas});
		api._.select.typeContract.get({setStateTiposContrato, setLoadingGetTiposContrato});
		api._.select.modelWork.get({setStateModelosDeTrabalho, setLoadingGetModelosDeTrabalho});
		api._.select.segments.get({setStateSegmentos, setLoadingGetSegmentos});
		api._.select.languages.get({setStateIdiomas, setLoadingGetIdiomas});
		api._.select.competences.get({setStateCompetencias, setLoadingGetCompetencias});
		api._.select.state.get({setStateEstados, setLoadingGetEstados, uf: true});
	}, []);

	const getClientes = async idCompany => {
		const ret = await api._.select.customers.get({
			idCompany: store.usuario.id_company,
			setLoadingGetClientes,
			setStateClientes,
		});

		if(ret.status === 200) {
			setStateDados(prevState => ({
				...prevState,
				id_customer: ret.data[0].id
			}));
		}
	}

	const getProjetos = async (params = {}, inicio) => {
		const {
			idCompany = store.usuario.id_company,
			idCustomer,
		} = params;

		const ret = await api._.select.projects.get({
			idCompany: idCompany,
			idCustomer: idCustomer,
			setLoadingGetProjetos,
			setStateProjetos,
		});

		if(inicio) {
			if(ret.status === 200) {
				setStateDados(prevState => ({
					...prevState,
					id_project: ret.data[0].id,
				}));
			}
		}
	}

	const getUsuarios = async (params = {}) => {
		const {
			idCompany = store.usuario.id_company,
		} = params;

		api._.users.getAllUsers.get({
			idCompany: idCompany,
			setLoadingGetUsuarios,
			setStateUsuarios,
		});
	}

	const getProfissoes = async (dados = {}) => {
		const ret = await api._.companyLite.professions.get({
			data: {
				...dados,
				limit: 999,
			},
			setStateProfissoes,
			setLoadingGetProfissoes,
		});
		if(ret.status !== 200) {
			msgs([ret]);
		}
	}

	const onChangeCheckboxProfServ = (tipo) => {
		var dados = {
			...stateDados,
			type: tipo,
		};

		if(stateDados.id_area) {
			dados.id_profession = null;
			if(tipo !== 'S') {
				getProfissoes({
					type: tipo,
					id_area: stateDados.id_area,
				});
			}
		}

		setStateDados(dados);
	}

	const exibirBloqueio = showTexto => {
		var conteudo = (
			<div className={`${c['info-selecao']}`}>
				<div className={`${c['info-selecao-texto']}`}>{showTexto ? 'Para iniciar, selecione o que precisa e também o modelo de trabalho do profissional.' : ''}</div>
			</div>
		);

		if(!stateDados.type) return conteudo;

		if(store.usuario.role !== 9) {
			if(stateDados.type === 'P' && !stateDados.id_type_contract) {
				return conteudo;
			}
		}

		return null;
	}

	const toShow = (campo) => {
		if(store.usuario.role === 1) {
			return true;
		}

		const campos = {
			1: {
				// Campos admin
			},
			3: [
				// Campos Premium
				'type',
				'id_type_contract',
				'title',
				'id_user',
				'id_customer',
				'id_project',
				'id_area',
				'id_profession',
				'custom_keyword',
				'seniority',
				'id_model_work',
				'segments',
				'skills',
				'languages',
				'state',
				'text',
			],
			9: [
				// Campos Premium
				'type',
				// 'id_type_contract',
				'title',
				// 'id_user',
				// 'id_customer',
				// 'id_project',
				'id_area',
				'id_profession',
				'custom_keyword',
				'seniority',
				// 'id_model_work',
				'segments',
				// 'skills',
				// 'languages',
				// 'state',
				'text',
			],
		};

		if(campos[store.usuario.role]) {
			return campos[store.usuario.role]?.filter(item => item === campo)?.length;
		}

		return false;
	}

	const cadastrar = async () => {
		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		/*
		** ADMIN
		** ================================================== */
		if(store.usuario.role === 1) {

			const dadosEnviar = {
				text: stateDescricao,
				id_project: stateDados.id_project,
				title: stateDados.title,
				id_area: stateDados.id_area,
				seniority: stateDados.seniority,
				id_company: stateDados.id_company,
				id_customer: stateDados.id_customer,
				id_model_work: stateDados.id_model_work,
				id_type_contract: stateDados.id_type_contract,
				id_user: stateDados.id_user,
				id_profession: stateDados.id_profession,
				state: stateDados.state,
				type: stateDados.type,
				// segments: [],
				// skills: [],
				// languages: []
			};

			const segmentos = [];
			if(stateSegmentos._) {
				segmentos.push({ id_segment: stateSegmentos._.id });
			}

			const competencias = stateCompetencias._?.map(val => {
				return { id_skill: val.id };
			});

			const idiomas = stateIdiomas._?.map(val => {
				return { id_language: val.id };
			});

			dadosEnviar.segments = segmentos;
			dadosEnviar.skills = competencias;
			dadosEnviar.languages = idiomas;

			var camposParaValidar = [
				'id_company',
				'id_customer',
				'id_project',
				'id_user',
				'title',
				'id_area',
				'id_profession',
				'seniority',
				'text',
				'type',
			];

			if(stateDados.type === 'P') {
				camposParaValidar.push(...['id_type_contract', 'id_model_work']);
				dadosEnviar.id_type_contract = stateDados.id_type_contract;
				dadosEnviar.id_model_work = stateDados.id_model_work;
			}

			if(camposInvalidos(dadosEnviar, {
				description: '',
			}, camposParaValidar)) return;

			if(dadosEnviar.id_model_work === 2 || dadosEnviar.id_model_work === 3) {
				if(camposInvalidos(dadosEnviar, {
					state: 'Estado',
				}, [
					'state',
				], 'Para modelos de trabalho que não sejam 100% remoto, é necessário selecionar o estado')) return;
			}


			if(stateDados.id_profession === 'outro') {
				dadosEnviar.custom_keyword = stateDados.custom_keyword;
				delete dadosEnviar.id_profession;

				if(camposInvalidos(dadosEnviar, {
					custom_keyword: 'Principal habilidade do profissional',
				}, [
					'custom_keyword',
				], 'Informe a principal habilidade do profisisonal, ex: Desenvolvedor WordPress, Designer de interiores, Especialista em IA, etc.')) return;
			}

			setLoadingCadastrar(true);
			const ret = await api.briefings.match.post(dadosEnviar);

			if(ret.status === 200) {
				setStateIdBriefingCadastrado(ret.id_briefing);
				setShowPortalCadastradoComSucesso(true);
			} else {
				msgs([ret]);
			}

			setLoadingCadastrar(false);
		}

		/*
		** PREMIUM
		** ================================================== */
		else if(store.usuario.role === 3) {
			const dadosEnviar = {
				text: stateDescricao,
				id_project: stateDados.id_project,
				title: stateDados.title,
				id_area: stateDados.id_area,
				seniority: stateDados.seniority,
				id_company: stateDados.id_company,
				id_customer: stateDados.id_customer,
				id_user: stateDados.id_user,
				id_profession: stateDados.id_profession,
				type: stateDados.type,
			};

			var camposParaValidar = [
				'id_company',
				'id_customer',
				'id_project',
				'id_user',
				'title',
				'id_area',
				'id_profession',
				'seniority',
				'text',
				'type',
			];

			if(stateDados.type === 'P') {
				camposParaValidar.push(...['id_type_contract', 'id_model_work']);
				dadosEnviar.id_type_contract = stateDados.id_type_contract;
				dadosEnviar.id_model_work = stateDados.id_model_work;
			}

			if(camposInvalidos(dadosEnviar, {
				description: '',
			}, camposParaValidar)) return;

			if(stateDados.id_profession === 'outro') {
				dadosEnviar.custom_keyword = stateDados.custom_keyword;
				delete dadosEnviar.id_profession;

				if(camposInvalidos(dadosEnviar, {
					custom_keyword: 'Principal habilidade do profissional',
				}, [
					'custom_keyword',
				], 'Informe a principal habilidade do profisisonal, ex: Desenvolvedor WordPress, Designer de interiores, Especialista em IA, etc.')) return;
			}

			setLoadingCadastrar(true);
			const ret = await api.briefings.match.post(dadosEnviar);

			if(ret.status === 200) {
				setStateIdBriefingCadastrado(ret.id_briefing);
				setShowPortalCadastradoComSucesso(true);
			} else {
				msgs([ret]);
			}

			setLoadingCadastrar(false);
		}

		/*
		** Lite
		** ================================================== */
		else if(store.usuario.role === 9) {
			const dadosEnviar = {
				text: stateDescricao,
				title: stateDados.title,
				id_area: stateDados.id_area,
				seniority: stateDados.seniority,
				id_profession: stateDados.id_profession,
				type: stateDados.type,
			};

			if(camposInvalidos(dadosEnviar, {
				description: '',
			}, [
				'title',
				'id_area',
				'id_profession',
				'seniority',
				'text',
				'type',
			])) return;

			if(stateDados.id_profession === 'outro') {
				dadosEnviar.custom_keyword = stateDados.custom_keyword;
				delete dadosEnviar.id_profession;

				if(camposInvalidos(dadosEnviar, {
					custom_keyword: 'Principal habilidade do profissional',
				}, [
					'custom_keyword',
				], 'Informe a principal habilidade do profisisonal, ex: Desenvolvedor WordPress, Designer de interiores, Especialista em IA, etc.')) return;
			}

			setLoadingCadastrar(true);
			const ret = await api.companyLite.briefingInsert.post(dadosEnviar);

			if(ret.status === 200) {
				setStateIdBriefingCadastrado(ret.id_briefing);
				setShowPortalCadastradoComSucesso(true);
			} else {
				msgs([ret]);
			}

			setLoadingCadastrar(false);
		}


	}

	return (
		<>
			<div className={`${className} ${c['container']}`}>
				<TopbarRoot>
					<Topbar.Titulo>Briefing/Vaga</Topbar.Titulo>
				</TopbarRoot>
				<div className="base">
					<div className="base-content">
						<div className="inner">
							<form onSubmit={e => {
								e.preventDefault();
								cadastrar();
							}} className={`${c['espacamento']}`}>
								<div className={`${c['box-radio']}`}>
									<Loading abs show={loadingGetProfissoes} />
									<Radio
										label="Profissional"
										_={{
											checked: stateDados.type === 'P' ? true : false,
											onChange: e => onChangeCheckboxProfServ('P'),
										}}
									/>
									<Radio
										label="Serviço"
										_={{
											checked: stateDados.type === 'S' ? true : false,
											onChange: e => onChangeCheckboxProfServ('S'),
										}}
									/>
									<Br />
									{stateTiposContrato.status === 200 ? (
										stateTiposContrato.data.map((val, key) => {
											return (
												<Radio
													key={key}
													label={val.label}
													_={{
														checked: stateDados.id_type_contract === val.id ? true : false,
														onChange: e => {
															setStateDados({
																...stateDados,
																id_type_contract: val.id,
															})
														}
													}}
													show={store.usuario.role !== 9 && stateDados.type === 'P' ? true : false}
												/>
											)
										})
									) : (
										loadingGetTiposContrato ? (
											<Loading />
										) : (
											<>Tipos: {stateTiposContrato.msg}</>
										)
									)}
								</div>
								<div className={`${c['box-inputs']}`}>
									{exibirBloqueio(true)}
									<Input
										label="Título"
										req
										_={{
											value: stateDados.title,
											onChange: e => {
												setStateDados({
													...stateDados,
													title: e.target.value,
												});
											}
										}}
										show={toShow('title')}
									/>
									<Select
										label="Selecione o cliente"
										req
										_={{
											value: stateDados.id_customer,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_customer: e.value,
													id_project: null,
												});
												getProjetos({
													idCustomer: e.value,
												});
											},
											options: stateClientes.data,
										}}
										loading={loadingGetClientes}
										show={toShow('id_customer')}
									/>
									<Select
										label="Selecione o projeto"
										req
										_={{
											value: stateDados.id_project,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_project: e.value,
												});
											},
											options: stateProjetos.data,
										}}
										loading={loadingGetProjetos}
										show={toShow('id_project')}
									/>
									<Select
										label="Selecione o responsável"
										req
										_={{
											value: stateDados.id_user,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_user: e.value,
												});
											},
											options: stateUsuarios.data,
										}}
										loading={loadingGetUsuarios}
										show={toShow('id_user')}
									/>
									<Select
										label="Categoria"
										req
										_={{
											value: stateDados.id_area,
											onChange: e => {
												setStateProfissoes({
													...stateProfissoes,
													data: [],
												});
												setStateDados({
													...stateDados,
													id_area: e.value,
													id_profession: null,
												});
												getProfissoes({
													type: stateDados.type,
													id_area: e.value,
												});
											},
											options: stateAreas.data,
										}}
										loading={loadingGetAreas}
										show={toShow('id_area')}
									/>
									<Select
										label={`${stateDados.type === 'P' ? 'Profissão' : 'Serviço'}`}
										req
										_={{
											value: stateDados.id_profession,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_profession: e.value,
												});
												setStateDescricao(e.text);
											},
											options: stateProfissoes.data?.length ? [
												...stateProfissoes.data,
												{
													value: 'outro',
													label: 'Outro (informe a habilidade)',
												},
											] : [],
										}}
										loading={loadingGetProfissoes}
										show={toShow('id_profession')}
									/>
									<Input
										label="Principal habilidade do profissional"
										req
										_={{
											value: stateDados.custom_keyword,
											onChange: e => {
												setStateDados({
													...stateDados,
													custom_keyword: e.target.value,
												});
											}
										}}
										show={toShow('custom_keyword') && stateDados.id_profession === 'outro'}
									/>
									<Select
										label="Senioridade"
										req
										_={{
											value: stateDados.seniority,
											onChange: e => {
												setStateDados({
													...stateDados,
													seniority: e.value,
												});
											},
											options: SELECT_SENIORIDADE,
										}}
										show={toShow('seniority')}
									/>
									<Select
										label="Segmento"
										_={{
											value: stateSegmentos._,
											onChange: e => {
												setStateSegmentos({
													...stateSegmentos,
													_: e,
												});
											},
											options: stateSegmentos.data,
										}}
										loading={loadingGetSegmentos}
										show={toShow('segments')}
									/>
									<Select
										label="Idiomas"
										_={{
											value: stateIdiomas._,
											onChange: e => {
												setStateIdiomas({
													...stateIdiomas,
													_: e,
												});
											},
											options: stateIdiomas.data,
											isMulti: true,
										}}
										loading={loadingGetIdiomas}
										show={toShow('languages') && stateDados.type !== 'S'}
									/>
									<Select
										label="Competências/Softskills"
										_={{
											value: stateCompetencias._,
											onChange: e => {
												setStateCompetencias({
													...stateCompetencias,
													_: e,
												});
											},
											options: stateCompetencias.data,
											isMulti: true,
										}}
										loading={loadingGetCompetencias}
										show={toShow('skills') && stateDados.type !== 'S'}
									/>
									<Select
										label="Modelo de trabalho"
										req
										_={{
											value: stateDados.id_model_work,
											onChange: e => {
												setStateDados({
													...stateDados,
													id_model_work: e.value,
												});
											},
											options: stateModelosDeTrabalho.data,
										}}
										loading={loadingGetModelosDeTrabalho}
										show={toShow('id_model_work') && stateDados.type !== 'S'}
									/>
									{stateDados.id_model_work === 2 || stateDados.id_model_work === 3 ? (
										<Select
											label="Estado"
											req
											_={{
												value: stateDados.state,
												onChange: e => {
													setStateDados({
														...stateDados,
														state: e.value,
													});
												},
												options: stateEstados.data,
											}}
											loading={loadingGetEstados}
											show={toShow('state') && stateDados.type !== 'S'}
										/>
									) : null}
								</div>
								<div className={`${c['box-template']} inputs`}>
									{exibirBloqueio()}
									<IconeTextoRoot
										onClick={() => {
											setShowPortalTemplates(true);
										}}
										className={`${c['btn-template']} hover-bg-alt btn-texto`}
									>
										<IconeTexto.Icone img={imgPasta} />
										<IconeTexto.Texto texto="Templates" className="strong" />
									</IconeTextoRoot>

									{/* <div style={{border: '2px solid var(--color-1)', 'border-radius': '10px', padding: '20px', display: 'flex', gap: '10px'}}>
										<Btn
											_={{
												value: 'TinyMCE',
												onClick: () => setStateTypeEditor('default'),
												type: 'button'
											}}
										/>
										<Btn
											_={{
												value: 'Simple Wysiwig',
												onClick: () => setStateTypeEditor('react-simple-wysiwyg'),
												type: 'button'
											}}
										/>
										<Btn
											_={{
												value: 'JoditEditor',
												onClick: () => setStateTypeEditor('jodit-editor'),
												type: 'button'
											}}
										/>
										<Btn
											_={{
												value: 'Quill',
												onClick: () => setStateTypeEditor('quill'),
												type: 'button'
											}}
										/>
										<Btn
											_={{
												value: 'SuneEitor',
												onClick: () => setStateTypeEditor('suneditor'),
												type: 'button'
											}}
										/>
									</div> */}

									<Editor
										label="Descrição"
										value={stateDescricao}
										setValue={e => {
											console.log('e:', e)
											setStateDescricao(e);
										}}
										className={`${c['editor']}`}
										type={stateTypeEditor}
									/>

									<Btn
										className={`${c['btn-match']}`}
										_={{
											value: 'Realizar o Match',
											type: 'submit',
										}}
										loading={loadingCadastrar}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<PortalTemplates
				idTipo={1}
				show={showPortalTemplates}
				onClose={() => setShowPortalTemplates(false)}
				onSelect={e => {
					setShowPortalTemplates(false);
					setStateDescricao(e.text);
				}}
			/>

			<PortalAnimacaoMatchProfissionais
				show={showPortalCadastradoComSucesso}
				x={false}
				className={`${c['portal-sucesso']}`}
				onClose={() => setShowPortalCadastradoComSucesso(false)}
				bottom={
					<>
						<Btn
							_={{
								value: 'Visualizar Publicação',
								onClick: () => navigate(`/briefing/negociacao?id_briefing=${stateIdBriefingCadastrado}`),
							}}
						/>
					</>
				}
			/>
		</>
	);
}
