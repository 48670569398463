import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import { caminhoFoto, dataApiParaBr, dataPorExtenso, formatarDinheiro, getSenioridadeBySlug, htmlToJsx, joinArray, sanitize, validarDataAPI } from '@func/utils';
import moment from 'moment/min/moment-with-locales';

// Imagens
import imgIconeBriefing from '@svg/regular/file-lines.svg';
import imgIconeHistorico from '@svg/regular/clock-rotate-left.svg';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import apiBriefings from '@api/briefings/briefings';
import apiBriefingFreelancers from '@api/briefingFreelancers/briefingFreelancers';
import apiBriefingSkills from '@api/briefingSkills/briefingSkills';
import apiBriefingGoals from '@api/briefingGoals/briefingGoals';
import apiBriefingSegments from '@api/briefingSegments/briefingSegments';
import apiBriefingLanguages from '@api/briefingLanguages/briefingLanguages';
import apiBriefingCompetences from '@api/briefingCompetences/briefingCompetences';
import Portal from '@cpnt/Portal/Portal';
import BtnLabel from '@cpnt/BtnLabel/BtnLabel';
import Loading from '@cpnt/Loading/Loading';
import Info from '@cpnt/Info/Info';
import apiBriefingsHistory from '@api/briefingsHistory/briefingsHistory';
import HistoricoEdicaoProcessoSeletivo from '../HistoricoEdicaoProcessoSeletivo/HistoricoEdicaoProcessoSeletivo';
import PortalProcessoSeletivoInfoHistorico from '@portal/PortaisProcessoSeletivo/PortalProcessoSeletivoInfoHistorico/PortalProcessoSeletivoInfoHistorico';
import Button2 from '@cpnt/Button2/Button2';
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from "@route/routeUtils";
import { PortalProcessoSeletivoHistorico } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalProcessoSeletivoHistorico/PortalProcessoSeletivoHistorico";
import StoreContext from "@/_/contexts/StoreContext";
import { SELECT_TIPO_SERVICO_STRING } from "@config/objetos";
import { tipoOuServico } from "@_func/objects";

// css
// import './DetalhesProcessoSeletivo.css';

const DetalhesProcessoSeletivo = props => {

	const navigate = useNavigate();
	const location = useLocation();
	const [store, setStore] = useContext(StoreContext);

	const {
		id,
		className = '',
		wrap = '',
		after,

		stateDados = {},

		stateBriefingProfissionais = [],
		stateBriefingHabilidades = [],
		stateBriefingObjetivos = [],
		stateBriefingSegmentos = [],
		stateBriefingIdiomas = [],
		stateHistoricoDados = [],

		loadingGetProfissionais,
		loadingGetHabilidades,
		loadingGetObjetivos,
		loadingGetSegments,
		loadingGetIdiomas,
		loadingGetCompetencias,
		loadingGetHistorico,
	} = props;

	const [stateGet, setStateGet] = useState(gets());

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	/*
	** Overlay Perfil
	** ================================================== */
	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [statePerfilCompletoId, setStatePerfilCompletoId] = useState(false);
	const [statePerfilCompletoTipo, setStatePerfilCompletoTipo] = useState('freelancer');

	/*
	** Portais
	** ================================================== */
	const [showPortalInformacoesTextoBriefing, setShowPortalInformacoesTextoBriefing] = useState(false);
	const [showPortalHistoricoBriefing, setShowPortalHistoricoBriefing] = useState(false);
	const [showPortalInfoHistorico, setShowPortalInfoHistorico] = useState(false);

	/*
	** Loading
	** ================================================== */
	// const [loading, setLoading] = useState(true);
	// const [loadingGetProfissionais, setLoadingGetProfissionais] = useState(false);
	// const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	// const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(false);
	// const [loadingGetSegments, setLoadingGetSegments] = useState(false);
	// const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	// const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	// const [loadingGetHistorico, setLoadingGetHistorico] = useState(false);

	/*
	** state
	** ================================================== */

	// const [stateBriefingProfissionais, setStateBriefingProfissionais] = useState([]);
	// const [stateBriefingHabilidades, setStateBriefingHabilidades] = useState([]);
	// const [stateBriefingObjetivos, setStateBriefingObjetivos] = useState([]);
	// const [stateBriefingSegmentos, setStateBriefingSegmentos] = useState([]);
	// const [stateBriefingIdiomas, setStateBriefingIdiomas] = useState([]);
	// const [stateHistoricoId, setStateHistoricoId] = useState(false);
	// const [stateHistoricoDados, setStateHistoricoDados] = useState([]);

	/*
	** Init
	** ================================================== */
	// useEffect(() => {
	// 	inicializarGets();
	// }, []);

	// const inicializarGets = async () => {
	// 	setLoading(true);

	// 	const retGetBriefing = await apiBriefings.getOne.get(id);

	// 	if(retGetBriefing.status === 200) {
	// 		setLoading(false);
	// 		setStateDados(retGetBriefing.data);
	// 		_setStateDados({
	// 			status: 200,
	// 		});

	// 		// Gets
	// 		getProfissionais(id);
	// 		getHabilidades(id);
	// 		getObjetivos(id);
	// 		getSegments(id);
	// 		getIdiomas(id);

	// 	} else if(retGetBriefing.status === 403) {
	// 		_setStateDados({
	// 			status: 403,
	// 			msg: 'Você não tem permissão para acessar os dados dessa proposta de job.'
	// 		});
	// 		msgs([retGetBriefing]);
	// 	} else {
	// 		_setStateDados({
	// 			status: retGetBriefing.status,
	// 			msg: retGetBriefing.error,
	// 		});
	// 		msgs([retGetBriefing]);
	// 	}
	// 	setLoading(false);
	// }

	const segmentos = () => {
		if(!stateDados?.segments?.length) {
			return '(não informado)';
		}
		var segments = stateDados.segments.map(item => item.name);
		return segments.join(', ');
	}

	return (
		<>
			<div className={`cpnt-detprocsel ${wrap}`}>
				<div className={`cpnt-detprocsel-box ${className}`}>
					<div className="cpnt-process-info-dados">
						<div className="cpnt-process-info-dados-geral">
							<div>
								<h3 className="margin-t-0">Dados gerais</h3>
								<div>
									<LinhaItem
										titulo="Título"
										desc={stateDados.title}
										alt
									/>
									<LinhaItem
										titulo="ID"
										desc={stateDados.id}
										alt
									/>
									{store.usuario.role !== 9 ? (
										<>
											<LinhaItem
												titulo="Projeto"
												desc={stateDados.project_name}
												alt
											/>
										</>
									) : null}

								</div>
							</div>
							<div>
								<h3>Dados dos perfis dos profissionais</h3>
								<div>
									<LinhaItem
										titulo="Categoria"
										desc={stateDados.area_name ? stateDados.area_name : '(não informado)'}
										alt
									/>
									<LinhaItem
										titulo="Senioridade"
										desc={getSenioridadeBySlug(stateDados.seniority, '(não informado)')}
										alt
									/>
								</div>
							</div>

							<div>
								<h3>Dados de contratação</h3>
								<div>
									<LinhaItem
										titulo="Profissional ou serviço"
										desc={tipoOuServico(stateDados.type)}
										alt
									/>
									<LinhaItem
										titulo="Estado que o profissional reside"
										desc={stateDados.state || '(não informado)'}
										alt
									/>
									<LinhaItem
										titulo="Modelo de trabalho"
										desc={stateDados.model_work_name || '(não informado)'}
										alt
									/>
									<LinhaItem
										titulo="Palavra-chave"
										desc={stateDados.keyword || '(não informado)'}
										alt
									/>
									<LinhaItem
										titulo="Tipo de contratação"
										desc={stateDados.type_contract_name || '(não informado)'}
										alt
									/>
									{/* <LinhaItem
										titulo="Segmento"
										desc={segmentos()}
										alt
									/> */}
								</div>

								{store.usuario.role !== 9 ? (
									<>
										{/* Idiomas */}
										<div>
											<h3 className="margin-b-5">Idiomas</h3>
											{loadingGetIdiomas ? (
												<Loading alt />
											) : (
												<div className="wrap-5">
													<div className="flex flex-wrap">
														{!stateBriefingIdiomas.length ? (
															<div className="padding-h-5">Nenhum idioma selecionado.</div>
														) : (
															stateBriefingIdiomas.map((val, key) => {
																return (
																	<BtnLabel key={key} texto={val.name} x={false} />
																)
															})
														)}
													</div>
												</div>
											)}
										</div>
										{/* Competencias */}
										<div>
											<h3 className="margin-b-5">Competências</h3>
											{loadingGetCompetencias ? (
												<Loading alt />
											) : (
												<div className="wrap-5">
													<div className="flex flex-wrap">
														{!stateDados.length ? (
															<div className="padding-h-5">Nenhuma competência selecionada.</div>
														) : (
															stateDados.map((val, key) => {
																return (
																	<BtnLabel key={key} texto={val.name} x={false} />
																)
															})
														)}
													</div>
												</div>
											)}
										</div>
										{/* Segmentos */}
										<div>
											<h3 className="margin-b-5">Segmentos de interesse</h3>
											{loadingGetSegments ? (
												<Loading alt />
											) : (
												<div className="wrap-5">
													<div className="flex flex-wrap">
														{!stateBriefingSegmentos.length ? (
															<div className="padding-h-5">Nenhum segmento selecionado.</div>
														) : (
															stateBriefingSegmentos.map((val, key) => {
																return (
																	<BtnLabel key={key} texto={val.name} x={false} />
																)
															})
														)}
													</div>
												</div>
											)}
										</div>
									</>
								) : null}
							</div>

						</div>
						<div className="cpnt-process-info-dados-acoes">
							<div>
								<h3 className="margin-t-0 margin-b-10">Recrutador responsável</h3>
								<div>
									<ItemFotoDesc
										titulo={stateDados.user_name}
										subtitulo={stateDados.user_role_company}
										foto={caminhoFoto(stateDados.user_photo).url}
										onClick={() => {
											setStatePerfilCompletoId(stateDados.id_user);
											setStatePerfilCompletoTipo('usuario');
											setShowPortalPerfilCompleto(true);
										}}
										className="cursor-pointer"
									/>
								</div>
								<div>
									<h3>Visualizar</h3>
									<div>
										<TextoIconeLink
											texto="Visualizar briefing"
											icone={imgIconeBriefing}
											iconeCor="#000"
											className="cursor-pointer color-4"
											onClick={() => {
												setShowPortalInformacoesTextoBriefing(true);
											}}
										/>
									</div>
									{store.usuario.role !== 9 ? (
										<div className="margin-t-5">
											<TextoIconeLink
												texto={`Histórico de edições (${stateDados.count_changes})`}
												icone={imgIconeHistorico}
												iconeCor="#000"
												className="cursor-pointer color-4"
												onClick={() => {
													setShowPortalInfoHistorico(true);
													// showPortal({portal: 'historico', dados: stateDados.id, stateGet, navigate})
												}}
											/>
										</div>
									) : null}
								</div>
							</div>
							<div>
								<h3>Datas</h3>
								<div>
									<div>
										<LinhaItem
											titulo="Status atual"
											desc={`${stateDados.status_name}${stateDados.task_status_name ? ` / ${stateDados.task_status_name}` : ''}`}
											alt
										/>

									</div>

									<LinhaItem
										titulo="Criado em"
										desc={dataPorExtenso(stateDados.created_at, true, 'há ', '', '')}
										alt
									/>
									<LinhaItem
										titulo="Recrutar até"
										desc={
											<>
											{stateDados.delivery_at && stateDados.delivery_at !== '' ? (
												<>
													{dataApiParaBr(stateDados.delivery_at)}
													{stateDados.status === 20 ? (
														<>
															<span className="color-1">
																(faltam {moment(stateDados.created_at).locale('pt-br').fromNow(true)})
															</span>
														</>
													) : null}
												</>
											) : (
												<>
													{dataApiParaBr(stateDados.delivery_at)}
													{validarDataAPI(stateDados.delivery_at) ? (
														<span className="color-1">
															(finalizou há {moment(stateDados.delivery_at).locale('pt-br').fromNow(true)})
														</span>
													) : (
														<span className="color-1">Não informado</span>
													)}
												</>
											)}
											</>
										}
										alt
									/>
								</div>
							</div>
						</div>
					</div>

					{/* Profissionais */}
					<div>
						<h3 className="margin-b-5">Profissional contratado</h3>
						{stateDados.contracted_freela_id ? (
							<ItemFotoDesc
								titulo={stateDados.contracted_freela_name}
								subtitulo={stateDados.user_role_company}
								foto={caminhoFoto(stateDados.contracted_freela_photo).url}
								onClick={() => {
									setStatePerfilCompletoId(stateDados.contracted_freela_id);
									setStatePerfilCompletoTipo('freelancer');
									setShowPortalPerfilCompleto(true);
								}}
								className="cursor-pointer"
							/>
						) : (
							<div>Nenhum profissional contratado.</div>
						)}
					</div>

					{/* Profissionais */}
					<div>
						<h3 className="margin-b-5">Profissionais selecionados</h3>
						{loadingGetProfissionais ? (
							<Loading alt />
						) : (
							<div className="wrap-h-5">
								{stateBriefingProfissionais.length ? stateBriefingProfissionais.map((val, key) => {
									return (
										<ItemFotoDesc
											key={key}
											titulo={val.name_freelancer}
											subtitulo={val.area}
											foto={caminhoFoto(val.photo).url}
											className="margin-5 cursor-pointer"
											onClick={() => {
												setStatePerfilCompletoTipo('freelancer');
												setStatePerfilCompletoId(val.id_freelancer);
												setShowPortalPerfilCompleto(true);
											}}
										/>
									)
								}) : (
									<div className="padding-h-5">Nenhum profissional selecionado.</div>
								)}
							</div>
						)}
					</div>

				</div>
				{after}
			</div>


			{/* Portal Texto do briefing */}
			<Portal
				titulo="Descrição da proposta de job"
				show={showPortalInformacoesTextoBriefing}
				closeOnEsc={false}
				onClose={() => {
					setShowPortalInformacoesTextoBriefing(false)
				}}
				className="w-800"
			>
				<div className="box-txt">{htmlToJsx(typeof(stateDados.text) === 'string' ? stateDados.text : '<div>Nenhuma descrição adicionada a essa proposta de job.</div>')}</div>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => {
								setShowPortalInformacoesTextoBriefing(false);
							}
						}}
					/>
				</div>
			</Portal>

			{/* Portal informações hunter / freela */}
			<PortalPerfil
				{...(statePerfilCompletoTipo === 'usuario' ? {
					idUsuario: statePerfilCompletoId
				} : {
					idFreelancer: statePerfilCompletoId
				})}
				show={showPortalPerfilCompleto}
				tipo={statePerfilCompletoTipo}
				onClose={() => {
					setShowPortalPerfilCompleto(false);
				}}
			/>

			{/* Portal - Info do histórico */}
			{/* <PortalProcessoSeletivoInfoHistorico
				id={stateDados.id}
				historico={stateHistoricoDados}
				show={showPortalInfoHistorico}
				onClose={() => {
					setShowPortalInfoHistorico(false);
				}}
			/> */}
			<PortalProcessoSeletivoHistorico
				id={stateDados.id}
				show={showPortalInfoHistorico}
				onClose={() => {
					setShowPortalInfoHistorico(false);
				}}
			/>
		</>
	);
};

export default DetalhesProcessoSeletivo;