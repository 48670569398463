import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast as msg } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { Link } from 'react-router-dom';
import qs from 'qs';

// Store e hooks
import StoreContext from '@/_/contexts/StoreContext';
// import useWindowFocus from '@hook/useWindowFocus';

// Textos
import JOB_MENSAGENS_PADROES from '@texto/jobMensagensPadroes';

// css
import './NegociarProcessoSeletivo.css';

// API
import apiBriefingMessages from '@api/briefingMessages/briefingMessages';
import apiBriefingFreelancers from '@api/briefingFreelancers/briefingFreelancers';
import socket from '@_api/socket';
import apiBriefings from '@api/briefings/briefings';
import apiBriefingSkills from '@api/briefingSkills/briefingSkills';
import apiBriefingGoals from '@api/briefingGoals/briefingGoals';
import apiBriefingSegments from '@api/briefingSegments/briefingSegments';
import apiBriefingLanguages from '@api/briefingLanguages/briefingLanguages';
import apiBriefingCompetences from '@api/briefingCompetences/briefingCompetences';

// Imagens
import imgIconeVoltar from '@svg/solid/arrow-left.svg';
import imgIconeValor from '@svg/light/sack-dollar.svg';
import imgIconeCheck from '@svg/solid/circle-check.svg';
import imgIconeUsuario from '@svg/solid/user.svg';
import imgIconeNaoPago from '@svg/regular/clock.svg';
import imgIconeDescBriefing from '@svg/light/file-lines.svg';
import imgIconeHistorico from '@svg/regular/clock-rotate-left.svg';
import imgIconeEditar from '@svg/regular/pen.svg';
// import imgIconeEditar from '@svg/solid/file-pen.svg';
import imgIconeDetalhesBriefing from '@svg/light/file-magnifying-glass.svg';
import imgIconeBriefing from '@svg/regular/file-lines.svg';

// Components
import Foto from '@cpnt/Foto/Foto';
import FotosAgrupadas from '@cpnt/FotosAgrupadas/FotosAgrupadas';
import BtnLabel from '@cpnt/BtnLabel/BtnLabel';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import Icone from '@cpnt/Icone/Icone';
import LinhaItem from '@cpnt/LinhaItem/LinhaItem';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';
import Portal from '@cpnt/Portal/Portal';
import Button2 from '@cpnt/Button2/Button2';
import Equipe from '@cpnt/Equipe/Equipe';
import ChatContainer from '@cpnt/Chat/ChatContainer/ChatContainer';
import Input2 from '@cpnt/Input2/Input2';
import ChatMensagem from '@cpnt/Chat/ChatMensagem/ChatMensagem';
import Radio2 from '@cpnt/Radio2/Radio2';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import Loading from '@cpnt/Loading/Loading';
import { iconeStatusBriefing } from '@cpnt/StatusBriefing/StatusBriefing';
import { iconeStatusCard } from '@cpnt/StatusCards/StatusCards';
import PortalConfirmacao from '@portalGeral/PortalConfirmacao/PortalConfirmacao';

// Imagens
import imgIconeDetalhes from '@svg/regular/file-lines.svg';
import imgIconePDF from '@svg/solid/file-pdf.svg';
import imgIconeXML from '@svg/solid/file-code.svg';
import imgIconeInfo from '@svg/solid/circle-info.svg';

// Funções
import { formatarMensagemChat } from '@shared/functions/components';
import { caminhoFoto, camposInvalidos, dataApiParaBr, disponibilidadeFreelancer, formatarDinheiro, getSenioridadeBySlug, html, htmlToJsx, isNumeroDiferente, joinArray, removerKeysVazias, sanitize } from '@func/utils';
import notificacao from '@_func/notification';
import { chatFormatarDadosUsuario, chatFormatarDadosUsuarioSidebar, chatGetId } from '@cpnt/Chat/functions';
import HistoricoEdicaoProcessoSeletivo from '../ProcessosSeletivos/HistoricoEdicaoProcessoSeletivo/HistoricoEdicaoProcessoSeletivo';
import PortalProcessoSeletivoInfoHistorico from '@portal/PortaisProcessoSeletivo/PortalProcessoSeletivoInfoHistorico/PortalProcessoSeletivoInfoHistorico';
import DetalhesProcessoSeletivo from '../ProcessosSeletivos/DetalhesProcessoSeletivo/DetalhesProcessoSeletivo';
import { DEV } from '@_config/config';
import useWindowDimension from "@/_/hooks/useWindowDimensions";
import { initFuncExec, setGet } from "@route/routeUtils";
import { apiGetBriefingCompetenciasGetAll, apiGetBriefingIdiomasGetAll, apiGetBriefingSegmentosGetAll } from "@api/_functions/geral";
import Voltar from "@cpnt/Voltar/Voltar";
import Tip from "@cpnt/Tip/Tip";
import { PortalProcessoSeletivoHistorico } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalProcessoSeletivoHistorico/PortalProcessoSeletivoHistorico";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import { PortalPropostaDeJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalPropostaDeJobDetalhes/PortalPropostaDeJobDetalhes";
import useDebounce from "@/_/hooks/useDebounce";
import PortalLateral from "@cpnt/Portais/PortaisGerais/PortalLateral/PortalLateral";
import useWindowFocus from "@/_/hooks/useWindowFocus";
import apiTemplates from "@api/templates/templates";

import { hash } from "@_func/generate";
import { msgs, msgStatus } from "@_func/request";
import { PortalTermosContratacaoSegura } from "@cpnt/Portais/PortaisTermos/PortalTermosContratacaoSegura/PortalTermosContratacaoSegura";

const NegociarProcessoSeletivo = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();
	const [locationBackup, setLocationBackup] = useState({});

	const [isDigitando, setIsDigitando] = useState(false);
	const debounceEvento = useDebounce(isDigitando, 500);

	useEffect(() => {
		setLocationBackup(location);
	}, []);

	/*
	** Debounce - Para não ficar enviando o evento a todo momento
	** ================================================== */
	useEffect(() => {
		socket.emit('message', {
			data: {
				id_freelancer: stateGet.id_freelancer,
				id_task: stateGet.id_task,
			},
			context: 'TasksMessages',
			function: 'userTyping',
		});
	}, [debounceEvento]);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = (getsAtualizados = getsDefault) => {
		initFuncExec([
			{
				func: getsIniciais,
				param: getsAtualizados,
				init: true,
			},
			{
				func: getsIniciais,
				param: getsAtualizados,
				condicao: init && isNumeroDiferente(stateGet.id_briefing, getsAtualizados.id_briefing),
			},
		], init, location, stateLocation);
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		overlay: [],
		id_briefing: null,
		id_freelancer: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	// Eventos para mensagens lidas
	const janelaAtiva = useWindowFocus();
	const janelaAtivaRef = useRef();
	const [emitirEventoMensagemLida, setEmitirEventoMensagemLida] = useState(false);

	const [showPortalPerfilCompleto, setShowPortalPerfilCompleto] = useState(false);
	const [showPortalTextoBriefing, setShowPortalTextoBriefing] = useState(false);
	const [showPortalDetalhesBriefing, setShowPortalDetalhesBriefing] = useState(false);
	const [showPortalConfirmacaoComplementarProcessoSeletivo, setShowPortalConfirmacaoComplementarProcessoSeletivo] = useState(false);
	const [stateIdUsuarioPerfilCompleto, setStateIdUsuarioPerfilCompleto] = useState(false);

	// Chat
	const [mensagensSidebar, setMensagensSidebar] = useState([]);
	const [loadingGetMensagensChat, setLoadingGetMensagensChat] = useState(false);
	const [mensagensChat, setMensagensChat] = useState([]);
	const [mensagemChat, setMensagemChat] = useState('');
	const [conversandoComChat, setConversandoComChat] = useState([]);
	const [usuariosOnline, setUsuariosOnline] = useState([]);
	const [mensagensSidebarUsuariosOnline, setMensagensSidebarUsuariosOnline] = useState([]);
	const [loadingGetMensagensSidebar, setLoadingGetMensagensSidebar] = useState(true);
	const [showTelaInicialChat, setShowTelaInicialChat] = useState(true);
	const [showPortalMensagemBotaoProposta, setShowPortalMensagemBotaoProposta] = useState(false);
	const [filtroSidebar, setFiltroSidebar] = useState(1);

	// const _stateProposta = {
	// 	sim: {
	// 		...JOB_MENSAGENS_PADROES.propostaAprovada,
	// 		tipo: 'verde',
	// 		resposta: true,
	// 	},
	// 	nao: {
	// 		...JOB_MENSAGENS_PADROES.propostaRecusada,
	// 		tipo: 'vermelho',
	// 		resposta: false,
	// 	},
	// }
	const [stateProposta, setStateProposta] = useState({
		// ..._stateProposta.sim,
		idProposta: false,
	});

	// Chat eventos
	const [isDigitandoChat, setIsDigitandoChat] = useState(false);
	const [isDigitandoSidebarChat, setIsDigitandoSidebarChat] = useState(false);
	const [isDigitandoInfoChat, setIsDigitandoInfoChat] = useState(false);
	const [isDigitandoSidebarInfoChat, setIsDigitandoSidebarInfoChat] = useState(false);

	const queryIdBriefingRef = useRef(stateGet.id_briefing);
	const queryIdFreelancerRef = useRef(stateGet.id_freelancer);
	const statePropostaRef = useRef(stateProposta);
	const mensagensSidebarRef = useRef([]);
	const mensagensChatRef = useRef([]);

	/*
	** Portais
	** ================================================== */
	const [showPortalHistoricoBriefing, setShowPortalHistoricoBriefing] = useState(false);
	const [showPortalInfoHistorico, setShowPortalInfoHistorico] = useState(false);
	const [showPortalResponsavel, setShowPortalResponsavel] = useState(false);
	const [showPortalTermosContratacaoSegura, setShowPortalTermosContratacaoSegura] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateBriefing, setStateBriefing] = useState({});
	const [stateBriefingMetadata, setStateBriefingMetadata] = useState({});
	const [_stateBriefing, _setStateBriefing] = useState({});
	const [_retGetBriefing, _setRetGetBriefing] = useState(null);
	const [loadingGetBriefing, setLoadingGetBriefing] = useState(false);
	const [stateIdBriefingHistorico, setStateIdBriefingHistorico] = useState(false);
	const [stateBriefingHistoricoId, setStateBriefingHistoricoId] = useState(false);

	const [stateBriefingHabilidades, setStateBriefingHabilidades] = useState([]);
	const [stateBriefingObjetivos, setStateBriefingObjetivos] = useState([]);
	const [stateBriefingSegmentos, setStateBriefingSegmentos] = useState([]);
	const [stateBriefingIdiomas, setStateBriefingIdiomas] = useState([]);
	const [stateBriefingCompetencias, setStateBriefingCompetencias] = useState([]);

	const [stateHistoricoId, setStateHistoricoId] = useState(queryIdBriefingRef);
	const [stateHistoricoDados, setStateHistoricoDados] = useState(queryIdBriefingRef);

	/*
	** Log para a sidebar
	** ================================================== */
	// const [stateInfoMensagensSidebar, setStateInfoMensagensSidebar] = useState({
	// 	totalFreelancers: 0,
	// 	mensagensRespondidas: 0,
	// });

	/*
	** Loading
	** ================================================== */
	const [loadingGetBriefingSegmentos, setLoadingGetBriefingSegmentos] = useState(false);
	const [loadingGetBriefingIdiomas, setLoadingGetBriefingIdiomas] = useState(false);
	const [loadingGetBriefingCompetencias, setLoadingGetBriefingCompetencias] = useState(false);

	const [loadingGetMensagemProposta, setLoadingGetMensagemProposta] = useState(false);
	const [stateTemplateSim, setStateTemplateSim] = useState({});
	const [stateTemplateNao, setStateTemplateNao] = useState({});

	const stateGetRef = useRef(stateGet);

	useEffect(() => {
		stateGetRef.current = stateGet;
	}, [stateGet]);

	/*
	** Gets iniciais
	** ================================================== */
	const getsIniciais = async (dadosObj = {}) => {
		setLoadingGetBriefing(true);

		const retGetBriefing = await apiBriefings.getOne.get(dadosObj.id_briefing);
		_setStateBriefing(msgStatus(retGetBriefing));

		if(retGetBriefing.status === 200) {
			// apiGetBriefingSkills
			// apiGetBriefingGoals
			setStateBriefing(retGetBriefing.data);
			getConversasSidebar();
			// apiGetBriefingSegmentosGetAll({idBriefing: stateGet.id_briefing, setLoadingGetBriefingSegmentos, setStateBriefingSegmentos});
			// apiGetBriefingIdiomasGetAll({idBriefing: stateGet.id_briefing, setLoadingGetBriefingIdiomas, setStateBriefingIdiomas});
			// apiGetBriefingCompetenciasGetAll({idBriefing: stateGet.id_briefing, setLoadingGetBriefingCompetencias, setStateBriefingIdiomas});
		}

		setLoadingGetBriefing(false);
		setLoadingGetMensagensSidebar(false);
	}

	/*
	** Alterar estado do focus
	** ================================================== */
	useEffect(() => {
		janelaAtivaRef.current = janelaAtiva;
	}, [janelaAtiva]);

	/*
	** Verifica se tem novas mensagens e emite evento
	** ================================================== */
	useEffect(() => {
		if(emitirEventoMensagemLida) {
			marcarMensagemComoLida();
		}
	}, [janelaAtiva]);

	useEffect(() => {
		if(janelaAtivaRef.current) {
			marcarMensagemComoLida();
		}
	}, [emitirEventoMensagemLida]);

	/*
	** Chat - Loga na sala de acordo com a URL
	** ================================================== */
	useEffect(() => {
		if(mensagensSidebar.length && usuariosOnline.length) {
			const listaOnlineAtivo = definirUsuariosOnlineSidebar({
				usuariosOnline: usuariosOnline,
				mensagensSidebar: mensagensSidebar,
			});
			setMensagensSidebarUsuariosOnline(listaOnlineAtivo);
		}
	}, [mensagensSidebar, usuariosOnline]);

	/*
	** Chat - Loga na sala de acordo com a URL
	** ================================================== */
	useEffect(() => {
		inicializarDados();
	}, [stateGet.id_briefing, stateGet.id_freelancer]);

	/*
	** Chat - Desconecta da sala ao sair da página
	** ================================================== */
	useEffect(() => {
		return () => {
			socket.emit('message', {
				data: {
					id_briefing: queryIdBriefingRef.current,
					id_freelancer: queryIdFreelancerRef.current,
				},
				context: 'BriefingMessages',
				function: 'leaveRoom'
			});
		}
	}, []);

	/*
	** Inicializar dados
	** ================================================== */
	const inicializarDados = async () => {
		/*
		** Define novos valores para os refs de acordo com o
		** briefing e id_freelancer do GET
		** ================================================== */
		const queryIdBriefing = Number(stateGet.id_briefing);
		const queryIdFreelancer = Number(stateGet.id_freelancer);

		queryIdBriefingRef.current = queryIdBriefing;
		queryIdFreelancerRef.current = queryIdFreelancer;

		/*
		** Conecta na sala correspondente
		** ================================================== */
		if(queryIdBriefing && queryIdFreelancer) {
			socket.emit('message', {
				data: {
					id_briefing: queryIdBriefing,
					id_freelancer: queryIdFreelancer,
				},
				context: 'BriefingMessages',
				function: 'joinRoom'
			});
		}

		/*
		** Chat - Sidebar - Adiciona o ativo no item correspondente
		** ================================================== */
		const mensagensSidebarAtivo = mensagensSidebarRef.current.map(val => {
			if(val._id === queryIdFreelancer) {
				return {
					...val,
					ativo: true,
					propria: val._id === store.usuario.id ? true : false,
				}
			}
			return {
				...val,
				ativo: false,
				propria: val._id === store.usuario.id ? true : false,
			};
		});
		setMensagensSidebar(mensagensSidebarAtivo);
		setMensagensSidebarUsuariosOnline(mensagensSidebarAtivo);
		mensagensSidebarRef.current = mensagensSidebarAtivo;

		/*
		** Chat - Pega as mensagens da conversa
		** ================================================== */
		if(queryIdFreelancer) {
			const retGetMensagens = await getMensagensFreelancer({
				id_briefing: queryIdBriefing,
				id_freelancer: queryIdFreelancer,
			});
			setMensagensChat(retGetMensagens);
			mensagensChatRef.current = retGetMensagens;
		}

		/*
		** Definir com quem está conversando
		** ================================================== */
		const dadosUsuarioSidebarAtivo = mensagensSidebarAtivo.filter(item => item._.id_freelancer === queryIdFreelancer);
		const dadosUsuarioSidebarAtivoFormatados = dadosUsuarioSidebarAtivo.map(val => {
			return {
				...chatFormatarDadosUsuario(val),
				_id: chatGetId(val),
				nome: val.name,
				foto: val.photo,
			}
		});
		setConversandoComChat(dadosUsuarioSidebarAtivoFormatados);
	}

	/*
	** Chat - inicializar
	** ================================================== */
	useEffect(() => {
		if(!socket.connected) {
			socket.auth.token = store.token;
  			socket.connect();
		}
	}, []);

	/*
	** Chat
	** ================================================== */
	useEffect(() => {
		/*
		** Conectado
		** ================================================== */
		socket.on('connected', e => {
			const mensagensSidebarSemAtual = mensagensSidebarRef.current.map(val => {
				if(val._id === chatGetId(e.data)) {
					return {
						...val,
						online: true,
					}
				}
				return val;
			});
			setMensagensSidebar(mensagensSidebarSemAtual);
			mensagensSidebarRef.current = mensagensSidebarSemAtual;
		});

		/*
		** Desconectado
		** ================================================== */
		socket.on('disconnected', e => {
			const mensagensSidebarComOnline = mensagensSidebarRef.current.map(val => {
				if(val._id === chatGetId(e.data)) {
					return {
						...val,
						online: false,
					}
				}
				return val;
			});
			setMensagensSidebar(mensagensSidebarComOnline);
			mensagensSidebarRef.current = mensagensSidebarComOnline;
		});

		/*
		** Digitando
		** ================================================== */
		socket.on('typing', e => {
			if(e.data.id_freelancer === queryIdFreelancerRef.current) {
				setIsDigitandoChat(true);
				setIsDigitandoInfoChat(chatFormatarDadosUsuario(e.data));
			}
		});

		/*
		** Digitando - Sala
		** ================================================== */
		socket.on('typingBriefing', e => {
			setIsDigitandoSidebarChat(true);
			setIsDigitandoSidebarInfoChat(chatFormatarDadosUsuario(e.data));
		});

		/*
		** Nova mensagem do outro usuário - insere no chat
		** ================================================== */
		socket.on('getMessage', dados => {
			setIsDigitandoChat(false);
			setIsDigitandoSidebarChat(false);

			var mensagensAtualizadas = [...mensagensChatRef.current];
			mensagensAtualizadas.push({
				...formatarMensagemChat(dados.data),
				propria: false,
			});

			var msgSidebar = [];

			if(mensagensSidebarRef.current && typeof(mensagensSidebarRef.current) === 'object') {
				msgSidebar = mensagensSidebarRef.current.map(val => {
					var ativo = false;
					if(val._.id_freelancer === Number(stateGet.id_freelancer)) {
						ativo = true;
					}
					if(val._.id_freelancer === dados.data.id_freelancer) {
						return {
							...val,
							_: dados.data,
							ativo: ativo,
						}
					}
					return {
						...val,
						ativo: ativo,
					};
				});
				mensagensSidebarRef.current = msgSidebar;
				setMensagensSidebar(msgSidebar);
			}

			setMensagensChat(mensagensAtualizadas);

			mensagensChatRef.current = mensagensAtualizadas;
			setEmitirEventoMensagemLida(true);

			if(!janelaAtivaRef.current) {
				var nome = dados.data.name_frelancer ? dados.data.name_frelancer : dados.data.name_user;
				if(dados.data.type === 'imagem-url') {
					notificacao(nome, {
						body: `${nome} enviou uma imagem`,
						icon: dados.data.metadata.imagem.preview
					});
				} else {
					notificacao(nome, {
						body: dados.data.text && dados.data.text !== '' ? dados.data.text : 'Você recebeu uma nova mensagem.',
						icon: caminhoFoto(dados.data.photo_user_freela ? dados.data.photo_user_freela : dados.data.photo_user).url
					});
				}
			}
		});

		/*
		** Própria mensagem enviada e cadastrada, insere no chat
		** ================================================== */
		socket.on('insertMessage', dados => {
			if(dados.success) {
				// Mensages do chat
				var mensagensAtualizadas = [...mensagensChatRef.current];
				mensagensAtualizadas.push({
					...formatarMensagemChat(dados.data),
					propria: true,
				});
				setMensagensChat(mensagensAtualizadas);
				mensagensChatRef.current = mensagensAtualizadas;

				// Mensagens sidebar
				var freelaAtual = {};
				const mensagensSidebarComMsgAtual = mensagensSidebarRef.current.filter(val => {
					if(val._ && val._.id_freelancer === dados.data.id_freelancer) {
						freelaAtual = val;
					}
					if(val._) {
						return val._.id_freelancer !== dados.data.id_freelancer;
					}
					return val;
				});
				mensagensSidebarComMsgAtual.unshift({
					...freelaAtual,
					mensagem: dados.data.text,
				});
				setMensagensSidebar(mensagensSidebarComMsgAtual);
				mensagensSidebarRef.current = mensagensSidebarComMsgAtual;
			} else {
				msg.error(dados.message, {
					autoClose: false,
				});
			}
		});

		/*
		** Verifica os usuários que estão online
		** ================================================== */
		socket.on('usersOnline', dados => {
			setUsuariosOnline(dados.users);
		});

		/*
		** Mensagens da barra latera
		** ================================================== */
		socket.on('newMessage', e => {
			if(e && e.data) {
				const mensagensSidebarSemAtual = mensagensSidebarRef.current.filter(val => val._id !== chatGetId(e.data));
				mensagensSidebarSemAtual.unshift(chatFormatarDadosUsuarioSidebar(e.data));
				setMensagensSidebar(mensagensSidebarSemAtual);
				mensagensSidebarRef.current = mensagensSidebarSemAtual;
			}
		});

		/*
		** Mensagens lidas
		** ================================================== */
		socket.on('messagesReaded', dados => {
			if(dados.data.id_user !== store.usuario.id) {
				const mensagensLidas = mensagensChatRef.current.map(val => {
					return {
						...val,
						mensagemLida: true,
					}
				});
				setMensagensChat(mensagensLidas);
				mensagensChatRef.current = mensagensLidas;
			}
		});

		/*
		** Conectando na sala
		** ================================================== */
		socket.on('joinChatRoom', dados => {
			// const mensagensSidebarSemAtual = mensagensSidebarRef.current.map(val => {
			// 	if(val._id === chatGetId(dados.data)) {
			// 		return {
			// 			...val,
			// 			online: true,
			// 		}
			// 	}
			// 	return val;
			// });
			// setMensagensSidebar(mensagensSidebarSemAtual);
			// mensagensSidebarRef.current = mensagensSidebarSemAtual;
		});

		/*
		** Se conecta na sala geral de briefing
		** ================================================== */
		socket.emit('message', {
			data: {
				id_briefing: stateGet.id_briefing
			},
			context: 'BriefingHunter',
			function: 'joinBriefingRoom'
		});

		/*
		** Se conecta na sala geral de briefing
		** ================================================== */
		socket.emit('message', {
			data: {},
			context: 'UserSocket',
			function: 'getUsersOnline'
		});

		/*
		** Proposta aceita
		** ================================================== */
		socket.on('acceptedPropose', dados => {
			if(dados.success) {
				confirmacaoDeRespostaMensagemChat(dados);
			} else {
				msg.error(dados.error);
			}
		});

		/*
		** Proposta recusada
		** ================================================== */
		socket.on('refusedPropose', dados => {
			if(dados.success) {
				confirmacaoDeRespostaMensagemChat(dados);
			} else {
				msg.error(dados.error);
			}
		});

	}, [socket]);

	/*
	** API - Get Dados briefing
	** ================================================== */
	const getBriefing = async () => {
		const idBriefing = stateGet.id_briefing;
		if(!idBriefing) {
			msg.error('O id do briefing deve ser informado.');
			return;
		}

		setLoadingGetBriefing(true);
		const retGetBriefing = await apiBriefings.getOne.get(idBriefing);

		if(retGetBriefing.status === 200) {
			setStateBriefing(retGetBriefing.data);
			_setRetGetBriefing(200);

			// Habilidades
			const retGetHabilidades = await apiBriefingSkills.getAll.get(idBriefing);
			if(retGetHabilidades.status === 200) {
				setStateBriefingHabilidades(retGetHabilidades.data);
			} else {
				setStateBriefingHabilidades([]);
			}

			// Objetivos
			const retGetObjetivos = await apiBriefingGoals.getAll.get(idBriefing);
			if(retGetObjetivos.status === 200) {
				setStateBriefingObjetivos(retGetObjetivos.data);
			} else {
				setStateBriefingObjetivos([]);
			}

			// Segmentos
			const retGetSegments = await apiBriefingSegments.getAll.get(idBriefing);
			if(retGetSegments.status === 200) {
				setStateBriefingSegmentos(retGetSegments.data);
			} else {
				setStateBriefingSegmentos([]);
			}

			// Idiomas
			const retGetIdiomas = await apiBriefingLanguages.getAll.get(idBriefing);
			if(retGetIdiomas.status === 200) {
				setStateBriefingIdiomas(retGetIdiomas.data);
			} else {
				setStateBriefingIdiomas([]);
			}

			// Competências
			const retGetCompetencias = await apiBriefingCompetences.getAll.get(idBriefing);
			if(retGetCompetencias.status === 200) {
				setStateBriefingCompetencias(retGetCompetencias.data);
			} else {
				setStateBriefingCompetencias([]);
			}

		} else if(retGetBriefing.status === 403) {
			setStateBriefing({});
			_setRetGetBriefing(403);
		} else {
			setStateBriefing([]);
			_setRetGetBriefing(false);
			msgs([retGetBriefing]);
		}
		setLoadingGetBriefing(false);
	}

	/*
	** agrupar mensagens e ver quem está online
	** ================================================== */
	const definirUsuariosOnlineSidebar = (dadosObj = {}) => {
		const dados = {
			mensagensSidebar: mensagensSidebar,
			usuariosOnline: usuariosOnline,
			...dadosObj,
		};

		if(dados.mensagensSidebar.length === 0) {
			return dados.mensagensSidebar;
		}

		const mensagensSidebarComOnline = dados.mensagensSidebar.map((val, key) => {
			var id = chatGetId(val._);
			var obj = _.find(dados.usuariosOnline, {id_user: id});

			val.propria = false;
			if(val._.id_user === store.usuario.id) {
				val.propria = true;
			}
			if(!obj) {
				obj = _.find(dados.usuariosOnline, {id_freelancer: id});
			}
			if(obj) {
				return {
					...val,
					online: true,
				}
			}
			// return {};
			return val;
		});

		return mensagensSidebarComOnline;
	}

	/*
	** Marcar mensagem como lida
	** ================================================== */
	const marcarMensagemComoLida = (dados = {}) => {
		const dadosMensagem = {
			id_briefing: queryIdBriefingRef.current,
			id_freelancer: queryIdFreelancerRef.current,
			...dados,
		};

		const dadosEnviar = {
			data: dadosMensagem,
			context: 'BriefingMessages',
			function: 'readingMessages'
		};

		socket.emit('message', dadosEnviar);
		setEmitirEventoMensagemLida(false);
	}

	/*
	** API - Get mensagens do freelancer
	** ================================================== */
	const getMensagensFreelancer = async (dados = {}) => {
		setLoadingGetMensagensChat(true);
		setShowTelaInicialChat(false);
		const dadosEnviar = {
			id_briefing: queryIdBriefingRef.current,
			id_freelancer: queryIdFreelancerRef.current,
			limit: 999999,
			...dados,
		};

		const retGetMensagens = await apiBriefingMessages.all.get(dadosEnviar);
		var mensagensFormatadas = [];

		if(retGetMensagens.status === 200) {
			mensagensFormatadas = retGetMensagens.data.map(val => {
				return {
					...formatarMensagemChat(val),
					// propria: val.id_user === store.usuario.id ? true : false
					propria: val.id_user ? true : false
				};
			});
			marcarMensagemComoLida(dadosEnviar);
		} else {
			// msgs([retGetMensagens]);
		}
		setLoadingGetMensagensChat(false);
		return mensagensFormatadas;
	}

	/*
	** API - Get de conversas da sidebar
	** ================================================== */
	const getConversasSidebarApi = async (dados = {}) => {
		const dadosEnviar = {
			id_briefing: queryIdBriefingRef.current,
			status: filtroSidebar,
			...dados,
		};

		const retGetMensagens = await apiBriefingFreelancers.allChats.get(dadosEnviar);

		// const info = {
		// 	totalFreelancers: 0,
		// 	mensagensRespondidas: 0,
		// }

		// // TODOS
		// const retMsgAll = await apiBriefingFreelancers.allChats.get({
		// 	id_briefing: queryIdBriefingRef.current,
		// 	status: 2,
		// });

		// if(retMsgAll.status === 200) {
		// 	info.totalFreelancers = retMsgAll.data.length;
		// }

		// // Respondidos
		// const regMsgResp = await apiBriefingFreelancers.allChats.get({
		// 	id_briefing: queryIdBriefingRef.current,
		// 	status: 1,
		// });

		// if(retMsgAll.status === 200) {
		// 	info.mensagensRespondidas = regMsgResp.data.length;
		// }


		if(retGetMensagens.status === 200) {
			const listaComAtivo = retGetMensagens.data.map(item => {
				return {
					...chatFormatarDadosUsuarioSidebar(item),
					ativo: item.id_freelancer === Number(queryIdFreelancerRef.current),
					propria: item._id === store.usuario.id ? true : false,
					mensagemLida: item.readed,
				}
			});
			return {
				...retGetMensagens,
				formatado: listaComAtivo,
			};
		}

		return retGetMensagens;
	}

	/*
	** API - Get de conversas da sidebar
	** ================================================== */
	const getConversasSidebar = async (dados = {}) => {
		setLoadingGetMensagensSidebar(true);
		const getConversasApi = await getConversasSidebarApi(dados);
		if(getConversasApi.status === 200) {
			setMensagensSidebar(getConversasApi.formatado);
			setMensagensSidebarUsuariosOnline(getConversasApi.formatado);
			mensagensSidebarRef.current = getConversasApi.formatado;
		} else {
			msgs([getConversasApi]);
		}
		setLoadingGetMensagensSidebar(false);
	}

	/*
	** Socket - Enviar mensagem
	** ================================================== */
	const enviarMensagemChat = async (dadosObj = {}) => {
		var texto = '';

		if(dadosObj.mensagem) {
			texto = dadosObj.mensagem;
		} else {
			texto = mensagemChat;
		}

		if(!texto || texto === '' || texto.trim() === '') {
			if(dadosObj.type !== 'proposta' && dadosObj.type !== 'imagem-url' && dadosObj.type !== 'variavel') {
				return;
			}
		}

		const dadosEnviar = {
			data: {
				id_freelancer: queryIdFreelancerRef.current,
				id_briefing: queryIdBriefingRef.current,
				// active: 0,
				text: texto,
				readed: 0,
				id_user: store.usuario.id,
				type: dadosObj.type ? dadosObj.type : 'default',
				...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
			},
			context: 'BriefingMessages',
			function: 'postMessage'
		};

		socket.emit('message', dadosEnviar);
		setMensagemChat('');
	}

	/*
	** Socket - envia evento de digitando
	** ================================================== */
	const digitandoChat = () => {
		setIsDigitando(hash());
	}

	/*
	** Sidebar - Clicar no item, traz as conversas
	** ================================================== */
	const onClickMensagemSidebar = async dados => {
		if(queryIdFreelancerRef.current !== dados._.id_freelancer) {
			/*
			** Desconecta da sala
			** ================================================== */
			socket.emit('message', {
				data: {
					id_briefing: queryIdBriefingRef.current,
					id_freelancer: queryIdFreelancerRef.current,
				},
				context: 'BriefingMessages',
				function: 'leaveRoom'
			});

			/*
			** Navega para a URL correta e carrega tudo novamente
			** ================================================== */
			// navigate(`/briefing/negociacao?id_briefing=${dados._.id_briefing}&id_freelancer=${dados._.id_freelancer}`);
			// const getsQ = qs.stringify({
			// 	id_briefing: dados._.id_briefing,
			// 	id_freelancer: dados._.id_freelancer,
			// });

			// navigate({
			// 	search: getsQ
			// });

			navigate(`/briefing/negociacao?id_briefing=${dados._.id_briefing}&id_freelancer=${dados._.id_freelancer}`);

		} else {
			inicializarDados();
		}
	}

	/*
	** Sidebar - Clicar no item, traz as conversas
	** ================================================== */
	const onClickFiltroSidebar = async dados => {
		if(loadingGetMensagensSidebar) {
			msg.warning('Por favor, aguarde...');
			return;
		}
		setFiltroSidebar(dados.value);
		setLoadingGetMensagensSidebar(true);

		const getConversasApi = await getConversasSidebarApi({
			status: dados.status,
			who: dados.who,
		});

		if(getConversasApi.status === 200) {
			setMensagensSidebar(getConversasApi.formatado);
			setMensagensSidebarUsuariosOnline(getConversasApi.formatado);
			mensagensSidebarRef.current = getConversasApi.formatado;
		} else {
			msgs([getConversasApi]);
		}


		/*
		** Define a exibição na sidebar com o usuário ativo (caso tenha)
		** ================================================== */
		const queryIdFreelancer = Number(stateGet.id_freelancer);

		var temFreelancerNoFiltroAtual = 0;
		const mensagensSidebarAtivo = mensagensSidebarRef.current.map(val => {
			if(val._id === queryIdFreelancer) {
				temFreelancerNoFiltroAtual++;
				return {
					...val,
					ativo: true,
					propria: val._id === store.usuario.id ? true : false,
				}
			}
			return {
				...val,
				ativo: false,
				propria: val._id === store.usuario.id ? true : false,
			};
		});
		if(!temFreelancerNoFiltroAtual) {
			setShowTelaInicialChat(true);
		}
		setMensagensSidebar(mensagensSidebarAtivo);
		setMensagensSidebarUsuariosOnline(mensagensSidebarAtivo);
		mensagensSidebarRef.current = mensagensSidebarAtivo;

		/*
		** Definir com quem está conversando
		** ================================================== */
		const dadosUsuarioSidebarAtivo = mensagensSidebarAtivo.filter(item => item._.id_freelancer === queryIdFreelancer);

		const dadosUsuarioSidebarAtivoFormatados = dadosUsuarioSidebarAtivo.map(val => {
			return {
				...chatFormatarDadosUsuario(val),
				_id: chatGetId(val),
				nome: val.name,
				foto: val.photo,
			}
		});

		setConversandoComChat(dadosUsuarioSidebarAtivoFormatados);
		setLoadingGetMensagensSidebar(false);
	}

	/*
	** Para evitar o travamento no loading
	** ================================================== */
	const onClickBtnRespostaProposta = dados => {
		if(dados.params.contracted || dados.params.refused) {
			msg.warning('Essa proposta já foi respondida anteriormente.');
			return;
		}

		// Evita o travamento do popup
		setLoadingGetMensagemProposta(true);
		setShowPortalMensagemBotaoProposta(true);

		setTimeout(() => {
			onClickBtnRespostaPropostaAcao(dados);
		}, 500);
	}

	/*
	** Mensagem - clicar no botão de ação
	** ================================================== */
	const onClickBtnRespostaPropostaAcao = async dados => {
		if(dados.params.contracted || dados.params.refused) {
			msg.warning('Essa proposta já foi respondida anteriormente.');
			return;
		}

		// Default, caso não consiga carregar o template
		var dadosResposta = {
			titulo: dados.resposta ? 'Aprovada' : 'Declinada',
			mensagem: '(não foi possível carregar o template, remova esse texto e insira sua mensagem manualmente)',
			tipo: dados.resposta ? 'verde' : 'vermelho',
		};

		setLoadingGetMensagemProposta(true);
		const retGetTemplateSim = await apiTemplates.getOne.get(44); // 44 -> Sua proposta foi aceita
		const retGetTemplateNao = await apiTemplates.getOne.get(51); // 51 -> Sua proposta foi declinada
		setLoadingGetMensagemProposta(false);

		var templateSim = dadosResposta;
		var templateNao = dadosResposta;

		if(retGetTemplateSim.status === 200) {
			templateSim = {
				titulo: retGetTemplateSim.data.title,
				mensagem: retGetTemplateSim.data.text,
				tipo: retGetTemplateSim.data.metadata.type
			};
		}

		if(retGetTemplateNao.status === 200) {
			templateNao = {
				titulo: retGetTemplateNao.data.title,
				mensagem: retGetTemplateNao.data.text,
				tipo: retGetTemplateNao.data.metadata.type
			};
		}

		setStateTemplateSim(templateSim);
		setStateTemplateNao(templateNao);

		if(dados.resposta) {
			dadosResposta = templateSim;
		} else {
			dadosResposta = templateNao;
		}

		const dadosProposta = {
			...dados,
			...dadosResposta,
			idProposta: dados.idProposta,
		};

		setStateProposta(dadosProposta);
		statePropostaRef.current = dadosProposta;

		return;
	}

	/*
	** Enviar orçamento no overlay
	** ================================================== */
	const onClickBtnEnviarRespostaProposta = e => {
		if(camposInvalidos(stateProposta, {}, [
			'titulo', 'mensagem', 'idProposta'
		])) {
			return;
		}

		setShowPortalMensagemBotaoProposta(false);

		if(socket.emit) {
			socket.emit('message', {
				data: {
					id_freelancer: queryIdFreelancerRef.current,
					id_briefing: queryIdBriefingRef.current,
					id_user: store.usuario.id,
					id_propose: stateProposta.idProposta,
				},
				context: 'BriefingMessages',
				function: stateProposta.resposta === true ? 'acceptPropose' : 'refusePropose',
			});
		}

	}

	/*
	** Após enviar a proposta, envia a mensagem no chat
	** ================================================== */
	const confirmacaoDeRespostaMensagemChat = (dados) => {

		// Apenas se for o próprio que respondeu
		if(dados.user.id_user === store.usuario.id) {
			const mensagensComBotaoOculto = mensagensChatRef.current.map(val => {
				if(statePropostaRef.current.params && statePropostaRef.current.params.id && val.id === statePropostaRef.current.params.id) {
					if(statePropostaRef.current.resposta) {
						val.contracted = true;
					} else {
						val.refused = true;
					}
				}
				return val;
			});

			setMensagensChat(mensagensComBotaoOculto);
			mensagensChatRef.current = mensagensComBotaoOculto;

			/*
			** Envia mensagem de confirmação da resposta
			** ================================================== */
			const dadosEnviar = {
				mensagem: statePropostaRef.current.mensagem,
				metadata: {
					titulo: statePropostaRef.current.titulo,
					idProposta: statePropostaRef.current.idProposta,
				},
				type: statePropostaRef.current.resposta ? 'verde' : 'vermelho',
			};

			enviarMensagemChat(dadosEnviar);


			// const dadosEnviar = {
			// 	data: {
			// 		id_freelancer: queryIdFreelancerRef.current,
			// 		id_briefing: queryIdBriefingRef.current,
			// 		// active: 0,
			// 		text: texto,
			// 		readed: 0,
			// 		id_user: store.usuario.id,
			// 		type: dadosObj.type ? dadosObj.type : 'default',
			// 		...(dadosObj.metadata ? {metadata: dadosObj.metadata} : {}),
			// 	},
			// 	context: 'BriefingMessages',
			// 	function: 'postMessage'
			// };


			// socket.emit('message', dadosEnviar);

			/*
			** Enviar mensagem de aviso dos cards
			** Obs: apenas caso seja aceita
			** ================================================== */

			if(statePropostaRef.current.resposta) {
				setTimeout(() => {
					const dadosEnviar = {
						metadata: {
							modelo: 'avisoBriefingAprovado',
							idProposta: statePropostaRef.current.idProposta,
							idUser: store.usuario.id,
							idFreelancer: stateGetRef.current.id_freelancer,
							idBriefing: stateGetRef.current.id_briefing,
						},
						type: 'variavel',
					};


					// socket.emit('message', dadosEnviar);
					enviarMensagemChat(dadosEnviar)
				}, 1000);
			}
		}
	}

	const onClickInfo = () => {
		setShowPortalTermosContratacaoSegura(true);
	}

	return (
		<>
			<div className="tela-negprocsel">
				<div className="titulo-box titulo-box-res">
					<div className="pg-negprocsel-titulo-box">
						<Voltar
							desc={false}
							link={locationBackup.state ? `/briefing?${qs.stringify(locationBackup.state)}` : '/briefing'}
							className="pg-negprocsel-btn-voltar"
						/>
						{_stateBriefing.status !== 200 ? (
							<h3 className="titulo text-one-line">Negociar proposta</h3>
						) : (
							<div className="pg-negprocsel-titulo-inner">
								<h3 className="titulo">{stateBriefing.title}</h3>
								<div className="pg-negprocsel-titulo-status td-desc">
									<Icone imagem={iconeStatusBriefing(stateBriefing.id_status).icone} classNameWrap="none-980" tamanho="16px" cor={iconeStatusBriefing(stateBriefing.id_status).cor} />
									<span className="margin-l-5 none-980">{stateBriefing.status_name}</span>
									<LinhaItem
										titulo="Criado em"
										desc={dataApiParaBr(stateBriefing.created_at)}
										className="margin-l-15 margin-l-0-980"
										alt
									/>
								</div>
							</div>
						)}
					</div>

					{_stateBriefing.status === 200 ? (
						<div className="cpnt-btnadd-wrap pg-negprocsel-titulo-sep-box">
							<div className="pg-negprocsel-titulo-sep">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeInfo}
											texto="Detalhes"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
											onClick={e => setShowPortalDetalhesBriefing(true)}
										/>
									}
									info
								>
									Detalhes da proposta
								</Tip>
							</div>
							<div className="pg-negprocsel-titulo-sep none-980">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeHistorico}
											texto="Histórico"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
											onClick={e => setShowPortalHistoricoBriefing(true)}
										/>
									}
									info
								>
									Histórico de edições
								</Tip>
							</div>
							<div className="pg-negprocsel-titulo-sep none-980">
								<Tip
									tip={
										<TextoIconeLink
											icone={imgIconeEditar}
											texto="Complementar"
											className="a"
											iconeTamanho="18px"
											iconeCor="#0277BD"
											onClick={e => setShowPortalConfirmacaoComplementarProcessoSeletivo(true)}
										/>
									}
									info
								>
									Complementar proposta
								</Tip>
							</div>
						</div>
					) : null}
				</div>

				<div className="tela-negprocsel-container">
					<ChatContainer
						mensagensSidebar={mensagensSidebarUsuariosOnline}
						loadingGetMensagensSidebar={loadingGetMensagensSidebar}
						onClickMensagemSidebar={onClickMensagemSidebar}
						filtroSidebar={filtroSidebar}
						onClickFiltroSidebar={onClickFiltroSidebar}
						loadingGetMensagens={loadingGetMensagensChat}
						mensagens={mensagensChat}
						setMensagens={setMensagensChat}
						mensagem={mensagemChat}
						setMensagem={setMensagemChat}
						enviarMensagem={enviarMensagemChat}
						conversandoCom={conversandoComChat}
						setDigitando={digitandoChat}
						isDigitando={isDigitandoChat}
						setIsDigitando={setIsDigitandoChat}
						isDigitandoInfo={isDigitandoInfoChat}
						isDigitandoSidebar={isDigitandoSidebarChat}
						setIsDigitandoSidebar={setIsDigitandoSidebarChat}
						isDigitandoSidebarInfo={isDigitandoSidebarInfoChat}
						layoutExibicao="usuario"
						showTelaInicial={showTelaInicialChat}
						onClickBtnRespostaProposta={onClickBtnRespostaProposta}
						onClickInfo={onClickInfo}
					/>
				</div>
			</div>

			<PortalProcessoSeletivoHistorico
				id={stateGet.id_briefing}
				show={showPortalHistoricoBriefing}
				onClose={() => setShowPortalHistoricoBriefing(false)}
			/>

			<PortalPerfilUsuario
				id={stateBriefing.id_user}
				show={showPortalResponsavel}
				onClose={() => setShowPortalResponsavel(false)}
			/>

			<PortalPropostaDeJobDetalhes
				id={stateGet.id_briefing}
				show={showPortalDetalhesBriefing}
				onClose={() => setShowPortalDetalhesBriefing(false)}
			/>

			<PortalTermosContratacaoSegura
				show={showPortalTermosContratacaoSegura}
				onClose={() => setShowPortalTermosContratacaoSegura(false)}
			/>

			{/* Portal Mensagem customizada */}
			<Portal
				titulo="Resposta da proposta"
				show={showPortalMensagemBotaoProposta}
				onClose={() => {
					setShowPortalMensagemBotaoProposta(false);
				}}
				className="w-1000"
			>
				<div className="box-txt">
					<div className="fs-14 color-1 margin-t-5">Caso necessário, você pode escrever uma mensagem informando o motivo de ter aceitado ou de ter recusado a proposta do freelancer.</div>
					<div className="relative">
						{loadingGetMensagemProposta ? <Loading absolute bgAlt2 /> : null}
						<div className="cpnt-chatmsgcustom-dados-preview margin-t-15">
							<div className="cpnt-chatmsgcustom-dados-mensagem-box">
								<div className="cpnt-chatmsgcustom-dados-mensagem">
									<div className="wrap-5">
										<div className="input-box">
											<Input2
												label="Título da mensagem"
												_={{
													value: stateProposta.titulo,
													onChange: e => {
														const dadosProposta = {
															...stateProposta,
															titulo: e.target.value
														};
														setStateProposta(dadosProposta);
														statePropostaRef.current = dadosProposta;
													}
												}}
											/>
										</div>
										<div className="input-box">
											<Input2
												label="Mensagem"
												_={{
													type: 'textarea',
													value: stateProposta.mensagem,
													onChange: e => {
														const dadosProposta = {
															...stateProposta,
															mensagem: e.target.value
														};
														setStateProposta(dadosProposta);
														statePropostaRef.current = dadosProposta;
													}
												}}
											/>
										</div>
										<div className="input-box">
											<div className="strong">Sua resposta:</div>
											<div className="flex margin-t-5">
												<Radio2
													label="Aceitar"
													_={{
														name: 'opcao-proposta',
														checked: stateProposta.resposta === true ? true : false,
														onChange: e => {
															const dadosProposta = {
																...stateProposta,
																...stateTemplateSim,
																resposta: e.target.checked
															};
															setStateProposta(dadosProposta);
															statePropostaRef.current = dadosProposta;
														}
													}}
												/>
												<Radio2
													label="Recusar"
													_={{
														name: 'opcao-proposta',
														checked: stateProposta.resposta === false ? true : false,
														onChange: e => {
															const dadosProposta = {
																...stateProposta,
																...stateTemplateNao,
																resposta: e.target.checked ? false : true,
															};
															setStateProposta(dadosProposta);
															statePropostaRef.current = dadosProposta;
														}
													}}
													wrap="margin-l-15"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="cpnt-chatmsgcustom-preview-box">
								<h3 className="margin-0">Pré visualização</h3>
								<div className="cpnt-chatmsgcustom-preview">
									<ChatMensagem
										{...{
											data: moment().subtract(0, 'minutes').format('HH:mm'),
											mensagem: stateProposta.mensagem,
											metadata: {
												titulo: stateProposta.titulo,
											},
											name: store.usuario.name,
											photo: caminhoFoto(store.usuario.photo).url,
											type: stateProposta.resposta === true ? 'verde' : 'vermelho',
											propria: false,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{!loadingGetMensagemProposta ? (
					<div className="form-bottom">
						<Button2
							_={{
								value: 'Cancelar',
								type: 'button',
								onClick: e => {
									setShowPortalMensagemBotaoProposta(false);
								},
								className: 'btn btn-1 margin-l-10',
							}}
						/>
						<Button2
							_={{
								value: stateProposta.resposta === true ? 'Aceitar proposta' : 'Recusar proposta',
								type: 'button',
								onClick: onClickBtnEnviarRespostaProposta,
								className: `btn margin-l-10 ${stateProposta.resposta === true ? 'btn-green' : 'btn-red'}`,
							}}
						/>
					</div>
				) : null}
			</Portal>

			{/* Confirmação de envio - Cliente*/}
			<PortalConfirmacao
				titulo="Complementar proposta de job"
				btnConfirmarTexto="Continuar"
				desc={
					<>
						<div>Você será redirecionado para outra outra página.</div>
						<div className="margin-t-15">Deseja continuar?</div>
					</>
				}
				show={showPortalConfirmacaoComplementarProcessoSeletivo}
				onClose={() => setShowPortalConfirmacaoComplementarProcessoSeletivo(false)}
				onClickCancelar={() => setShowPortalConfirmacaoComplementarProcessoSeletivo(false)}
				onClickConfirmar={() => {
					navigate(`/briefing/complementar?edit_id_briefing=${stateBriefing.id}`);
				}}
				loadingConfirmar={false}
			/>
		</>
	);
};

export default NegociarProcessoSeletivo;