import React from 'react';

// css
import './Icone.css';

const Icone = props => {
	const {
		imagem,
		tamanho = '',
		wrap = '',
		cor = '#000',
		className = '',
		classNameWrap,
		onClick,
		title,
		svg = true,
	} = props;

	if(svg) {
		return (
			<div className={`cpnt-icone-wrap ${wrap} ${classNameWrap}`} {...(onClick ? {onClick: onClick} : {})} {...(title ? {title: title} : {})}>
				<div
					className={`cpnt-icone bg-mask-alt relative ${className}`}
					style={{
						...(tamanho ? {width: tamanho, height: tamanho} : {}),
						...(cor ? {backgroundColor: cor} : {}),
						WebkitMaskImage: `url(${imagem})`,
						maskImage: `url(${imagem})`
					}}
				>
				</div>
			</div>
		);
	}

	return (
		<div className={`cpnt-icone-wrap ${wrap} ${classNameWrap}`} {...(onClick ? {onClick: onClick} : {})} {...(title ? {title: title} : {})}>
			<div
				className={`cpnt-icone bg-alt relative ${className}`}
				style={{
					...(tamanho ? {width: tamanho, height: tamanho} : {}),
					backgroundImage: `url(${imagem})`,
				}}
			>
			</div>
		</div>
	);
};

export default Icone;