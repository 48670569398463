import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast as msg } from 'react-toastify';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// CSS
import './ProcessosSeletivosLite.css';

// API
import apiBriefings from '@api/briefings/briefings';
import { apiGetClientes, apiGetEmpresas, apiGetSelectProjetosClientes, apiGetStatusBriefing, apiGetUsuarios, apiValidarAcesso } from '@apiGeral/geral';

// Imagens
import imgIconeStatus from '@svg/regular/play-pause.svg';
import imgIconeUsuarios from '@svg/regular/user.svg';
import imgIconeDetalhes from '@svg/regular/file-lines.svg';

// Components
import { BtnLimparFiltros } from '@cpnt/BtnLimparFiltros/BtnLimparFiltros';
import FiltrosWrap from '@cpnt/FiltrosWrap/FiltrosWrap';
import Icone from '@cpnt/Icone/Icone';
import { IconeChatWrap } from '@cpnt/IconeChatWrap/IconeChatWrap';
import { ImgDesc } from '@cpnt/ImgDesc/ImgDesc';
import ImgTituloDesc from '@cpnt/ImgTituloDesc/ImgTituloDesc';
import Input2 from '@cpnt/Input2/Input2';
import { PlaceholderRender } from '@cpnt/Placeholder/Placeholder';
import { PortalFreelancersSelecionadosBriefing } from "@cpnt/Portais/PortaisFreelancers/PortalFreelancersSelecionadosBriefing/PortalFreelancersSelecionadosBriefing";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import { PortalProcessoSeletivoHistorico } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalProcessoSeletivoHistorico/PortalProcessoSeletivoHistorico";
import { PortalPropostaDeJobDetalhes } from "@cpnt/Portais/PortaisProcessoSeletivo/PortalPropostaDeJobDetalhes/PortalPropostaDeJobDetalhes";
import { Select2 } from '@cpnt/Select2/Select2';
import { Select3Lista } from '@cpnt/Select3/Select3';
import StatusBriefing from '@cpnt/StatusBriefing/StatusBriefing';
import Tip from '@cpnt/Tip/Tip';
import TotalPaginacao from '@cpnt/TotalPaginacao/TotalPaginacao';

// Funções
import { caminhoFoto, dataApiParaBr, isNumeroDiferente, tamanhoTela } from '@func/utils';

// Config
import { FILTROS_RESULTADOS } from '@config/objetos';

// Hooks
import useWindowDimension from '@/_/hooks/useWindowDimensions';

// Rotas
import AvisoAcessoRestrito from "@cpnt/AvisoAcessoRestrito/AvisoAcessoRestrito";
import { clearGet, closePortal, getPortalDados, initFuncExec, isShowPortal, setGet, setGetNavigate, showPortal } from '@route/routeUtils';
import { msgs, msgStatus } from "@_func/request";

import { primeiroNome } from "@_func/regex";
import { Btn } from "@_cpnt/Btn/Btn/Btn";

const ProcessosSeletivosLite = () => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const screen = useWindowDimension();

	/*
	** Validar acesso
	** ================================================== */
	const [stateAcesso, setStateAcesso] = useState({});
	const [stateIsAcessoValido, setStateIsAcessoValido] = useState(true);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		const validarAcesso = await apiValidarAcesso({setStateAcesso, setStateIsAcessoValido});

		if(validarAcesso.sucesso) {
			initFuncExec([
				{
					func: getBriefings,
					param: getsAtualizados
				},
				{
					func: apiGetEmpresas,
					condicao: store.usuario.role === 1,
					param: {
						setLoadingGetEmpresas,
						setStateEmpresas,
					},
					init: true,
				},
				{
					func: apiGetClientes,
					param: {
						id: getsAtualizados.id_company,
						setLoadingGetClientes,
						setStateClientes,
					},
					condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
				},
				{
					func: apiGetUsuarios,
					param: {
						id: getsAtualizados.id_company,
						setLoadingGetUsuarios,
						setStateUsuarios,
					},
					condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
				},
				{
					func: apiGetSelectProjetosClientes,
					param: {
						idEmpresa: getsAtualizados.id_company,
						idCliente: getsAtualizados.id_customer,
						setLoadingGetProjetos,
						setStateProjetos,
					},
					condicao: !init ? true : (isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company) || isNumeroDiferente(stateGet.id_customer, getsAtualizados.id_customer))
				},
				{
					func: apiGetStatusBriefing,
					param: {
						setLoadingGetStatusBriefing,
						setStateStatusBriefing,
					},
					condicao: !init ? true : isNumeroDiferente(stateGet.id_company, getsAtualizados.id_company)
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		id_company: store.usuario.id_company,
		overlay: [],
		limit: 20,
		page: 1,
		id_user: null,
		name: '',
		product_type: null,
		scenery_type: null,
		id_status: null,
		id_customer: null,
		id_project: null,
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetClientes, setLoadingGetClientes] = useState(false);
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	const [loadingGetProjetos, setLoadingGetProjetos] = useState(false);
	const [loadingGetStatusBriefing, setLoadingGetStatusBriefing] = useState(false);
	const [loadingGetAlgolia, setLoadingGetAlgolia] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateBriefings, setStateBriefings] = useState([]);
	const [stateBriefingsMetadata, setStateBriefingsMetadata] = useState([]);
	const [_stateBriefings, _setStateBriefings] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateClientes, setStateClientes] = useState([]);
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [stateProjetos, setStateProjetos] = useState([]);
	const [stateStatusBriefing, setStateStatusBriefing] = useState([]);
	const [stateAlgolia, setStateAlgolia] = useState([]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetBriefings, setLoadingGetBriefings] = useState(false);
	const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);

	/*
	** API - Projetos Filtro
	** ================================================== */
	const getBriefings = async (dadosObj = {}) => {
		if(loadingGetBriefings) {
			return;
		}

		setLoadingGetBriefings(true);

		var dadosEnviar = clearGet(dadosObj);
		const retGetBriefings = await apiBriefings.all.get(dadosEnviar);

		_setStateBriefings(msgStatus(retGetBriefings));

		if(retGetBriefings.status === 200) {
			setStateBriefings(retGetBriefings.data);
			setStateBriefingsMetadata(retGetBriefings.metadata);
		} else {
			setStateBriefings([]);
			setStateBriefingsMetadata({});
		}

		setLoadingGetBriefings(false);
	}

	/*
	** API - Alterar status do briefing
	** ================================================== */
	const alterarStatusBriefing = async dadosObj => {

		if(loadingAlterarStatus) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingAlterarStatus(dadosObj.id);

		const dadosEnviar = {
			id: dadosObj.id,
			id_status: dadosObj.status,
		};

		const retUpdateBriefingStatus = await apiBriefings.update.put(dadosEnviar);

		if(retUpdateBriefingStatus.status === 200) {
			msg.success(`Status alterado para: ${dadosObj.nomeStatus}`);
			const briefings = stateBriefings.map((val) => {
				if(val.id === dadosObj.id) {
					return {
						...val,
						...dadosEnviar,
						status_name: dadosObj.nomeStatus,
					}

				}
				return val;
			})

			setStateBriefings(briefings);
		} else {
			msgs([retUpdateBriefingStatus]);
		}
		setLoadingAlterarStatus(false);
	}

	/*
	** Nomes dos status abreviados
	** ================================================== */
	const nomeStatusProcSelExibicao = status => {
		var _status = '(desconhecido)';
		switch (status) {
			case 'Em Andamento': _status = 'Andamento'; break;
			case 'Em rascunho': _status = 'Rascunho'; break;
			default: _status = status; break;
		}
		return _status;
	}

	/*
	** Variaveis de acordo com o tamanho da tela
	** ================================================== */
	var winPosTooltipDireito = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'right-center'}, screen);

	var winPosTooltipEsquerdo = tamanhoTela([
		{width: 980, dados: {placement: 'bottom-center'}},
	], {placement: 'left-center'}, screen);


	return (
		<>
			<div className="pg-briefings base-full">
				<div className="conteudo-box">
					<div className="titulo-box">
						<div className="flex flex-center-v">
							<h3 className="titulo">Briefing/Vaga</h3>
						</div>
						<Btn
							_={{
								value: 'Cadastrar Briefing/Vaga',
								onClick: () => {
									navigate('/briefing/cadastrar');
								}
							}}
							size="m"
						/>
					</div>

					<FiltrosWrap>
						<form
							onSubmit={e => e.preventDefault()}
							className="flex flex-wrap wrap-5"
						>
							<div className={`${store.usuario.role === 1 ? 'w-25p' : 'w-50p'} input-box`}>
								<Input2
									label="Pesquisar Briefing/Vaga"
									_={{
										value: stateGet.title,
										onChange: e => {
											setGetNavigate({
												gets: {title: e.target.value},
												navigate,
												stateGet,
												location,
											});
										}
									}}
								/>
							</div>
							<div className="input-box w-25p relative">
								<Select2
									label="Status"
									_={{
										value: stateGet.id_status,
										onChange: e => {
											setGetNavigate({
												gets: {id_status: e ? e.value : null},
												navigate,
												stateGet,
												location,
											});
										},
										options: stateStatusBriefing,
										isClearable: true,
									}}
									loading={loadingGetStatusBriefing}
								/>
							</div>
							<div className="input-box w-25p flex">
								<Select2
									label="Resultados por página"
									_={{
										value: stateGet.limit,
										onChange: e => {
											setGetNavigate({
												gets: {limit: e.value},
												navigate,
												stateGet,
												location,
											});
										},
										options: FILTROS_RESULTADOS,
									}}
								/>
								<BtnLimparFiltros
									_={{
										onClick: e => {
											setGetNavigate({
												gets: getsDefault,
												navigate,
												stateGet,
												location,
											});
										},
									}}
								/>
							</div>
						</form>
					</FiltrosWrap>

					{!stateIsAcessoValido && !loadingGetBriefings ? (
						<AvisoAcessoRestrito {...stateAcesso} />
					) : (
						<div id="tabela-container">
							<TotalPaginacao {...{stateGet, stateMetadata: stateBriefingsMetadata, loading: loadingGetBriefings, getsDefault}} />
							<div className="table-wrap">
								<table className="tabela">
									<thead className="tabela-titulo-fixo">
										<tr className="">
											<th className="col-id">
												<Tip tip="ID">ID único da proposta de job</Tip>
											</th>
											<th className="col-min-2">
												<Tip tip="Chat">Conversar com os profissionais selecionados</Tip>
											</th>
											<th className="col-min-3">
												<Tip tip="Resp.">Responsável por essa proposta de job</Tip>
											</th>
											<th className="">
												<Tip tip="Briefing">Título da proposta de job e projeto</Tip>
											</th>
											<th className="tbl-procsel-col-area-atuacao">
												<Tip tip="Área">Categoria</Tip>
											</th>
											<th className="col-data">
												<Tip tip="Public. em">Data de publicação da proposta de job</Tip>
											</th>
											<th className="col-min-2">
												<Tip tip="Info">
													Detalhes do briefing
												</Tip>
											</th>
											<th className="col-min-3">
												<Tip tip="Prof.">Profissionais selecionados</Tip>
											</th>
											<th className="col-min-5">
												<Tip
													tip={
														<Icone
															imagem={imgIconeStatus}
															cor="#FFF"
															className="margin-auto"
														/>
													}
												>
													Status atual do briefing
												</Tip>
											</th>
										</tr>
									</thead>
									<tbody>
										<PlaceholderRender
											loading={loadingGetBriefings}
											init={init}
											itens={stateBriefings}
											request={_stateBriefings}
											cpnt="PlaceholderBriefingsLite"
											qtdColunas={9}
										>
											{stateBriefings.map((val, index) => {
												return (
													<tr key={index}>

														<td data-titulo="ID" className="col-id">
															<div className="td-wrap">
																{val.id}
															</div>
														</td>

														<td data-titulo="Chat" className="">
															<div className="td-wrap scroll-visible align-center">
																<IconeChatWrap
																	qtdMensagens={val.count_messages}
																	link={`/briefing/negociacao?id_briefing=${val.id}`}
																	linkState={stateGet}
																/>
															</div>
														</td>

														<td data-titulo="Recrutador">
															<div className="td-wrap align-center">
																<ImgDesc
																	wrap="a td-info"
																	titulo={primeiroNome(val.user_name)}
																	img={caminhoFoto(val.user_photo).url}
																	_tipDefaults={winPosTooltipDireito}
																	onClick={() => showPortal({portal: 'usuario', dados: val.id_user, stateGet, navigate})}
																/>
															</div>
														</td>

														<td data-titulo="Briefing">
															<div className="td-wrap">
																<ImgTituloDesc
																	wrap="a td-info"
																	titulo={val.title || '(Briefing sem título)'}
																	desc={val.project_name}
																	classNameTitulo="td-titulo"
																	classNameDesc="td-desc"
																	responsivo={true}
																	onClick={() => navigate(`/briefing/negociacao?id_briefing=${val.id}`)}
																/>
															</div>
														</td>

														<td data-titulo="Área" className="align-center">
															<div className="td-wrap">{val.area_name || '-'}</div>
														</td>

														<td data-titulo="Publicado em" className="align-center">
															<div className="td-wrap">{dataApiParaBr(val.created_at, '-')}</div>
														</td>

														<td data-titulo="Detalhes">
															<div className="td-wrap align-center">
																{val.count_changes ? (
																	<div
																		className="a"
																		onClick={() => showPortal({portal: 'detalhes', dados: val.id, stateGet, navigate})}
																	>
																		<Icone
																			imagem={imgIconeDetalhes}
																			cor="#555"
																			className="margin-auto cursor-pointer"
																		/>
																	</div>
																) : (
																	<div>-</div>
																)}
															</div>
														</td>

														<td data-titulo="Profisisonais">
															<div className="td-wrap align-center">
																{val.count_changes ? (
																	<div
																		className="a"
																		onClick={() => showPortal({portal: 'profissionais', dados: val.id, stateGet, navigate})}
																	>
																		<Icone
																			imagem={imgIconeUsuarios}
																			cor="#555"
																			className="margin-auto cursor-pointer"
																		/>
																		<div className="td-desc-alt">{val.count_freelancers}</div>
																	</div>
																) : (
																	<div>-</div>
																)}
															</div>
														</td>

														<td data-titulo="Status" className="relative">
															<div className="td-wrap scroll-visible align-center">
																<Tip
																	tip={
																		<div className="a">
																			<StatusBriefing
																				status={val.id_status}
																				className="cursor-pointer"
																			/>
																			<div className="td-desc-alt">{nomeStatusProcSelExibicao(val.status_name)}</div>
																		</div>
																	}
																	click={true}
																	show={loadingAlterarStatus === val.id ? true : false}
																	_defaults={winPosTooltipEsquerdo}
																>
																	<Select3Lista
																		titulo={<h3 className="margin-0">Status do briefing</h3>}
																		options={stateStatusBriefing}
																		optionsType="radio"
																		optionsChecked={{
																			value: val.id_status,
																		}}
																		onClick={e => {
																			alterarStatusBriefing({
																				id: val.id,
																				status: e.value,
																				nomeStatus: e.label,
																			})
																		}}
																		loading={loadingAlterarStatus === val.id ? true : false}
																		labelClass="flex-1"
																		posicao=""
																		classNameDefault=""
																	/>
																</Tip>
															</div>
														</td>
													</tr>
												)
											})}
										</PlaceholderRender>
									</tbody>
								</table>
							</div>
							<TotalPaginacao {...{stateGet, stateMetadata: stateBriefingsMetadata, loading: loadingGetBriefings, getsDefault}} />
						</div>
					)}
				</div>

				<PortalProcessoSeletivoHistorico
					id={getPortalDados({portal: 'historico', stateGet})}
					show={isShowPortal({portal: 'historico', stateGet})}
					onClose={() => closePortal({portal: 'historico', stateGet, navigate})}
				/>

				<PortalFreelancersSelecionadosBriefing
					id={getPortalDados({portal: 'profissionais', stateGet})}
					show={isShowPortal({portal: 'profissionais', stateGet})}
					onClose={() => closePortal({portal: 'profissionais', stateGet, navigate})}
				/>

				<PortalPerfilUsuario
					id={getPortalDados({portal: 'usuario', stateGet})}
					show={isShowPortal({portal: 'usuario', stateGet})}
					onClose={() => closePortal({portal: 'usuario', stateGet, navigate})}
				/>

				<PortalPropostaDeJobDetalhes
					show={isShowPortal({portal: 'detalhes', stateGet})}
					id={getPortalDados({portal: 'detalhes', stateGet})}
					onClose={() => closePortal({portal: 'detalhes', stateGet, navigate})}
				/>

			</div>
		</>
	);
}

export default ProcessosSeletivosLite;
