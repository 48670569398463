import { DEV } from "@_config/config";

export const LINKS_SECOES_SIDEBAR_FREELANCER = [
	{
		titulo: 'Dados pessoais',
		links: [
			{
				texto: 'Resumo do perfil',
				link: 'resumo',
			},
			// {
			// 	texto: 'Perfil público',
			// 	link: 'perfil-publico',
			// },
			{
				texto: 'Dados pessoais',
				link: 'dados',
			},
			{
				texto: 'Carta de apresentação',
				link: 'apresentacao',
			},
			{
				texto: 'Foto de perfil',
				link: 'foto',
			},
			{
				texto: 'Vídeo de apresentação',
				link: 'video',
			},
			{
				texto: 'Dados para os Jobs',
				link: 'jobs',
			},
			{
				texto: 'Endereço para contato',
				link: 'endereco',
			},
			{
				texto: 'Redes sociais',
				link: 'redes-sociais',
			},
		],
	},
	{
		titulo: 'Dados empresariais',
		links: [
			{
				texto: 'Dados da sua empresa',
				link: 'dados-empresa',
			},
			{
				texto: 'Dados bancários',
				link: 'dados-bancarios',
			},
		],
	},
	{
		titulo: 'Conhecimentos e experiências',
		links: [
			{
				texto: 'Área e habilidades',
				link: 'habilidades',
			},
			{
				texto: 'Idiomas',
				link: 'idiomas',
			},
			{
				texto: 'Segmentos de interesse',
				link: 'segmentos',
			},
			{
				texto: 'Competências',
				link: 'competencias',
			},
			{
				texto: 'Experiências',
				link: 'experiencias',
			},
			{
				texto: 'Formações',
				link: 'formacoes',
			},
			{
				texto: 'Portfólios',
				link: 'portfolios',
			},
			{
				texto: 'Certificados',
				link: 'certificados',
			},
			{
				texto: 'Prêmios',
				link: 'premios',
			},
		],
	},
	{
		titulo: 'Outras configurações',
		links: [
			{
				texto: 'Dados de acesso',
				link: 'acessos',
			},
			{
				texto: 'Gerenciar notificações',
				link: 'aceites',
			},
			{
				texto: 'Central de Ajuda',
				link: 'ajuda',
			},
			{
				texto: 'Termos de uso',
				link: 'termos',
			},
			{
				texto: 'Política de privacidade',
				link: 'politica',
			},
			// (DEV ? (
			// {
			// 	texto: 'Excluir perfil',
			// 	link: 'excluir-perfil',
			// }) : null),
		],
	},
];