import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { toast as msgToast } from 'react-toastify';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'
import qs from 'qs';
import { Link } from 'react-router-dom';

// Imagens
import imgUserPicDefault from '@img/userpic.png';

// Config
import { EMAIL_CONTATO, URL_API_ARQUIVOS, URL_API_IMAGENS } from '@_config/config';
import { NOMES_CAMPOS, STATUS_DEFAULT, TIPOS_TEMPLATE } from '@config/objetos';
import { clearGet } from "@route/routeUtils";
import { isNumeric } from "@_func/regex";
import { dataSemTZ } from "@_func/date";

export const msg = msgToast;

export const camposInvalidos = (obj, nomesNovosObj = {}, arrCamposValidar = '*', mensagem = 'Preencha todos os campos obrigatórios:', exibirCampos = true) => {
	if(mensagem === null) {
		mensagem = 'Preencha todos os campos obrigatórios:';
	}
	var retorno = {};
	var msgsAgrupadas = [];

	if(typeof(obj) === 'object') {
		if(arrCamposValidar === '*') {
			Object.keys(obj).map((val, key) => {
				var campo = obj[val];
				if(!campo || campo === '') {
					retorno[val] = true;
					var label = '';
					if(nomesNovosObj[val]) {
						label = nomesNovosObj[val];
					} else {
						label = nomeCampo(val)
					}
					msgsAgrupadas.push(<div key={key} className={'msgs-lista-item msgs-lista-item-error'}>{label}</div>);
				}
			});
		} else {

			arrCamposValidar.map((val, key) => {
				var campo = obj[val];
				if(!campo || campo === '') {
					retorno[val] = true;
					var label = '';
					if(nomesNovosObj[val]) {
						label = nomesNovosObj[val];
					} else {
						label = nomeCampo(val)
					}
					msgsAgrupadas.push(<div key={key} className={'msgs-lista-item msgs-lista-item-error'}>{label}</div>);
				}
			});
		}
	}

	if(Object.keys(retorno).length > 0) {
		if(mensagem !== false) {
			if(exibirCampos) {
				msgToast.error(<div><div>{mensagem}</div><div className="msgs-lista-box msgs-lista">{msgsAgrupadas}</div></div>);
			} else {
				msgToast.error(mensagem);
			}
		}
		return retorno;
	}
	return false;
}

export const nomeCampo = campo => {
	var campos = NOMES_CAMPOS;

	if(campos[campo]) {
		return campos[campo];
	}

	return campo;
}

export const apenasNumeros = (string, converter = false) => {
	if(!string) {
		return string;
	}
	var numeros = string.toString();
	numeros = numeros.replace(/\D+/g, '');
	if(converter) {
		numeros = Number(numeros);
	}
	return numeros;
}

export const formatarCEP = (cep) => {
	if(!cep) {
		return cep;
	}
	if(typeof(cep) !== 'string') {
		cep = cep.toString();
	}

	var numeros = cep.replace(/\D+/g, '');

	if(numeros.length !== 8) {
		return cep;
	}

	const cepFormatado = Array.from(numeros);
	cepFormatado.splice(5, 0, '-');
	return cepFormatado.join('');
}

export const apenasNumerosCpfCnpj = string => {
	var retorno = '';
	if(string && typeof(string) === 'string') {
		retorno = string.replace(/\D/g,'').toString();
		if(retorno > 14) {
			retorno = retorno.slice(0, 14);
		}
	}
	return retorno;
}

export const validarEmail = (email, mensagem = 'O e-mail inserido é inválido.') => {
	if(!email || email === '') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	var valido = re.test(email);
	if(!valido) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
	}
	return valido;
};

export const validarCpf = (cpf, mensagem = 'O CPF inserido é inválido.') => {
	if(!cpf || cpf === '') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	cpf = cpf.trim();
	cpf = cpf.replace(/\./g, '');
	cpf = cpf.replace('-', '');
	cpf = cpf.replace('/', '');
	cpf = cpf.split('');

	let v1 = 0;
	let v2 = 0;
	let aux = false;

	for (let i = 1; cpf.length > i; i++) {
		if (cpf[i - 1] != cpf[i]) {
			aux = true;
		}
	}

	if (aux == false) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
		v1 += cpf[i] * p;
	}

	v1 = ((v1 * 10) % 11);

	if (v1 == 10) {
		v1 = 0;
	}

	if (v1 != cpf[9]) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
		v2 += cpf[i] * p;
	}

	v2 = ((v2 * 10) % 11);

	if (v2 == 10) {
		v2 = 0;
	}

	if (v2 != cpf[10]) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	} else {
		return true;
	}
}

export const validarCnpj = (cnpj, mensagem = 'O CNPJ inserido é inválido.') => {
	if(!cnpj || cnpj === '') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	cnpj = cnpj.trim();
	cnpj = cnpj.replace(/\./g, '');
	cnpj = cnpj.replace('-', '');
	cnpj = cnpj.replace('/', '');
	cnpj = cnpj.split('');

	let v1 = 0;
	let v2 = 0;
	let aux = false;

	for (let i = 1; cnpj.length > i; i++) {
		if (cnpj[i - 1] != cnpj[i]) {
			aux = true;
		}
	}

	if (aux == false) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	for (let i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v1 += cnpj[i] * p1;
		} else {
			v1 += cnpj[i] * p2;
		}
	}

	v1 = (v1 % 11);

	if (v1 < 2) {
		v1 = 0;
	} else {
		v1 = (11 - v1);
	}

	if (v1 != cnpj[12]) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	for (let i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v2 += cnpj[i] * p1;
		} else {
			v2 += cnpj[i] * p2;
		}
	}

	v2 = (v2 % 11);

	if (v2 < 2) {
		v2 = 0;
	} else {
		v2 = (11 - v2);
	}

	if (v2 != cnpj[13]) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	} else {
		return true;
	}
}

export const validarTelefone = (telefone, mensagem = 'O telefone inserido é inválido.') => {
	if(!telefone) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	if(typeof(telefone) !== 'string') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	if(telefone.length === 14) {
		return true;
	} else if(telefone.length === 15) {
		return true;
	}

	var tel = apenasNumeros(telefone);

	// Internacional com 55 com 9 e sem 9
	if(tel.length === 13 || tel.length === 12) {
		return true;
	}

	if(mensagem) {
		msgToast.error(mensagem);
	}

	return false;
}

export const validarTelefonePais = (telefone, mensagem = 'O telefone inserido é inválido.') => {
	if(!telefone) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	if(typeof(telefone) !== 'string') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	var tel = apenasNumeros(telefone);

	// Internacional com 55 com 9 e sem 9
	if(tel.length === 13 || tel.length === 12) {
		return true;
	}

	if(mensagem) {
		msgToast.error(mensagem);
	}

	return false;
}

export const shuffleArray = (arr) => {
	let i = arr.length - 1;
	for (; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = arr[i];
		arr[i] = arr[j];
		arr[j] = temp;
	}
	return arr;
}

export const calcularAnos = (e) => {
	if(e && e !== '' && e.length > 0 && validarDataAPI(e)) {
		return moment().diff(e, 'years');
	}
	return false;
}

export const dataBrParaEng = e => {
	if(validarDataBr(e)) {
		if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && e.length === 10 && e.indexOf('/') > -1) {
			var data = e.split('/');
			if(data.length === 3) {
				if(data[0].length === 2 && data[1].length === 2 && data[2].length === 4) {
					return `${data[2]}/${data[1]}/${data[0]}`;
				}
			}
		}
	}
	return null;
}

export const dataBrParaEngDb = e => {
	if(validarDataBr(e)) {
		var data = e.split('/');
		if(data.length === 3) {
			if(data[0].length === 2 && data[1].length === 2 && data[2].length === 4) {
				return `${data[2]}-${data[1]}-${data[0]}`;
			}
		}
	}
	return null;
}

export const dataEngParaBr = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && e.length === 10 && e.indexOf('/') > -1) {
		var data = e.split('/');
		if(data.length === 3) {
			if(data[0].length === 4 && data[1].length === 2 && data[2].length === 2) {
				return `${data[2]}/${data[1]}/${data[0]}`;
			}
		}
	}
	return null;
}

export const dataEngParaBrAlt = (e, dia = true) => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && e.length === 10 && e.indexOf('-') > -1) {
		var data = e.split('-');
		if(data.length === 3) {
			if(data[0].length === 4 && data[1].length === 2 && data[2].length === 2) {
				if(dia) {
					return `${data[2]}/${data[1]}/${data[0]}`;
				}
				return `${data[1]}/${data[0]}`;
			}
		}
	}
	return null;
}

export const validarDataAPI = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && (e.length === 10 || e.length === 19 || e.length === 24) && e !== '0000-00-00 00:00:00') {
		return true;
	}
	return false;
}

export const validarDataSegundosAPI = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && e.length === 24 && e !== '0000-00-00 00:00:00') {
		return true;
	}
	return false;
}

export const validarDataEng = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && e.length === 10 && e.indexOf('-') > -1) {
		var data = e.split('-');
		if(data.length === 3) {
			if(data[0].length === 4 && data[1].length === 2 && data[2].length === 2) {
				return true;
			}
		}
	}
	return false;
}

export const validarFormatoDataBr = data => {
	if(!data) {
		return false
	}
	var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
	if(data.match(reg)) {
		return true;
	}
	return false;
}

export const validarDataEngIgnorarHora = e => {
	if(e && e !== '' && e.length >= 10 && e.indexOf('-') > -1) {
		var data = e.split(' ');
		if(data.length >= 1) {
			var dataApenas = data[0].split('-');
			if(dataApenas.length === 3 && dataApenas[0].length === 4 && dataApenas[1].length === 2 && dataApenas[2].length === 2) {
				return true;
			}
		}
	}
	return false;
}

export const validarDataBrIgnorarHora = e => {
	if(e && e !== '' && e.length >= 10 && e.indexOf('/') > -1) {
		var data = e.split(' ');
		if(data.length >= 1) {
			var dataApenas = data[0].split('/');
			if(dataApenas.length === 3 && dataApenas[0].length === 2 && dataApenas[1].length === 2 && dataApenas[2].length === 4) {
				return true;
			}
		}
	}
	return false;
}

export const dataEngParaObj = data => {
	if(validarDataEngIgnorarHora(data)) {
		var dataArr = data.split(' ');
		if(dataArr.length) {
			var dataApenas = dataArr[0].split('-');
			return {
				year: Number(dataApenas[0]),
				month: Number(dataApenas[1]),
				day: Number(dataApenas[2]),
			}
		}
	}
	return false;
}

export const dataBrParaObj = data => {
	if(validarDataBrIgnorarHora(data)) {
		var dataArr = data.split(' ');
		if(dataArr.length) {
			var dataApenas = dataArr[0].split('/');
			return {
				year: Number(dataApenas[2]),
				month: Number(dataApenas[1]),
				day: Number(dataApenas[0]),
			}
		}
	}
	return false;
}

export const dataApiCrop = data => {
	if(data && typeof(data) === 'string' && data.length === 24) { // Ex: 2022-07-29T03:00:00.000Z
		return data.split('T')[0];
	}
	return data;
}

export const validarDataBr = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && e.length === 10 && e.indexOf('/') > -1) {
		var data = e.split('/');
		if(data.length === 3) {
			if(data[0].length === 2 && data[1].length === 2 && data[2].length === 4) {
				return true;
			}
		}
	}
	return false;
}

export const isDataEngMaiorQueHoje = (data) => {
	if(moment(data).isSameOrAfter(moment().utc().toDate())) {
		return true;
	}
	return false;
}

export const dataApiParaEng = (date, objetoData = false) => {
	if(date !== null && date !== undefined && date !== '' && date.length > 0 && date.indexOf('-') > -1) {
		var data = moment(date).utc().format('YYYY/MM/DD')
		if(objetoData) {
			data = new Date(data);
		}
		return data;
	}

	return null;
}

export const dataApiParaBr = (date, textoInvalido) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('DD/MM/YYYY');
	}

	if(textoInvalido) {
		return textoInvalido;
	}

	return null;
}

export const dataApiParaBrHora = (date, textoInvalido) => {
	if(validarDataAPI(date)) {
		var data = date.split('T');
		if(data.length === 2) {
			if(data[1].indexOf('.') > -1) {
				var hora = data[1].split('.');
				return hora[0];
			}
		}
	}

	return textoInvalido;
}

export const dataApiParaBrExtenso = (date) => {
	if(date !== null && date !== undefined && date !== '' && date.length > 0 && date.indexOf('-') > 0) {
		return moment(date).utc().locale('pt-br').format('DD [de] MMMM [de] YYYY');
	}

	return null;
}

export const dataApiParaBrAno = (date) => {
	if(date !== null && date !== undefined && date !== '' && date.length > 0 && date.indexOf('-') > 0) {
		return moment(date).utc().format('YYYY');
	}

	return null;
}

export const dataBrParaDate = (date) => {
	if(validarDataBr(date)) {
		var dataConvertidaBrParaEng = dataBrParaEng(date);
		return moment(dataConvertidaBrParaEng).utc().toDate();
	}

	return null;
}

export const dataObjParaApi = (date) => {
	if(date && typeof(date) === 'object') {
		return moment(date).utc().format('YYYY/MM/DD');
	}

	return null;
}

export const dataObjParaBr = (date) => {
	if(date && typeof(date) === 'object') {
		return moment(date).utc().format('DD/MM/YYYY');
	}

	return null;
}

export const dataPorExtenso = (date, exibirHaQuantoTempo = true, prefixoTempo = 'há ', prefixoHoje = 'Hoje às ', prefixoGeral = '') => {

	if(!validarDataAPI(date)) {
		return '(data não informada)';
	}

	var horaMinSeg = dataApiParaHoraMinSeg(date);
	var horaExtenso = '';

	if(moment(date).isSame(moment(), 'day')) {
		horaExtenso = `${prefixoHoje}${horaMinSeg}`;
	} else {
		horaExtenso = `${prefixoGeral}${dataApiParaBr(date)} às ${horaMinSeg}`;
	}

	if(exibirHaQuantoTempo && horaExtenso !== '') {
		horaExtenso += ` (${prefixoTempo}${dataHaQuantoTempo(date)})`;
	}

	return horaExtenso;
}

export const dataHaQuantoTempo = (date, prefixo = '') => {
	if(date && date !== null && date !== undefined && date !== '' && date.length) {
		const dataFormatada = dataSemTZ(date);
		var tempo = `${prefixo}${moment(dataFormatada).locale('pt-br').fromNow(true)}`;
		if(tempo === 'poucos segundos') {
			return 'agora';
		}
		if(tempo === 'um minuto') {
			return '1 minuto';
		}
		return `${prefixo}${moment(dataFormatada).locale('pt-br').fromNow(true)}`;
	}
	return null;
};

export const dataApiParaHoraMinSeg = (date) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('H:mm:ss');
	}
	return null;
}

export const dataApiParaHoraMin = (date) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('H:mm');
	}
	return null;
}

export const dataAgora = (horas = false) => {
	if(horas) {
		return moment().locale('pt-br').format('YYYY-MM-DD H:mm:ss');
	}
	return moment().locale('pt-br').format('YYYY-MM-DD');
}

export const dataAgoraBr = (horas = false) => {
	if(horas) {
		return moment().locale('pt-br').format('DD/MM/YYYY H:mm:ss');
	}
	return moment().locale('pt-br').format('DD/MM/YYYY');
}

export const dataApiParaMesAnoExt = (data) => {
	return moment(data).locale('pt-br').format('MM [de] YYYY');
}

export const splitHoraDaDataApi = (data) => {
	if(validarDataAPI(data)) {
		var dataSplit = data.split('T');
		return dataSplit[1].replace('.000Z', '');
	}
	return data;
}

export const dateAnoAtual = () => {
	return moment().year();
}

export const splitDataDaDataHoraApi = (data) => {
	if(validarDataAPI(data)) {
		var dataSplit = data.split('T');
		return dataSplit[0];
	}
	return data;
}

export const conveterRealParaNumero = (val, inverter) => {
	if(!isNaN(val) && val === '') {
		return (inverter === true ? '0' : 0);
	}
	if(!val) {
		return 0;
	}
	if(inverter === true) {
		return val.toString().replace('.',',');
	}
	if(typeof(val) === 'number') {
		return val;
	}
	val = val.toString();
	val = val.replaceAll('.', '');
	val = val.replaceAll(',', '.');
	val = parseFloat(val);
	return val;
}

export const formatarDinheiro = (val, prefixo = '') => {
	var valor = val;
	if(typeof(val) !== 'number') {
		valor = conveterRealParaNumero(val);
	}
	if(isNaN(valor)) {
		return `${prefixo}0,00`;
	}
	if(valor && valor !== null && valor !== undefined && valor !== '') {
		return prefixo+parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
	}
	return `${prefixo}0,00`;
}

export const formatarTelefone = (numero) => {
	if(!numero) {
		return '';
	}
	var length = numero.length;
	var telefoneFormatado = numero;

	if(length === 14) {
		telefoneFormatado = numero.substring(0, 3) + ' (' + numero.substring(3, 5) + ') ' + numero.substring(5, 10) + '-' + numero.substring(10, 15);
	} else if (length === 10) {
		telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 6) + '-' + numero.substring(7, 10);
	} else if (length === 11) {
		telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 7) + '-' + numero.substring(7, 11);
	} else if (length === 8) {
		telefoneFormatado = numero.substring(0, 4) + '-' + numero.substring(4, 8);
	} else if (length === 9) {
		telefoneFormatado = numero.substring(0, 5) + '-' + numero.substring(5, 9);
	}
	return telefoneFormatado;
}

export const formatarCNPJ = (cnpj) => {
	if(cnpj && typeof(cnpj) === 'string') {
		var _cnpj = cnpj.replace(/\D/g, '');
		return _cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
	}
	return cnpj;
}

export const formatarCPF = (cpf) => {
	if(cpf && typeof(cpf) === 'string') {
		var _cpf = cpf.replace(/\D/g, '');
		return _cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
	}
	return cpf;
}

export const validar200 = dados => {
	var validado = true;
	dados.map(valueRetorno => {
		if(valueRetorno.status !== 200) {
			validado = false;
		}
		return valueRetorno;
	});
	return validado;
}

export const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
}

export const extensaoPorMimeType = mime => {
	var ext = false;
	if(typeof(mime) === 'object') {
		if(typeof(mime.type) === 'string') {
			var extArr = mime.type.split('/');
			if(extArr.length === 2) {
				ext = extArr[1];
			}
		}
	}
	return ext;
}

export const disponibilidadeFreelancer = (availability, zeroTitulo = 'Não disponível') => {
	if(availability === 1) return 'De 31h a 40h por semana';
	if(availability === 2) return 'De 11h a 30h por semana';
	if(availability === 3) return 'Cerca de 10h por semana';
	if(availability === 0) return zeroTitulo;
	return 'Não informado';
}

export const getSenioridadeBySlug = (senioridade, naoEncontrado = 'Não informado') => {
	if(senioridade === 'pleno') return 'Pleno';
	if(senioridade === 'senior') return 'Sênior';
	if(senioridade === 'junior') return 'Júnior';
	return naoEncontrado;
}

export const textoNivelAcesso = role => {
	const roles = {1: 'Administrador', 2: 'Profissional', 3: 'CROWD Premium', 9: 'Crowd Lite'};
	if(roles[role] !== undefined) {
		return roles[role];
	}
	return 'Nível desconhecido';
}

export const slugNivelAcesso = role => {
	const roles = {1: 'administrador', 2: 'freelancer', 3: 'hunter', 9: 'lite'};
	if(roles[role] !== undefined) {
		return roles[role];
	}
	return 'desconhecido';
}

export const senioridadeFreelancer = (senioridade, textoInvalido = '(texto indisponível)') => {
	if(senioridade === 1) return 'Sênior - mais que 5 anos de experiência';
	if(senioridade === 2) return 'Pleno - entre 3 e 5 anos de experiência';
	if(senioridade === 3) return 'Júnior - menos que 3 anos de experiência';
	return textoInvalido;
}

export const validarCep = (cep, mensagem = 'CEP Inválido') => {
	var cepNumeros = apenasNumeros(cep);

	if(!cepNumeros) {
		cepNumeros = '';
	}
	if(typeof(cepNumeros) === 'number') {
		cepNumeros.toString();
	}
	if(cepNumeros.length === 8) {
		return true;
	}
	if(mensagem) {
		msgToast.error(mensagem);
	}
	return false;
}

export const caminhoFoto = (url, placeholder = imgUserPicDefault, retornarUrl = true) => {
	var retorno = {
		api: false,
		url: `${placeholder}`,
	};

	if(url && typeof(url) === 'string' && url.length > 1) {
		if(url.indexOf('amazonaws.com') > -1) {
			retorno = {
				api: true,
				url: `${url}`
			};
		} else if(url.indexOf('Content/images/customer') > -1) {
			retorno = {
				api: true,
				url: `${URL_API_IMAGENS}/${url}`
			};
		} else if(url.indexOf('Content/images/user') > -1) {
			retorno = {
				api: true,
				url: `${URL_API_IMAGENS}/${url}`
			};
		} else if(url.indexOf('users/profile') > -1) {
			retorno = {
				api: true,
				url: `${URL_API_IMAGENS}/${url}`
			};
		} else if(url.indexOf('/static/media') > -1) {
			retorno = {
				api: false,
				url: `${url}`
			};
		} else {
			retorno = {
				api: false,
				url: `${placeholder}`
			};
		}
	}

	if(!retornarUrl) {
		retorno.url = false;
	}

	return retorno;
}

export const caminhoVideo = (url) => {
	if(url && url !== '') {
		if(url.indexOf('Content/video/') > -1) {
			return `${URL_API_ARQUIVOS}/${url}`;
		}
	}
	return url;
}

export const caminhoUrl = (url) => {

	if(url && typeof(url) === 'string' && url.length > 1) {

		if(url.indexOf('/static/media') > -1) {
			return url;
		} else {
			return `${URL_API_ARQUIVOS}/${url}`;
		}
	}

	return url;
}

export const addPrefixo = (url, prefixo = 'https://') => {
	if(typeof(url) === 'object') {
		return '';
	}
	if(url === '') {
		return '';
	}
	if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
		url = `${prefixo}${url}`;
	}
	return url.trim();
}

export const validarUrl = (url, mensagem = 'A URL inserida é inválida.') => {
	if(!url || typeof(url) !== 'string') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	var res = false;
	if(url && typeof(url) === 'string') {
		res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	}
	if(url.trim().indexOf(' ') > -1) {
		res = false;
	}
	if(!res) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	return true;
}

export const stringPesquisa = string => {
	if(string === null || string === undefined) {
		string = '';
	}
	if(isNumeric(string)) {
		return string.toString();
	}
	return _.deburr(string.toString().toLowerCase());
}

export const stringSlug = string => {
	if(string === null || string === undefined) {
		string = '';
	}
	if(isNumeric(string)) {
		return string.toString();
	}
	var retorno = string.toLowerCase().replace(/[^a-z]/g, "");
	return retorno;
}


export const apenasLetrasSlug = string => {
	if(string === null || string === undefined) {
		string = '';
	}
	if(isNumeric(string)) {
		return string.toString();
	}
	var retorno = string.toLowerCase().replace(/[^a-z]/g, "");
	return retorno;
}

export const corDegrade = (corInicio, corFim, porcentagem) => {
	var r1 = parseInt(corInicio.substring(1,3),16);
	var g1 = parseInt(corInicio.substring(3,5),16);
	var b1 = parseInt(corInicio.substring(5,7),16);
	var r2 = parseInt(corFim.substring(1,3),16);
	var g2 = parseInt(corFim.substring(3,5),16);
	var b2 = parseInt(corFim.substring(5,7),16);
	var r3 = (256+(r2-r1)*porcentagem/100+r1).toString(16);
	var g3 = (256+(g2-g1)*porcentagem/100+g1).toString(16);
	var b3 = (256+(b2-b1)*porcentagem/100+b1).toString(16);
	return '#'+r3.substring(1,3)+g3.substring(1,3)+b3.substring(1,3);
}

export const simNao = (status, naoInformado = 'Não informado') => {
	if(status === 1 || status === '1' || status === true) {
		return 'Sim';
	} else if(status === 0 || status === '0' || status === false) {
		return 'Não'
	};
	return naoInformado;
}

export const consoleFormData = formdataObj => {
	console.log("%c------------------------------------------------", 'color: red');
	for(var pair of formdataObj.entries()) {
		if(pair[0] == 'acao') {
			var cor = 'red';
		} else {
			var cor = '#0074E8';
		}
		console.log('%cchave:%c '+pair[0]+ '%c - %cvalor:%c '+ pair[1], 'color: '+cor, 'color: #DD00A9', 'color: #000', 'color: #0074E8', 'color: #DD00A9');
	}
	console.log("%c------------------------------------------------", 'color: red');
}

export const joinArray = (arr, separador = ',', final = 'e', keyObj = false) => {
    var retorno = '';
	if(typeof(arr) === 'object') {
		if (arr.length === 1) {
			if(keyObj) {
				retorno = arr[0][keyObj];
			} else {
				retorno = arr[0];
			}
		} else if (arr.length === 2) {
			if(keyObj) {
				retorno = _.map(arr, keyObj).join(` ${final} `);;
			} else {
				retorno = arr.join(` ${final} `);
			}
		} else if (arr.length > 2) {
			if(keyObj) {
				var keysOk = _.map(arr, keyObj);
				retorno = keysOk.slice(0, -1).join(`${separador} `) + `, ${final} ` + keysOk.slice(-1);
			} else {
				retorno = arr.slice(0, -1).join(`${separador} `) + `, ${final} ` + arr.slice(-1);
			}
		}
	} else {
		retorno = arr;
	}
    return retorno;
}

export const joinObj = (obj, separador = ',', final = 'e') => {
    var retorno = '';
	if(typeof(obj) === 'object') {
		var arr = Object.keys(obj);
		if (arr.length === 1) {
			retorno = arr[0];
		} else if (arr.length === 2) {
			retorno = arr.join(` ${final} `);
		} else if (arr.length > 2) {
			retorno = arr.slice(0, -1).join(`${separador} `) + `, ${final} ` + arr.slice(-1);
		}
	} else {
		retorno = arr;
	}
    return retorno;
}

export const range = (start, stop, step) => {
    var a = [start], b = start;
    while (b < stop) {
        a.push(b += step || 1);
    }
    return (b > stop) ? a.slice(0,-1) : a;
}

export const numeroAleatorio = (max = 15, inicio = 1) => {
	return Math.floor(Math.random() * max) + inicio;
}

export const retornoFiltros = dados => {
	const {
		padrao = {},
		retorno = {},
	} = dados;

	var ret = padrao;

	if(Object.keys(padrao).length > 0 && Object.keys(retorno).length > 0) {
		ret = {};
		Object.keys(padrao).map((val, key) => {
			if(retorno[val] !== undefined) {
				ret[val] = retorno[val];
			} else {
				ret[val] = padrao[val];
			}
		});
	}
	return ret;
}

export const isOverflownX = element => {
	if(element && element.current) {
		if(element.current.scrollWidth > element.current.clientWidth) {
			return true;
		} else {
			return false;
		}
	}
	return null;
}

export const singularPlural = (qtd, singular = 'item', plural = 'itens', nenhum = 'Nenhum item') => {
	if(isNumeric(qtd)) {
		if(Number(qtd) > 1) {
			return plural;
		}
		if(Number(qtd) > 0) {
			return singular;
		}
	}
	return nenhum;
}

export const toFixed = (numero, qtdCasas = 2) => {
	if(typeof(numero) === 'number') {
		if(numero > 0) {
			return numero.toFixed(qtdCasas);
		}
	}
	return numero;
}

export const html = jsx => {
	return ReactDOMServer.renderToStaticMarkup(jsx);
}

export const jsxToHtml = jsx => {
	return ReactDOMServer.renderToStaticMarkup(jsx);
}

export const sanitize = (htmlString, replace = {}, opts = {}) => {
	if(typeof(htmlString) === 'string') {
		var ok = htmlString.replace(/(^|;)\s*font-[^;]+/g,"");
		return DOMPurify.sanitize(ok);
	}
	return '';
}

export const htmlToJsx = (htmlString, replace = {}, opts = {}) => {
	return parse(sanitize(htmlString), {
		...replace,
		...opts,
	});
}

export const htmlToJsxAdmin = (htmlString, replace = {}, opts = {}) => {
	return parse(htmlString, {
		...replace,
		...opts,
	});
}

export const trim = (texto, caracterTrim = ' ') => {
    var start = 0,
        end = texto.length;

    while(start < end && texto[start] === caracterTrim)
        ++start;

    while(end > start && texto[end - 1] === caracterTrim)
        --end;

    return (start > 0 || end < texto.length) ? texto.substring(start, end) : texto;
}

export const trimRight = (texto, remover) => {
    for(;;) {
        var pos = texto.lastIndexOf(remover);
        if(pos === texto.length -1) {
            var result  = texto.slice(0,pos);
            texto = result;
        } else {
            break;
        }
    }
    return texto;
}

export const trimArr = (texto, caracterTrimArr, sufixo = false) => {
    var retorno = texto;
	caracterTrimArr.map((val, key) => {
		retorno = trim(retorno, val);
	});
	if(sufixo) {
		retorno = trimRight(retorno, sufixo);
	}
	return retorno;
}

export const urlExibicao = url => {
	if(url.startsWith('http://')) {
		url = url.slice('http://'.length);
	} else if(url.startsWith('https://')) {
		url = url.slice('https://'.length);
	}
	return trimRight(url, '/');
}

export const removerHtml = (texto, tagsPermitidas = [], barraN = true) => {
	if(texto) {
		var clean = DOMPurify.sanitize(texto, {ALLOWED_TAGS: tagsPermitidas}); // ['b', 'a', 'strong']
		if(barraN) {
			clean = clean.replace(/\n\n+/g, '\n\n');
		}
		return clean;
		// return texto.replace(/<br\s*\\?>/g, "\r\n\n").replace(/<[^>]+>/g, "");
	}
	return texto;
}

export const ultimoNome = (nomeCompleto, retornoDefault = '') => {
	if(nomeCompleto && nomeCompleto !== '' && nomeCompleto.indexOf(' ') >= 0) {
		return nomeCompleto.split(' ').slice(-1)[0];
	}
	return retornoDefault;
}

export const cidadeEstado = (statePerfilFreelancer) => {
	var retorno = '';
	if(statePerfilFreelancer.city) {
		retorno = statePerfilFreelancer.city;
	}
	if(statePerfilFreelancer.state) {
		if(retorno !== '') {
			retorno += ` - ${statePerfilFreelancer.state}`;
		} else {
			retorno = statePerfilFreelancer.state;
		}
	}
	if(retorno === '') {
		retorno = <><i>Cidade</i> - <i>Estado</i></>;
	}
	return retorno;
}


export const isNumeroDiferente = (antigo, novo) => {
	var retorno = false;
	if(antigo !== novo) {
		if(isNumeric(antigo) && isNumeric(novo)) {
			if(Number(antigo) !== Number(novo)) {
				retorno = true;
			} else {
				retorno = false;
			}
		} else {
			retorno = true;
		}
	}
	return retorno;
}

export const isObjDiferente = (fromObject, toObject, retornoIgual = {}) => {
    const changes = {};

    const buildPath = (path, obj, key) =>
        _.isUndefined(path) ? key : `${path}.${key}`;

    const walk = (fromObject, toObject, path) => {
        for (const key of _.keys(fromObject)) {
            const currentPath = buildPath(path, fromObject, key);
            if (!_.has(toObject, key)) {
                changes[currentPath] = {from: _.get(fromObject, key)};
            }
        }

        for (const [key, to] of _.entries(toObject)) {
            const currentPath = buildPath(path, toObject, key);
            if (!_.has(fromObject, key)) {
                changes[currentPath] = {to};
            } else {
                const from = _.get(fromObject, key);
                if (!_.isEqual(from, to)) {
                    if (_.isObjectLike(to) && _.isObjectLike(from)) {
                        walk(from, to, currentPath);
                    } else {
                        changes[currentPath] = {from, to};
                    }
                }
            }
        }
    };

    walk(fromObject, toObject);

	if(Object.keys(changes).length) {
		return changes;
	}

	return retornoIgual;

}


/*
** Definir filtros para a busca
** ================================================== */
export const removerKeysVazias = (dados = {}) => {
	var dadosSemKeysVazias = {};
	Object.keys(dados).map((val, key) => {
		if(dados[val] && dados[val] !== false && dados[val] !== '' && dados[val] !== 'false') {
			if(
				(typeof(dados[val]) === 'string' && dados[val] !== '') ||
				(typeof(dados[val]) === 'object' && dados[val].length > 0) ||
				(typeof(dados[val]) === 'boolean') ||
				(typeof(dados[val]) === 'number' && dados[val] > 0)
			) {
				if(dados[val] !== 'Invalid date') {
					dadosSemKeysVazias[val] = dados[val];
				}
			}
		}
	});

	return dadosSemKeysVazias;
}

export const temKeys = (dados = {}) => {
	if(dados && Object.keys(dados).length) {
		return true;
	}
	return false;
}

export const converterNumerosParaNumeric = (dados = {}) => {
	var dadosConvertidosParaNumeric = {};
	Object.keys(dados).map((val, key) => {
		if(dados[val] && dados[val] !== false && dados[val] !== '' && isNumeric(dados[val])) {
			dadosConvertidosParaNumeric[val] = Number(dados[val]);
		} else {
			dadosConvertidosParaNumeric[val] = dados[val];
		}
	});

	return dadosConvertidosParaNumeric;
}

export const stringify = (obj) => {
	var retorno = '';
	if(obj && typeof(obj) === 'object') {
		Object.keys(obj).map((val, key) => {
			retorno = `${val}=${obj[val]}`;
		});
	}
	return retorno;
}

export const formatarSelect = (dadosObj = {}) => {
	// dadosArr, keyIcone = false, caminhoFotoAuto = true, iconClass = false
	const {
		dados = [],
		keyValue = 'value',
		keyName = 'name',
		keyIcone,
		iconClass,
		caminhoFotoDefault = true,
	} = dadosObj;

	var retorno = [];
	if(typeof(dados) && dados.length) {
		dados.map((val) => {
			var obj = {
				...val,
				value: val[keyValue],
				label: val[keyName],
			};
			if(keyIcone) {
				if(val[keyIcone] !== undefined) {
					if(caminhoFotoDefault) {
						obj.icon = caminhoFoto(val[keyIcone]).url;
					} else {
						obj.icon = val[keyIcone];
					}
					if(iconClass) {
						obj.iconClass = iconClass;
					}
				}
			}
			retorno.push(obj);
		});
	}
	return retorno;
}

export const formatarSelectAlgolia = (dados = []) => {
	var retorno = [];
	if(typeof(dados) && dados.length) {
		dados.map((val) => {
			var obj = {
				_: val,
				value: val.Id,
				label: val.Name || '',
				photo: caminhoFoto(val.Photo).url,
				id: val.Id,
				email: val.Email,
				price: val.Price,
				area: val.Category && val.Category.Name ? val.Category.Name : 'Área não informada',
			};
			retorno.push(obj);
		});
	}
	return retorno;
}

export const tamanhoTela = (dadosArr, retornoDefault, screen) => {

	if(!dadosArr || typeof(dadosArr) !== 'object' || dadosArr[0] === undefined) {
		return retornoDefault;
	}

	var retorno = retornoDefault;
	var encontrado = false;
	var dadosArrReverse = dadosArr.reverse();

	dadosArrReverse.map((val, key) => {
		if(!encontrado) {
			if(val.width >= screen.width) {
				encontrado = true;
				retorno = val.dados;
			}
		}
	});

	if(!encontrado) {
		retorno = retornoDefault;
	}

	return retorno;
}

export const cssTamanho = (tamanho) => {
	return {
		minWidth: tamanho,
		maxWidth: tamanho,
		minHeight: tamanho,
		maxHeight: tamanho,
	};
}

export const cssBg = img => {
	return {
		backgroundImage: `url('${img}')`,
	};
}

export const cssCorImagem = (dados = {}) => {
	var {
		width,
		height,
		cor,
		img,
	} = dados;

	var tamanho;

	if(width || height) {
		tamanho = width;
	}

	return {
		...(tamanho ? {width: tamanho, height: tamanho} : {}),
		...(cor ? {backgroundColor: cor} : {}),
		WebkitMaskImage: `url(${img})`,
		maskImage: `url(${img})`
	};
}

export const kbToMb = (valorEmBytes, retornarDescricao = false) => {
	const unidades = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(valorEmBytes, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	var valorFinal = n.toFixed(n < 10 && l > 0 ? 1 : 0);
	if(retornarDescricao) {
		valorFinal = `${valorFinal} ${unidades[l]}`;
	}
	return valorFinal;
}

export const typeFileSelecionarImagem = async dados => {
	const {
		e,
		formatos = ['image/png', 'image/jpg', 'image/jpeg'],
		tamanhoMax = 5120, // em MB // 5MB
		msgSucesso = 'Arquivo carregado com sucesso',
		msgInvalido = 'Arquivo não permitido. Selecione uma foto com o formato jpg, jpeg ou png.',
		msgIncorreto = 'Parece que sua foto está corrompida ou com alguma assinatura inválida, envie outra foto.',
		msgTamanho = 'O arquivo selecionado é muito grande, selecione um arquivo menor.',
		exibirTamanhoMax = true,
		cb = () => null,
	} = dados;

	var img = {};
	if(e && e.target && e.target.files && e.target.files[0]) {
		img = e.target.files[0];
	}

	var tamanho = tamanhoMax * 1024;

	if(img.size > tamanho) {
		msgToast.error(
			<>
				<div>{msgTamanho}</div>
				{exibirTamanhoMax ? (
					<div className="margin-t-10">Tamanho máximo: {kbToMb(tamanho, true)}</div>
				) : null}
			</>
		)
		cb({
			sucesso: false,
			msg: msgTamanho,
			erro: 'tamanho',
			tamanho: img.size,
			tamanhoMax: tamanhoMax,
			tamanhoMaxMb: tamanho,
		});
		return;
	}

	if(formatos.includes(img.type)) {
		let reader = new FileReader();
		reader.readAsDataURL(img);
		reader.onload = eImg => {
			let imagemBase64 = eImg.target.result;
			if(imagemBase64) {
				cb({
					sucesso: true,
					base64: imagemBase64,
					msg: msgSucesso,
				});
				return;
			} else {
				cb({
					sucesso: false,
					msg: msgIncorreto,
					erro: 'incorreto',
				});
				return;
			}
		};
	} else {
		cb({
			sucesso: false,
			msg: msgInvalido,
			erro: 'invalido',
		});
		return;
	}
}

export const dadosEnviarFormData = async dadosObj => {
	const {
		dadosEnviar,
		imagem = '',
		keyArquivo = 'logo',
		nomeArquivo = 'foto',
		showConsoleLog,
	} = dadosObj;

	var retorno = dadosEnviar;

	if(imagem && imagem !== '') {
		const extensao = imagem.match(/[^:/]\w+(?=;|,)/);
		if(extensao !== null) {

			var arquivo = await fetch(imagem).then(r => r.blob());

			if(!arquivo.name) {
				arquivo = blobToFile(arquivo, `${nomeArquivo}.${extensaoPorMimeType(arquivo)}`);
			}

			var dadosFormData = new FormData();

			retorno = removerKeysVazias(dadosEnviar);

			Object.keys(retorno).map((val, key) => {
				dadosFormData.append(val, retorno[val]);
			});

			dadosFormData.append(keyArquivo, arquivo);

			retorno = dadosFormData;
			if(showConsoleLog) {
				consoleFormData(retorno);
			}
		}
	} else {
		retorno = removerKeysVazias(dadosEnviar);
	}
	return retorno;
}

export const multiplicarElementos = (qtd, el) => {
	if(!isNumeric(qtd)) {
		return el;
	}
	var retorno = [];
	if(qtd <= 0) {
		qtd = 1;
	}
	for (let i = 0; i < qtd; i++) {
		retorno.push(el);
	}
	return retorno;
}

export const statusDefault = status => {
	var statusNome = '(sem status)';
	if(status || status === 0) {
		var statusNomeObj = _.find(STATUS_DEFAULT, {value: status});
		if(statusNomeObj && statusNomeObj.label) {
			statusNome = statusNomeObj.label;
		}
	}
	return statusNome;
}

export const checked = dados => {
	const {
		state,
		id,
		isChecked,
	} = dados;

	var stateArr = state.map(value => {
		if(value.id === id) {
			return {
				...value,
				checked: isChecked,
			}
		}
		return value;
	});
	return stateArr;
}

export const arrGetCheckeds = (lista = [], keyId = 'id', keyRetorno) => {
	var retorno = [];
	if(lista && typeof(lista) === 'object' && lista.length) {
		lista.map((val, key) => {
			if(val.checked) {
				if(keyRetorno) {
					retorno.push({
						[keyRetorno]: val[keyId]
					});
				} else {
					retorno.push(val);
				}
			}
		});
	}
	return retorno;
}

export const arrValues = (lista = [], keyRetorno, keyObj) => {
	var retorno = [];
	if(lista && typeof(lista) === 'object' && lista.length) {
		lista.map((val, key) => {
			if(val[keyRetorno]) {
				if(keyObj) {
					retorno.push({
						[keyObj]: val[keyRetorno]
					});
				} else {
					retorno.push(val[keyRetorno]);
				}
			}
		});
	}
	return retorno;
}

export const arrValue = (lista = [], keySearch, valueCompare, keyRetorno, retornoDefault = '') => {
	var retorno = retornoDefault;
	if(lista && typeof(lista) === 'object' && lista.length) {
		lista.map((val, key) => {
			if(val[keySearch] === valueCompare) {
				retorno = val[keyRetorno];
			}
		});
	}
	return retorno;
}

export const checkArr = (listaCompleta, listaComCheck, keyLista1 = 'id', keyLista2 = 'id') => {

	if(!listaComCheck || typeof(listaComCheck) !== 'object') {
		listaComCheck = [];
	}

	var _listaCompleta = listaCompleta.map(item => {
		return {
			...item,
			checked: false,
		}
	});

	var _listaCompletaFinal = _listaCompleta.map(item => {
		if(_.find(listaComCheck, {[keyLista1]: item[keyLista2]})) {
			return {
				...item,
				checked: true,
			}
		}
		return item;
	});

	return _listaCompletaFinal;
}

export const uncheckArr = (lista) => {

	if(!lista || typeof(lista) !== 'object') {
		lista = [];
	}

	var _listaCompleta = lista.map(item => {
		return {
			...item,
			checked: false,
		}
	});

	return _listaCompleta;
}

export const unificarArr = (arrays, keyToCheck = 'id') => {
	if(!arrays || typeof(arrays) !== 'object' || !arrays.length) {
		return [];
	}

	var arrs = [];
	arrays.map(val => {
		if(val) {
			arrs = [
				...arrs,
				...val,
			];
		}
	});

	const unicoQrr = _.uniqBy(arrs, e => {
		return e[keyToCheck];
	});

	return unicoQrr;
}

export const extensaoString = string => {
	if(typeof(string) === 'string') {
		return string.match(/[^:/]\w+(?=;|,)/);
	}
	return false;
}

export const getKey = (dadosArr, valorPesquisar, chavePesquisar) => {
	var dados = {};

	if(dadosArr && typeof(dadosArr) === 'object' && dadosArr.length) {
		dados = _.find(dadosArr, {[chavePesquisar]: valorPesquisar});
		if(dados && typeof(dados) === 'object' && Object.keys(dados).length) {
			return dados;
		}
	}

	return {};
}

export const tipoTemplate = idTipo => {
	if(TIPOS_TEMPLATE.editor.includes(idTipo)) {
		return 'html';
	} else if(TIPOS_TEMPLATE.textarea.includes(idTipo)) {
		return 'texto';
	}
	return 'outro';
}

export const linkVoltar = (linkState, link) => {
	var queryObj = {}
	if(linkState && linkState !== '') {
		queryObj = clearGet(linkState);
		if(queryObj.page === 0 || queryObj.page === '0') {
			delete queryObj.page;
		} else {
			queryObj.page = (queryObj.page + 1);
		}
	}
	if(link) {
		var retorno = link;
		if(Object.keys(queryObj).length) {
			var queryString = qs.stringify(queryObj);
			retorno = `${link}?${queryString}`;
		}
		return retorno;
	}
	return queryObj;
}

export const linkRedeSocial = ({url, site = ''}) => {
	const links = {
		whatsapp: `https://wa.me/?text=`,
		facebook: `https://www.facebook.com/sharer/sharer.php?u=`,
		linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=`,
		tweeter: `https://twitter.com/intent/tweet?url=`,
		email: `mailto:?body=`,
	};

	var linkAtual = `${links[site]}${url}`;
	return linkAtual;
}

export const arredondarNumero = n => {
	if(typeof(n) === 'number') {
		return n.toFixed(1);
	}
	return n;
}

export const getIdArray = (arr, key = 'id') => {
	if(arr && arr.length) {
		return arr[0][key];
	}
	return false;
}
export const matchTipo = t => {
	if(t === 'P') return 'Profissional';
	if(t === 'S') return 'Serviço';
	return '(não informado)';
}
