import { useRoutes } from 'react-router-dom';

// Layout
import LayoutBlank from "@_role/Layouts/LayoutBlank/LayoutBlank";
import LayoutInicioAlt from "@role/Layouts/LayoutInicioAlt/LayoutInicioAlt";
import { LayoutPage } from "@role/Layouts/LayoutPage/LayoutPage";

// Screens
import CadastroDeEmpresas from "@page/SemRestricoes/LandingPages/CadastroDeEmpresas/CadastroDeEmpresas";
import MeIndiqueUmProfissionalRemoto from "@page/SemRestricoes/LandingPages/MeIndiqueUmProfissionalRemoto/MeIndiqueUmProfissionalRemoto";
import { TermosDeUso } from "@page/SemRestricoes/LandingPages/TermosDeUso/TermosDeUso";
import FreelancerPerfil from "@page/SemRestricoes/FreelancerPerfil/FreelancerPerfil";
import { DEV } from "@_config/config";
import Erro404Deslogado from "@page/Deslogado/Erro404Deslogado/Erro404Deslogado";
import CadastroDeEmpresasLite from "@page/SemRestricoes/LandingPages/CadastroDeEmpresasLite/CadastroDeEmpresasLite";
import { CadastroDeEmpresasLiteAtivacao } from "@page/SemRestricoes/LandingPages/CadastroDeEmpresasLite/CadastroDeEmpresasLiteAtivacao/CadastroDeEmpresasLiteAtivacao";
import { TesteGTM } from "@page/SemRestricoes/TesteGTM/TesteGTM";

export const ROTAS_SEM_RESTRICOES = [
	{
		path: '/termos-de-uso',
		element:
		<LayoutPage
			title="Termos de uso"
			component={TermosDeUso}
		/>,
	},
	{
		path: '/me-indique-um-profissional-remoto',
		element:
		<LayoutBlank
			title="Me indique um profissional remoto"
			component={MeIndiqueUmProfissionalRemoto}
		/>,
	},
	(DEV ?
		{
			path: '/cadastro-de-empresas',
			element:
			<LayoutInicioAlt
			title="Cadastro de empresas"
			component={CadastroDeEmpresas}
			/>,
		} : {}
	),
	{
		path: '/cadastro-de-empresas-lite',
		element:
		<LayoutInicioAlt
			title="Cadastro de empresas lite"
			component={CadastroDeEmpresasLite}
		/>,
	},
	{
		path: '/cadastro-de-empresas-lite/ativacao',
		element:
		<LayoutBlank
			title="Ative seu cadastro de empresa lite"
			component={CadastroDeEmpresasLiteAtivacao}
		/>,
	},
	// (DEV ? {
	// 	path: '/teste-gtm',
	// 	element:
	// 	<LayoutBlank
	// 		title="Teste GTM"
	// 		component={TesteGTM}
	// 	/>,
	(DEV ? {
		path: '/freelancer/:id',
		element:
		<LayoutBlank
			title="Perfil do freelancer"
			component={FreelancerPerfil}
		/>,
	} : {}),
	{
		path: '*',
		element:
		<Erro404Deslogado
			title="Página não encontrada..."
			component={Erro404Deslogado}
		/>,
	}
];

function ScreensSemRestricoes() {
	const rotasGerais = useRoutes(ROTAS_SEM_RESTRICOES);
	return rotasGerais;
}

export { ScreensSemRestricoes };