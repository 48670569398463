import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Componentes
import Disponibilidade from '@cpnt/Disponibilidade/Disponibilidade';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import PortalPerfil from '@portalGeral/PortalPerfil/PortalPerfil';

// Funções
import { caminhoFoto } from '@func/utils';
import { closePortal, getPortalDados, gets, isShowPortal, showPortal } from '@route/routeUtils';
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";

const DetalhesEquipe = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		className = '',
		wrap = '',
		restricted,
		name,
		id,
		client_name,
		team,
		teamFreelancers,
		after,
	} = props;

	const [stateGet, setStateGet] = useState(gets());
	const [statePerfilVisualizacao, setStatePerfilVisualizacao] = useState({});

	useEffect(() => {
		setStateGet(gets());
	}, [location.search]);

	return (
		<>
			<div className={`cpnt-detalhesequipe ${wrap}`}>
				<div className={`cpnt-detalhesequipe-box ${className}`}>
					<h3 className="margin-v-0 inline-flex flex-center-v">
						{restricted === 1 && <Disponibilidade textoIndisponivel="Restrito" min status={0} className="margin-r-7 margin-b--3" />}
						<span>{name} <span className="color-1">(ID: {id})</span></span>
					</h3>
					<div className="fs-16 color-1 margin-t-2">{client_name}</div>

					<div>
						{team && team.length ? (
							<div>
								<h3>Time <span className="color-1 fw-lighter">({team.length})</span></h3>
								<div className="wrap-5">
									{team.map((value, index) => {
										return (
											<ItemFotoDesc
												className="margin-2 cursor-pointer"
												key={index}
												foto={caminhoFoto(value.user_photo).url}
												titulo={value.user_name}
												subtitulo={value.area_name || value.role}
												onClick={() => {
													setStatePerfilVisualizacao({
														show: true,
														tipo: 'usuario',
														id: value.id_user,
													});
												}}
											/>
										)
									})}
								</div>
							</div>
						) : (
							<div>
								<h3 className="margin-b-0">Time</h3>
								<div className="fs-16 color-1">Nenhum usuário selecionado para o time.</div>
							</div>
						)}
					</div>

					<div className="padding-t-15">
						{teamFreelancers && teamFreelancers.length ? (
							<div>
								<h3>Profissionais Freelancers <span className="color-1 fw-lighter">({teamFreelancers.length})</span></h3>
								<div className="wrap-5">
									{teamFreelancers.map((value, index) => {
										return (
											<ItemFotoDesc
												className="margin-2 cursor-pointer"
												key={index}
												foto={caminhoFoto(value.photo).url}
												titulo={value.name}
												subtitulo={value.area_name}
												onClick={() => {
													setStatePerfilVisualizacao({
														show: true,
														tipo: 'freelancer',
														id: value.id_freelancer,
													});
												}}
											/>
										)
									})}
								</div>
							</div>
						) : (
							<div>
								<h3 className="margin-b-0">Profissionais Freelancers</h3>
								<div className="fs-16 color-1">Nenhum profissional selecionado para o time.</div>
							</div>
						)}
					</div>

				</div>
			</div>

			{after}

			<PortalPerfil
				idFreelancer={statePerfilVisualizacao.id}
				idUsuario={statePerfilVisualizacao.id}
				tipo={statePerfilVisualizacao.tipo}
				show={statePerfilVisualizacao.show}
				onClose={() => setStatePerfilVisualizacao({})}
			/>
		</>
	);
};

export default DetalhesEquipe;