import React, { useEffect, useState } from 'react';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { msg} from '@func/utils';

import Button2 from "@cpnt/Button2/Button2";
import TodasAvaliacoes from "@page/SemRestricoes/FreelancerPerfil/TodasAvaliacoes/TodasAvaliacoes";
import c from './PortalFreelancerAvaliacoes.module.css';
import { msgs, msgStatus } from "@_func/request";
import { api } from "@_api/api";

const PortalFreelancerAvaliacoes = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = c['portal'],
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [page, setPage] = useState(0);

	const [stateAvaliacoes, setStateAvaliacoes] = useState([]);
	const [_stateAvaliacoes, _setStateAvaliacoes] = useState({});
	const [stateAvaliacoesMedia, setStateAvaliacoesMedia] = useState({});
	const [_stateAvaliacoesMedia, _setStateAvaliacoesMedia] = useState({});

	const [loadingGetAvaliacoes, setLoadingGetAvaliacoes] = useState(false);
	const [loadingGetAvaliacoesMedia, setLoadingGetAvaliacoesMedia] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show === true) {
			setLoading(true);
			setPage(0);
			setStateAvaliacoes([]);
			setStateAvaliacoesMedia({});
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const ret = await api.freelancerRatings.ratingMedia.get({
			id_freelancer: id,
		});

		_setStateAvaliacoesMedia(msgStatus(ret));

		if(ret.status === 200) {
			setStateAvaliacoesMedia(ret.data);
			getAvaliacoes();
		} else {
			msgs([ret]);
		}

		setLoading(false);
	}

	/*
	** API - Avaliações
	** ================================================== */
	const getAvaliacoes = async (carregandoMais) => {
		setLoadingGetAvaliacoes(true);

		const ret = await api.freelancerRatings.all.get({
			id_freelancer: id,
			limit: 20,
			page: page,
		});

		if(ret.status === 200) {
			setStateAvaliacoes(prevState => [...prevState, ...ret.data]);
			setPage(prevState => prevState + 1);
		} else {
			if(carregandoMais) {
				msg.info('Todas as avaliações já foram carregadas');
			} else {
				msgs([ret]);
			}
		}
		setLoadingGetAvaliacoes(false);
	}


	return (
		<Portal
			titulo={loading ? 'Avaliações' : (_stateAvaliacoesMedia.status === 200 ? `Avaliações de ${stateAvaliacoesMedia.freelancer_name}` : 'Avaliações')}
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={() => {
				onClose();
				setPage(0);
			}}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateAvaliacoesMedia.status === 200 ? (
							<div>
								<TodasAvaliacoes
									stateDados={stateAvaliacoesMedia}
									avaliacoes={stateAvaliacoes}
									getAvaliacoes={getAvaliacoes}
								/>
								<div className="flex flex-center padding-t-20">
									<Button2
										_={{
											value: 'Carregar mais',
											type: 'button',
											onClick: () => getAvaliacoes(true),
										}}
										loading={loadingGetAvaliacoes}
									/>
								</div>
							</div>
						) : (
							<Info
								texto={_stateAvaliacoesMedia.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
			<div className="form-bottom">
				<Button2
					_={{
						value: 'Fechar',
						type: 'button',
						onClick: () => {
							setPage(0);
							onClose();
						},
					}}
				/>
			</div>
		</Portal>
	);
};

export default PortalFreelancerAvaliacoes;