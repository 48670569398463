import React, { useState, useContext } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './FormProjetos.css';

// Componentes
import Loading from '@cpnt/Loading/Loading';
import { Select2 } from '@cpnt/Select2/Select2';
import Input2 from '@cpnt/Input2/Input2';
import BtnAdicionar from '@cpnt/BtnAdicionar/BtnAdicionar';
import PesquisaAlgolia from '@cpnt/PesquisaAlgolia/PesquisaAlgolia';
import InputCalendario from '@cpnt/InputCalendario/InputCalendario';

// Funções
import {  msg } from '@func/utils';
import { Editor } from '@_cpnt/Inputs/Editor/Editor';
import Tip from '@cpnt/Tip/Tip';
import { InputDate } from "@cpnt/InputDate/InputDate";
import Select2Algolia from "@cpnt/Select2Algolia/Select2Algolia";
import { MSG_ACESSO_EM_ANALISE_FUNCIONALIDADE_DESATIVADA } from "@texto/textosGerais";

const FormProjetos = props => {

	const [store, setStore] = useContext(StoreContext);

	const {
		isCadastro = true,
		titulo = 'Dados do projeto',
		before,
		after,

		// States
		stateDados,
		setStateDados,
		stateClientes,
		stateEmpresas,
		stateProdutos,
		stateSolucoes,
		stateCenarios,
		stateStatus,

		stateAnexos,
		setStateAnexos,
		stateUsuarios,
		stateAreas,
		stateFreelancers,
		setStateFreelancers,

		// Editor
		stateDescricao,
		setStateDescricao,

		// Loading
		loadingGetClientes,
		loadingGetEmpresas,
		loadingGetProdutos,
		loadingGetSolucoes,
		loadingGetCenarios,
		loadingGetStatus,
		loadingGetUsuarios,
		loadingGetAreas,

		onSubmit,

		getClientes = () => null,
		getUsuarios = () => null,

		stateUsuariosSelecionado,
		setStateUsuariosSelecionado,

		stateRetornoAlgolia = [],
		setStateRetornoAlgolia,
		acessoRestrito,

	} = props;

	return (
		<form onSubmit={onSubmit}>
			<div>
				{before}
				<h3 className="margin-t-0 margin-b-5">{titulo}</h3>
				<div className="wrap-h-5">
					<div className="flex flex-wrap">
						{store.usuario.role === 1 ? (
							<>
								{isCadastro ? (
									<>
										<div className="input-box w-50p">
											<Select2
												label="Empresa"
												req
												_={{
													options: stateEmpresas,
													value: stateDados.id_company,
													onChange: e => {
														getClientes(e.value);
														getUsuarios(e.value);
														setStateDados({
															...stateDados,
															id_company: e.value,
															id_customer: null,
														});
														setStateUsuariosSelecionado([]);
													}
												}}
												loading={loadingGetEmpresas}
											/>
										</div>
										<div className="input-box w-50p relative">
											<Select2
												label="Cliente"
												req
												_={{
													options: stateClientes,
													value: stateDados.id_customer,
													onChange: e => {
														setStateDados({
															...stateDados,
															id_customer: e.value,
														})
													}
												}}
												loading={loadingGetClientes}
											/>
										</div>
									</>
								) : null}
								<div className="input-box w-70p">
									<Input2
										label="Nome do projeto"
										req
										_={{
											value: stateDados.name,
											onChange: e => setStateDados({
												...stateDados,
												name: e.target.value,
											})
										}}
									/>

								</div>
								<div className="input-box w-30p">
									<Select2
										label="Projeto restrito"
										tip="Somente usuários Administradores poderão ver este projeto."
										_={{
											value: (stateDados.restricted !== undefined && stateDados.restricted !== null) ? stateDados.restricted : 0,
											options: [
												{label: 'Sim', value: 1},
												{label: 'Não', value: 0},
											],
											onChange: e => {
												setStateDados({
													...stateDados,
													restricted: e.value,
												});
											}
										}}
									/>
								</div>
							</>
						) : (
							<>
								{isCadastro ? (
									<div className="input-box w-50p relative">
										<Select2
											label="Cliente"
											_={{
												options: stateClientes,
												value: stateDados.id_customer,
												onChange: e => setStateDados({
													...stateDados,
													id_customer: e.value,
												})
											}}
											loading={loadingGetClientes}
										/>
									</div>
								) : null}
								<div className="input-box w-50p">
									<Input2
										label="Nome do projeto"
										_={{
											value: stateDados.name,
											onChange: e => setStateDados({
												...stateDados,
												name: e.target.value,
											})
										}}
									/>
								</div>
							</>
						)}

						{/* <div className="input-box w-50p relative">
							<Select2
								label="Tipo de produto"
								_={{
									value: stateDados.product_type,
									options: stateProdutos,
									onChange: e => setStateDados({
										...stateDados,
										product_type: e.value,
									})
								}}
								loading={loadingGetProdutos}
							/>
						</div> */}

						{/* <div className="input-box w-50p relative">
							<Select2
								label="Soluções"
								_={{
									isMulti: true,
									isClearable: true,
									value: stateDados.solutions,
									closeMenuOnSelect: false,
									options: stateSolucoes,
									onChange: e => {
										setStateDados({
											...stateDados,
											solutions: e,
										})
									}
								}}
								loading={loadingGetSolucoes}
							/>
						</div> */}

						<div className="input-box w-50p">
							<InputDate
								label="Data de início"
								value={stateDados.start_date}
								onChange={e => {
									setStateDados({
										...stateDados,
										start_date: e,
									});
								}}
							/>
						</div>
						<div className="input-box w-50p">
							<InputDate
								label="Data de término"
								value={stateDados.end_date}
								onChange={e => {
									setStateDados({
										...stateDados,
										end_date: e,
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div>
				<h3 className="margin-t-10 margin-b-5">Sobre o projeto</h3>
				<div className="wrap-h-5">
					<div className="flex flex-wrap">
						<div className="input-box w-33p relative">
							<Select2
								label="Cenário"
								_={{
									value: stateDados.scenery_type,
									options: stateCenarios,
									onChange: e => setStateDados({
										...stateDados,
										scenery_type: e.value,
									})
								}}
								loading={loadingGetCenarios}
							/>
						</div>
						<div className="input-box w-33p relative">
							<Select2
								label="Status"
								_={{
									value: stateDados.status,
									options: stateStatus,
									onChange: e => setStateDados({
										...stateDados,
										status: e.value,
									})
								}}
								loading={loadingGetStatus}
							/>
						</div>
						<div className="input-box w-100p">
							<Editor
								value={stateDescricao}
								setValue={setStateDescricao}
							/>
						</div>
					</div>
				</div>
			</div>

			<div>
				<h3 className="margin-t-10 margin-b-5">Rentabilidade</h3>
				<div className="wrap-h-5">
					<div className="flex flex-wrap">
						<div className="input-box w-33p">
							<Input2
								label="Receita total prevista"
								dinheiro={true}
								_={{
									value: stateDados.expected_total_cost,
									onChange: e => {
										setStateDados({
											...stateDados,
											expected_total_cost: e,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-33p">
							<Input2
								label="Custo total previsto"
								dinheiro={true}
								_={{
									value: stateDados.total_revenue,
									onChange: e => {
										setStateDados({
											...stateDados,
											total_revenue: e,
										});
									}
								}}
							/>
						</div>
						<div className="input-box w-33p">
							<Input2
								label="Budget mensal"
								dinheiro={true}
								_={{
									value: stateDados.monthly_budget,
									onChange: e => {
										setStateDados({
											...stateDados,
											monthly_budget: e,
										});
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div className="flex flex-center-v">
					<h3 className="flex flex-center-v margin-t-10 margin-b-0">
						<span className="margin-r-20">Anexos</span>
						<BtnAdicionar
							onClick={() => setStateAnexos([...stateAnexos, {label: '', link: ''}])}
						/>
					</h3>
				</div>
				<div className="wrap-h-5">
					<div className="flex flex-wrap">
						{!stateAnexos.length ?
							<h3 className="msg flex margin-h-5 flex-1">Nenhum anexo adicionado.</h3>
						:
							stateAnexos.map((value, index) => {
								return (
									<div key={index} className="flex w-100p">
										<div className="flex flex-1 border-box wrap-input relative">
											<div className="input-box w-40p">
												<Input2
													label="Título"
													_={{
														value: value.label,
														onChange: e => {
															var dados = [...stateAnexos];
															dados[index] = {
																...dados[index],
																label: e.target.value,
															}
															setStateAnexos(dados);
														}
													}}
												/>
											</div>
											<div className="input-box w-60p">
												<Input2
													label="Link"
													_={{
														value: value.link,
														onChange: e => {
															var dados = [...stateAnexos];
															dados[index] = {
																...dados[index],
																link: e.target.value,
															}
															setStateAnexos(dados);
														}
													}}
												/>
											</div>
											<div className="x" onClick={e => setStateAnexos(stateAnexos.filter((item, keyItem) => keyItem !== index))}></div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			<div>
				<div className="flex flex-center-v">
					<h3 className="flex flex-center-v margin-t-10 margin-b-0">
						<span className="margin-r-20">Time interno</span>
						<BtnAdicionar
							onClick={() => setStateUsuariosSelecionado([...stateUsuariosSelecionado, {id_user: '', role: ''}])}
						/>
					</h3>
				</div>
				<div className="wrap-h-5">
					<div className="flex flex-wrap">

						{!stateUsuariosSelecionado.length ?
							<h3 className="msg flex margin-h-5 flex-1">Nenhum usuário do time adicionado.</h3>
						:
							stateUsuariosSelecionado.map((value, index) => {
								return (
									<div key={index} className="flex w-100p">
										<div className="flex flex-1 border-box wrap-input relative">
											<div className="input-box w-60p relative">
												{loadingGetUsuarios && <Loading texto="Carregando, aguarde..." alt absolute wrap="fs-14" />}
												<Select2
													label="Usuário"
													_={{
														value: value.id_user,
														options: stateUsuarios,
														onChange: e => {
															var dados = [...stateUsuariosSelecionado];
															dados[index] = {
																...dados[index],
																id_user: e.value,
																user_name: e.label,
																user_photo: e.icon,
															}
															setStateUsuariosSelecionado(dados);
														}
													}}
													wrap="flex-1"
												/>
											</div>
											<div className="input-box w-40p">
												<Input2
													label="Papel"
													_={{
														value: value.role,
														onChange: e => {
															var dados = [...stateUsuariosSelecionado];
															dados[index] = {
																...dados[index],
																role: e.target.value,
															}
															setStateUsuariosSelecionado(dados);
														}
													}}
												/>
											</div>
											<div className="x" onClick={e => setStateUsuariosSelecionado(stateUsuariosSelecionado.filter((item, keyItem) => keyItem !== index))}></div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			<div>
				<div className="flex flex-center-v">
					<h3 className="flex flex-center-v margin-t-10 margin-b-0">
						<span className="margin-r-20">Profissionais Freelancers</span>
						<BtnAdicionar
							onClick={() => setStateFreelancers([...stateFreelancers, {label: '', link: ''}])}
						/>
					</h3>
				</div>
				<div className="wrap-h-5">
					<div className="flex flex-wrap">

						{!stateFreelancers.length ?
							<h3 className="msg flex margin-h-5 flex-1">Nenhum profissional adicionado.</h3>
						:
							stateFreelancers.map((value, index) => {
								return (
									<div key={index} className="flex w-100p">
										<div className="flex flex-1 border-box wrap-input relative">
											<div className="input-box w-60p">
												<Select2Algolia
													label="Freelancer"
													setStateOptions={setStateRetornoAlgolia}
													_={{
														value: value.id_freelancer,
														onChange: e => {
															var dados = [...stateFreelancers];
															dados[index] = {
																...dados[index],
																id_freelancer: e.id,
																name_freelancer: e.name,
															};
															setStateFreelancers(dados);
														},
														defaultOptions: stateRetornoAlgolia,
														options: stateRetornoAlgolia,
														isClearable: true,
														onClick: () => {
															if(acessoRestrito) {
																msg.warning(MSG_ACESSO_EM_ANALISE_FUNCIONALIDADE_DESATIVADA);
															}
														}
													}}
													desativarRequest={acessoRestrito}
												/>
											</div>
											<div className="input-box w-40p relative">
												{loadingGetAreas && <Loading texto="Carregando, aguarde..." alt absolute wrap="fs-14" />}
												<Select2
													label="Categoria"
													_={{
														value: value.id_area,
														options: stateAreas,
														onChange: e => {
															var dados = [...stateFreelancers];
															dados[index] = {
																...dados[index],
																id_area: e.value,
																area_name: e.label,
															}
															setStateFreelancers(dados);
														}
													}}
													wrap="flex-1"
												/>
											</div>
											<div className="x" onClick={e => setStateFreelancers(stateFreelancers.filter((item, keyItem) => keyItem !== index))}></div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
			{after}
		</form>
	);
};

export default FormProjetos;