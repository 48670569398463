import { arredondarNumero } from '@func/utils';
import React from 'react';

// css
import c from './AvaliacoesBarra.module.css';

const AvaliacoesBarra = props => {
	const {
		className = '',
		classNameWrap = '',

		tasks = {},
		ratings = {},
		tamanho = '110px',
	} = props;

	const avaliacoes = {
		1: {},
		2: {},
		3: {},
		4: {},
		5: {},
		...ratings,
	}

	return (
		<div className={`${classNameWrap}`}>
			<div className={`${className}`}>
				<LinhaBarraAvaliacao
					estrela={5}
					porcentagem={arredondarNumero(avaliacoes[5]?.percent)}
				/>
				<LinhaBarraAvaliacao
					estrela={4}
					porcentagem={arredondarNumero(avaliacoes[4]?.percent)}
				/>
				<LinhaBarraAvaliacao
					estrela={3}
					porcentagem={arredondarNumero(avaliacoes[3]?.percent)}
				/>
				<LinhaBarraAvaliacao
					estrela={2}
					porcentagem={arredondarNumero(avaliacoes[2]?.percent)}
				/>
				<LinhaBarraAvaliacao
					estrela={1}
					porcentagem={arredondarNumero(avaliacoes[1]?.percent)}
				/>
			</div>
		</div>
	);
};

export const LinhaBarraAvaliacao = props => {
	const {
		estrela = 5,
		porcentagem = 0,
	} = props;

	return (
		<div className={`${c['linha-barra-wrap']}`}>
			<div className={`${c['linha-barra-titulo']}`}>{estrela} estrela{estrela <= 1 ? '' : 's'}</div>
			<BarraAvaliacao porcentagem={arredondarNumero(porcentagem)} />
			<div className={`${c['linha-barra-porcentagem']}`}>{porcentagem}%</div>
		</div>
	)
}

export const BarraAvaliacao = props => {
	const {
		porcentagem = 0,
	} = props;

	return (
		<div className={`${c['barra-wrap']}`}>
			<div className={`${c['barra-box']}`}>
				<div className={`${c['barra']}`} style={{width: `${porcentagem}%`}}></div>
			</div>
		</div>
	)
}

export default AvaliacoesBarra;