import React, { useContext, useEffect, useRef, useState } from 'react';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import c from './PortalAvaliacaoJob.module.scss';

// Componentes
import { Portal } from "@_cpnt/Portal/Portal/Portal";
import { CirculoNumero } from "@_cpnt/CirculoNumero/CirculoNumero";
import { Input } from "@_cpnt/Inputs/Input/Input";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { EstrelasAvaliacao } from "@_cpnt/EstrelasAvaliacao/EstrelasAvaliacao";
import { msg } from "@_func/cpnt";
import { camposInvalidos } from "@func/utils";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { api } from "@_api/api";
import { msgs } from "@_func/request";
import { PortalInfoMensagem } from "../PortalInfoMensagem/PortalInfoMensagem";

// Imagens

// Vídeos

const PortalAvaliacaoJob = props => {
	const [store, setStore] = useContext(StoreContext);
	const refInput = useRef(null);

	const {
		show,
		id,
		onClose,
		onSuccess,
		onError,
		x,
		closeOnEsc = false,
		portalClassName = c['container'],
		removerInfoPagamento,
	} = props;

	const [loading, setLoading] = useState(false);
	const [stateDia, setStateDia] = useState(false);
	const [stateAvaliacao, setStateAvaliacao] = useState(false);
	const [stateDescricao, setStateDescricao] = useState('');
	const [showPortalConfirmarFechar, setShowPortalConfirmarFechar] = useState(false);
	const [showPortalSucesso, setShowPortalSucesso] = useState(false);

	const numerosAvaliacoes = [{numero: 10}, {numero: 20}, {numero: 30}];

	useEffect(() => {
		if(show) {
			// Fix para fechar o popup do menu de aprovação
			refInput?.current?.click();
			setStateAvaliacao(false);
			setStateDescricao('');
		}
	}, [show])

	const enviarAvaliacao = async () => {

		if(loading) {
			msg.warning('Por favor, aguarde...');
			return;
		}

		var dadosEnviar = {
			id: id,
			stars: stateAvaliacao,
			comment: stateDescricao,
			payday: stateDia,
		};

		if(!removerInfoPagamento) {
			if(store.usuario.role !== 9) {
				if(camposInvalidos(dadosEnviar, {
					id: 'ID não identificado',
					payday: 'Qual será o dia do pagamento',
					stars: 'Quantas estrelas',
				}, [
					'id',
					'payday',
					'stars',
				])) return;
			} else {
				if(camposInvalidos(dadosEnviar, {
					stars: 'Quantas estrelas',
				}, [
					'stars',
				])) return;
			}
		} else {
			if(camposInvalidos(dadosEnviar, {
				stars: 'Quantas estrelas',
			}, [
				'stars',
			])) return;
		}

		setLoading(true);
		const ret = await api.tasks.approve.put(dadosEnviar);

		if(ret.status === 200) {
			// msg.success(<>Obrigado por sua avaliação!<br /><br />Seu job foi aprovado com sucesso.</>);
			if(!removerInfoPagamento) {
				setShowPortalSucesso(true);
			} else {
				msg.success('Avaliação enviada com sucesso');
			}
			onSuccess();
			onClose();
		} else {
			msgs([ret]);
		}
		setLoading(false);
	}

	const resetPortal = (ret) => {
		setStateDia(false);
		setStateAvaliacao(false);
		setStateDescricao('');
	}

	return (
		<>
			<Portal
				titulo={`Aprovação do job (ID: ${show})`}
				show={show}
				closeOnEsc={closeOnEsc}
				onClose={() => !removerInfoPagamento ? setShowPortalConfirmarFechar(true) : onClose()}
				classNameWrap={portalClassName}
				classNameX={c['x']}
				x={x}
			>
				<div className={`${c['conteudo']}`}>
					{!removerInfoPagamento ? (
						store.usuario.role !== 9 ? (
							<>
								<h2 className={`margin-t-0 ${c['texto-dia']}`}>Escolha o dia em que o profissional será pago no próximo mês</h2>
								<div className={`${c['circulos']}`}>
									{numerosAvaliacoes.map((val, key) => {
										return (
											<CirculoNumero
												key={key}
												numero={val.numero}
												className={`${c['circulo']} ${stateDia === val.numero ? c['active'] : ''}`}
												onClick={() => setStateDia(val.numero)}
												{...(stateDia === val.numero ? {active: true} : {})}
											/>
										)
									})}
								</div>
								<p className={`${c['texto-alerta']}`}>Lembre-se de que o pagamento deve ser realizado<br /><strong>5 dias antes para garantir a liberação na data escolhida.</strong></p>
							</>
						) : (
							<h2 className={`${c['texto-avalie']}`}>Avalie a qualidade do profissional</h2>
						)
					) : (
						<h2 className={`${c['texto-avalie']} margin-v-0`}>Avalie a qualidade do profissional</h2>
					)}
					<p className={`${c['texto-compartilhe']} bold`}>Quantas estrelas você avalia o profissional contratado?</p>
					<div className={`${c['box-estrelas']}`}>
						<EstrelasAvaliacao
							onClick={e => setStateAvaliacao(e)}
							value={stateAvaliacao}
						/>
					</div>
					<Input
						_={{
							type: 'textarea',
							placeholder: 'Escreva aqui seu comentário',
							value: stateDescricao,
							onChange: e => setStateDescricao(e.target.value),
							ref: refInput,
						}}
					/>
					<div className={`${c['btn']}`}>
						<Btn
							_={{
								value: !removerInfoPagamento ? 'Avaliar a aprovar' : 'Avaliar job',
								onClick: enviarAvaliacao,
							}}
							loading={loading}
						/>
					</div>
				</div>
			</Portal>
			<PortalConfirmacao
				titulo="Cancelar aprovação do job?"
				desc={<>Ao fechar essa janela, o job <strong className="color-red">não</strong> será aprovado. Deseja realmente fechar?</>}
				show={showPortalConfirmarFechar}
				onClose={() => setShowPortalConfirmarFechar(false)}
				onClickCancelar={() => setShowPortalConfirmarFechar(false)}
				onClickConfirmar={() => {
					setShowPortalConfirmarFechar(false);
					onClose();
					resetPortal();
				}}
			/>
			<PortalInfoMensagem
				show={showPortalSucesso}
				onClose={() => {
					setShowPortalSucesso(false);
					resetPortal();
				}}
				titulo="Pagamento aprovado"
				icone="sucesso"
				portalClassName="w-600"
				texto={
					<div className="align-center">
						<h3>Pagamento aprovado</h3>
						<div>Obrigado por sua avaliação! Seu job foi aprovado com sucesso.</div>
					</div>
				}
			/>
		</>
	);
};

export { PortalAvaliacaoJob };
