import React from 'react';

// Libs
import _ from 'lodash';
import { Link } from 'react-router-dom';

// Func
import { msg } from "@_func/cpnt";

// Config
import { EMAIL_CONTATO } from "@_config/config";

/*
** Verifica o retorno e exibe as mensagens, agrupa, etc.
** ================================================== */
export const msgs = (arrRetornoApi, exibirSucesso = false) => {

	var camposInvalidosRetorno = [];

	if(arrRetornoApi.length > 0) {
		arrRetornoApi.map((valueRequest) => {
			if(valueRequest.status >= 200 && valueRequest.status <= 299) {
				if(exibirSucesso) {
					msg.success(valueRequest.message);
				}
			} else if(valueRequest.status >= 400) {
				// Campos inválidos
				if(typeof(valueRequest.invalidFields) === 'object') {
					var msgAgrupadas = [];
					valueRequest.invalidFields.map((value, key) => {
						camposInvalidosRetorno.push([value.param]);
						msgAgrupadas.push(<div key={key} className={`${valueRequest.invalidFields.length === 1 ? '' : 'msgs-lista-item msgs-lista-item-error'}`}>{value.msg}</div>);
						return false;
					});
					msg.error(<div className="msgs-lista">{msgAgrupadas}</div>);
				} else if(valueRequest.status === 401) {
					msg.error(<div className="msgs-lista"><div>{valueRequest.error}</div><div className="margin-t-15">Caso o erro persista, tente deslogar e logar novamente.</div><div className="margin-t-15">Se preferir, <Link className="strong a underline" to="/logout">clique aqui</Link> para acessar a tela de login.</div></div>);
				} else if(valueRequest.error !== undefined) {
					msg.error(<div className="msgs-lista">{valueRequest.error}</div>);
				} else if(valueRequest.status >= 400 && valueRequest.status <= 499) {
					msg.error(<div className="msgs-lista">{valueRequest.message}</div>);
				} else if(valueRequest.status >= 500) {
					if(valueRequest.error) {
						msg.error(<div className="msgs-lista">{valueRequest.error}</div>);
					} else if(valueRequest.exception) {
						msg.error(<div className="msgs-lista">{valueRequest.exception}</div>);
					} else {
						msg.error(<div className="msgs-lista">Houve algum problema ao fazer a sua requisição para a API. Caso o erro persista, por favor no informe através do e-mail <strong>{EMAIL_CONTATO}.</strong></div>);
					}
				}
			} else if(valueRequest.status === 0) {
				msg.error(valueRequest.message);
			} else {
				msg.warning(valueRequest.error);
			}
		});
	}

	return camposInvalidosRetorno;
}

/*
** Trata retorno da API
** ================================================== */
export const apiObj = (ret, dataTipoDefault = []) => {
	if(!ret) {
		return {
			data: dataTipoDefault,
			metadata: {},
			_: null, // Reaproveitar o mesmo objeto para armazenar mais informações
		};
	}

	var retorno = {
		...ret,
		data: ret?.data,
		status: ret.status,
		msg: ret.msg,
		_: null,
	};

	if(!ret.msg) {
		retorno = {
			...retorno,
			...msgStatus(ret),
		};
	}

	return retorno;
}

/*
** Verifica o retorno do get da API, trata a mensagem, e verifica o status
** ================================================== */
export const msgStatus = (ret, extraParam = {}) => {
	var retorno = {};

	if(ret && typeof(ret) === 'object') {
		if(ret.status === 403) {
			retorno = {
				status: ret.status,
				msg: ret.error || 'Você não tem permissão para acessar essas informações.',
			}
		} else if(ret.status === 400) {
			retorno = {
				status: ret.status,
				msg: ret.error || 'Nenhum conteúdo encontrado',
			}
		} else if(ret.status !== 200) {
			var temMsg = _.get(ret, 'invalidFields[0].msg');
			if(temMsg && temMsg !== '') {
				retorno = {
					status: ret.status,
					msg: temMsg,
				};
			} else if(ret.msg) {
				retorno = {
					status: ret.status,
					msg: ret.msg,
				};
			} else {
				retorno = {
					status: ret.status,
					msg: ret.statusText,
				};
			}
		} else if(ret.status === 200) {
			retorno = {
				status: ret.status,
				msg: ret.statusText,
			};
		}
	} else {
		retorno = {
			status: 0,
			msg: 'Não foi possível fazer a requisição.',
		};
	}

	return {
		...retorno,
		...extraParam,
	};
}
