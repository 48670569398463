import React from 'react';

// Componentes
import Disponibilidade from '../Disponibilidade/Disponibilidade';
import ItemFotoDesc from '../ItemFotoDesc/ItemFotoDesc';

// Funções
import { caminhoFoto } from '@func/utils';

const Equipe = props => {
	const {
		className = '',
		wrap = '',
		equipe,
	} = props;

	return (
		<div className={`cpnt-equipe ${wrap}`}>
			<div className={`cpnt-equipe-box ${className}`}>
				<div>
					<h3 className="margin-v-0 inline-flex flex-center-v">
						{equipe.restricted === 1 && <Disponibilidade textoIndisponivel="Restrito" min status={0} className="margin-r-7 margin-b--3" />}
						<span>{equipe.name} <span className="color-1">(ID: {equipe.id})</span></span>
					</h3>
					<div className="fs-16 color-1 margin-t-2">{equipe.client_name}</div>

					{equipe.team && equipe.team.length ? (
						<div>
							<h3>Time <span className="color-1 fw-lighter">({equipe.team.length})</span></h3>
							<div className="wrap-5">
								{equipe.team.map((value, index) => {
									return (
										<ItemFotoDesc
											className="margin-2"
											key={index}
											foto={caminhoFoto(value.user_photo).url}
											titulo={value.user_name}
											subtitulo={value.area_name || value.role}
										/>
									)
								})}
							</div>
						</div>
					) : (
						equipe.team !== false ? (
							<div>
								<h3 className="margin-b-0">Time</h3>
								<div className="fs-16 color-1">Nenhum usuário selecionado para o time.</div>
							</div>
						) : null
					)}

					{equipe.teamFreelancers && equipe.teamFreelancers.length ? (
						<div className="padding-t-15">
							<h3>Profissionais Freelancers <span className="color-1 fw-lighter">({equipe.teamFreelancers.length})</span></h3>
							<div className="wrap-5">
								{equipe.teamFreelancers.map((value, index) => {
									return (
										<ItemFotoDesc
											className="margin-2"
											key={index}
											foto={caminhoFoto(value.photo).url}
											titulo={value.freelancer_name || value.name}
											subtitulo={value.area_name}
										/>
									)
								})}
							</div>
						</div>
					) : (
						equipe.teamFreelancers !== false ? (
							<div className="padding-t-15">
								<h3 className="margin-b-0">Profissionais Freelancers</h3>
								<div className="fs-16 color-1">Nenhum profissional selecionado para o time.</div>
							</div>
						) : null
					)}

				</div>
			</div>
		</div>
	);
};

export default Equipe;