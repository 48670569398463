import React from 'react';

// css
import c from './PortalInfoMensagem.module.scss';

// Componentes
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import Info from "@_cpnt/Info/Info";
import { Portal } from "@_cpnt/Portal/Portal/Portal";

// Imagens
import iconeAlerta from '@_img/default/icone-alerta.png';
import iconeErro from '@_img/default/icone-erro.png';
import iconeInfo from '@_img/default/icone-info.png';
import iconeSucesso from '@_img/default/icone-sucesso.png';

const PortalInfoMensagem = props => {

	const {
		show,
		onClose,
		x,
		closeOnEsc = false,
		portalClassName = c['container'],
		titulo,
		subtitulo,
		texto,
		footer = true,
		icone = 'sucesso',
		textoBtnFechar = 'Fechar',
	} = props;

	return (
		<Portal
			titulo={titulo}
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			classNameWrap={portalClassName}
			x={x}
		>
			<Info icone={icone} />
			{subtitulo && <h3>{subtitulo}</h3>}
			{texto && <div>{texto}</div>}
			{footer ? (
				footer === true ? (
					<div className="form-bottom btns flex-end margin-t-25">
						<Btn
							_={{
								value: textoBtnFechar,
								onClick: onClose,
							}}
						/>
					</div>
				) : footer
			) : null}
		</Portal>
	);
};

export { PortalInfoMensagem };
