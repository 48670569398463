import React, { useEffect, useMemo, useRef } from 'react';
import JoditEditorDefault from 'jodit-react';

// CSS
import './JoditEditor.css';

const JoditEditor = props => {
	const editorRef = useRef(null);
	const valueRef = useRef(props.value || '');

	const {
		value = '',
		className = '',
		wrap = '',
		onBlur = () => null,
		onChange = () => null,
		_ = {},
	} = props;

	const buttons = 'undo,redo,|,eraser,copyformat,spellcheck,|,bold,italic,underline,strikethrough,brush,|,paragraph,fontsize,superscript,subscript,link,|,left,center,right,|,ul,ol,|,table,indent,outdent,|,paste,preview,print'; //,fullsize

	// https://www.xdsoft.net/jodit/docs/classes/config.Config.html
	const editorConfig = useMemo( () => ({
			readonly: false, // all options from https://xdsoft.net/jodit/doc/,
			placeholder: '',
			spellcheck: true,
			language: 'pt_br',
			defaultMode: '1',
			minHeight: 400,
			maxHeight: -1,
			buttons: buttons,
			buttonsMD: buttons,
			buttonsSM: buttons,
			buttonsXS: buttons,
			defaultActionOnPaste: 'insert_as_html',
			defaultActionOnPasteFromWord: 'insert_as_html',
			askBeforePasteFromWord: false,
			askBeforePasteHTML: false,
			// events: {
			// 	afterPaste: e => {
			// 		var conteudo = e.target.innerHTML.replace(/(^|;)\s*font-[^;]+/g,"");
			// 		conteudo = conteudo.replace(/style="(.*?)"/g,"");
			// 		conteudo = conteudo.replace(/dir="(.*?)"/g,"");
			// 		conteudo = conteudo.replace(/aria-level="(.*?)"/g,"");
			// 		conteudo = conteudo.replace(/role="(.*?)"/g,"");
			// 		conteudo = conteudo.replace(/<span[^>]*>|<\/span>$/g, '');
			// 		conteudo = conteudo.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g,' ');
			// 		onBlurJodit(conteudo);
			// 	}
			// },
			..._,
		}), []
	);

	const onBlurJodit = e => {
		onChange(e);
	}

	return (
		<div className={`cpnt-editor ${wrap}`}>
			<div className={`cpnt-editor-box ${className}`}>
				<JoditEditorDefault
					ref={editorRef}
					value={value}
					config={editorConfig}
					tabIndex={1}
					onBlur={onBlurJodit}
					onChange={e => valueRef.current = e}
				/>
			</div>
		</div>
	)
};

export { JoditEditor };