import React, { useState } from 'react';

// CSS
import './ProcessoSeletivoInfoHistorico.css';

// Imagens
import imgIconeCheck from '@svg/solid/circle-check.svg';
import imgIconeUncheck from '@svg/solid/circle-x.svg';

// Componentes
import FotosAgrupadas from '@cpnt/FotosAgrupadas/FotosAgrupadas';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';

// Funções
import Button2 from '@cpnt/Button2/Button2';
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";
import { PortalPerfilUsuario } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilUsuario";
import Portal from '@cpnt/Portal/Portal';
import TextoIconeLink from '@cpnt/TextoIconeLink/TextoIconeLink';
import { caminhoFoto, dataPorExtenso, formatarDinheiro, htmlToJsx } from '@func/utils';

const ProcessoSeletivoInfoHistorico = props => {
	const {
		className = '',
		wrap = '',
		stateDados,

		after,
	} = props;

	/*
	** Portais
	** ================================================== */
	const [showPortalInformacoesFreelancers, setShowPortalInformacoesFreelancers] = useState(false);
	const [showPortalPerfilUsuario, setShowPortalPerfilUsuario] = useState(false);
	const [showPortalPerfilFreelancer, setShowPortalPerfilFreelancer] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateFreelancers, setStateFreelancers] = useState([]);
	const [statePerfilIdUsuario, setStatePerfilIdUsuario] = useState(false);
	const [statePerfilIdFreelancer, setStatePerfilIdFreelancer] = useState(false);


	return (
		<>
			<div className={`cpnt-procsele-infohistorico ${wrap}`}>
				<div className={`cpnt-procsele-infohistorico-box ${className}`}>

					<div>
						<div className="flex flex-center-v">
							<h3 className="margin-0">{stateDados.title}</h3>
						</div>
						<div className="td-desc">ID da proposta: {stateDados.id_briefing}</div>
						<div className="td-desc">ID da edição: {stateDados.id}</div>
					</div>

					<div className="flex flex-center-v margin-t-25">
						<div>
							<div className="fs-14 margin-b-5 color-1">Edição feita por</div>
							<ItemFotoDesc
								titulo={stateDados.edited_name}
								subtitulo={stateDados.edited_role_company}
								foto={caminhoFoto(stateDados.edited_photo).url}
								onClick={() => {
									setStatePerfilIdUsuario(stateDados.id_edited);
									setShowPortalPerfilUsuario(true);
								}}
								className="cursor-pointer"
							/>
						</div>
						<div className="margin-l-30">
							<div className="fs-14 margin-b-5 color-1">Responsável</div>
							<ItemFotoDesc
								titulo={stateDados.responsible_name}
								subtitulo={stateDados.responsible_role_company}
								foto={caminhoFoto(stateDados.responsible_photo).url}
								onClick={() => {
									setStatePerfilIdUsuario(stateDados.id_responsible);
									setShowPortalPerfilUsuario(true);
								}}
								className="cursor-pointer"
							/>
						</div>
						<div className="margin-l-30">
							<div className="fs-14 margin-b-5 color-1">Novos profissionais selecionados</div>
							{stateDados.new_freelancers && stateDados.new_freelancers.length ? (
								<FotosAgrupadas
									fotos={[...stateDados.new_freelancers.map((item, key) => {
										return {
											foto: caminhoFoto(item.user_photo).url,
											desc: item.freelancer_name,
										}
									})]}
									onClick={() => {
										setStateFreelancers(stateDados.new_freelancers);
										setShowPortalInformacoesFreelancers(true);
									}}
									className="cursor-pointer"
									qtd={6}
								/>
							) : (
								<div>Não foram adicionados novos freelancers nessa edição.</div>
							)}
						</div>
					</div>

					<div className="margin-t-20">
						<div className="flex flex-center-v">
							<h3 className="margin-0">Descrição do briefing</h3>
						</div>
						<div className="cpnt-detalhesprojeto-deschtml box-txt scroll scroll-alt margin-t-10">
							{stateDados.text && stateDados.text !== '' ? htmlToJsx(stateDados.text) : '(Nenhuma descrição adicionada)'}
						</div>
					</div>

 					<div className="flex margin-t-20">
						<div>
							<div className="flex flex-center-v">
								<h3 className="margin-0">Data da edição</h3>
							</div>
							<div>{dataPorExtenso(stateDados.created_at, true, 'há ', 'Hoje às ', '')}</div>
						</div>
						<div className="margin-l-50">
							<div className="flex flex-center-v">
								<h3 className="margin-0">Budget</h3>
							</div>
							<div>{formatarDinheiro(stateDados.budget, 'R$ ')}</div>
						</div>
					</div>

					<div className="margin-t-20">
						<div className="inline-flex flex-center-v">
							<h3 className="margin-0">Motivo da edição</h3>
						</div>
						<div>{stateDados.reason || '(não informado)'}</div>
					</div>

					<div className="margin-t-25">
						<div className="flex flex-center-v">
							<h3 className="margin-0">Notificações</h3>
						</div>
						<div className="margin-t-10">
							<div>
								<TextoIconeLink
									texto="Novos profissionais selecionados"
									icone={stateDados.notify_new_freelancer ? imgIconeCheck : imgIconeUncheck}
									iconeCor={stateDados.notify_new_freelancer ? '#229d3e' : '#CCC'}
									iconeTamanho="14px"
								/>
							</div>
							<div>
								<TextoIconeLink
									texto="Novo responsável selecionado"
									icone={stateDados.notify_responsible ? imgIconeCheck : imgIconeUncheck}
									iconeCor={stateDados.notify_responsible ? '#229d3e' : '#CCC'}
									iconeTamanho="14px"
								/>
							</div>
							<div>
								<TextoIconeLink
									texto="Profissionais anteriores a edição"
									icone={stateDados.notify_old_freelancer ? imgIconeCheck : imgIconeUncheck}
									iconeCor={stateDados.notify_old_freelancer ? '#229d3e' : '#CCC'}
									iconeTamanho="14px"
								/>
							</div>
						</div>
					</div>
					{after}
				</div>
			</div>

			{/* Portal - Info dos selecionados */}
			<Portal
				titulo="Profissionais adicionados nessa edição"
				show={showPortalInformacoesFreelancers}
				onClose={() => {
					setShowPortalInformacoesFreelancers(false);
				}}
				className="w-750 box-txt"
			>
				<div className="wrap-5">
					<div className="flex flex-wrap margin-t-25">
						{!stateFreelancers.length ? (
							<div>Nenhum profissional adicionado.</div>
						) : (
							<div>
								<div className="margin-t--10 padding-b-15 margin-h-5">Total: {stateFreelancers.length}</div>
								{stateFreelancers.map((val, key) => {
									return (
										<ItemFotoDesc
											key={key}
											titulo={val.freelancer_name || '(Nome não informado)'}
											subtitulo={val.freelancer_title || 'Área não informada'}
											foto={caminhoFoto(val.user_photo).url}
											onClick={() => {
												setStatePerfilIdFreelancer(val.id_freelancer);
												setShowPortalPerfilFreelancer(true);
											}}
											className="margin-5 cursor-pointer"
										/>
									)
								})}
							</div>
						)}
					</div>
				</div>

				<div className="flex flex-end margin-t-30">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => {
								setShowPortalInformacoesFreelancers(false);
							}
						}}
					/>
				</div>
			</Portal>

			<PortalPerfilFreelancer
				id={statePerfilIdFreelancer}
				show={showPortalPerfilFreelancer}
				onClose={e => {
					setShowPortalPerfilFreelancer(false);
				}}
			/>

			<PortalPerfilUsuario
				id={statePerfilIdUsuario}
				show={showPortalPerfilUsuario}
				onClose={e => {
					setShowPortalPerfilUsuario(false);
				}}
			/>

		</>
	);
};

export default ProcessoSeletivoInfoHistorico;