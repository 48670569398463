import React from 'react';
import { toast as msg } from 'react-toastify';

// Imagens
import imgIconeEditar from '@svg/regular/pen.svg';

// Componentes
import Button2 from '@cpnt/Button2/Button2';
import Icone from '@cpnt/Icone/Icone';
import ItemFotoDesc from '@cpnt/ItemFotoDesc/ItemFotoDesc';
import { Select2 } from '@cpnt/Select2/Select2';

// Funções
import { getAllUsuariosLocal, removerUsuarioLocal } from '@func/selecaoUsuariosBriefings';

const ListaLocalProfissionaisSelecionados = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		stateListaAtual,
		setStateListaAtual,
		stateSelecionados,
		setStateSelecionados,
		stateListas,
		after,
		onClickEditarLista,
		onClickItemLista,
	} = props;

	return (
		<>
			<div className={`cpnt-listalocprofsel ${wrap} ${classNameWrap}`}>
				<div className={`cpnt-listalocprofsel-box ${className}`}>
					<div className="flex flex-center-v">
						<div className="flex-1">
							<Select2
								_={{
									value: stateListaAtual,
									options: stateListas,
									onChange: e => {
										setStateListaAtual(e.label);
										setStateSelecionados(getAllUsuariosLocal(e.label));
									}
								}}
							/>
						</div>
						<div className="margin-l-15">
							{stateListaAtual === 'Lista padrão' ? (
								<Button2
									_={{
										value: <Icone imagem={imgIconeEditar} cor="#FFF" tamanho="16px" wrap="padding-v-3 padding-h-1" />,
										onClick: e => msg.warning('Não é possível editar a lista padrão.')
									}}
									wrap="opct-4"
								/>
							) : (
								<Button2
									_={{
										value: <Icone imagem={imgIconeEditar} cor="#FFF" tamanho="16px" wrap="padding-v-3 padding-h-1" />,
										onClick: onClickEditarLista,
									}}
								/>
							)}
						</div>
					</div>
					<div className="padding-t-20">
						{!stateSelecionados.length ? (
							<div className="msg">Nenhum profissional selecionado neste lista.</div>
						) : (
							<div>
								<div className="fs-14 padding-b-15">Profissionais selecionados <strong>{stateSelecionados.length}</strong></div>
								<div className="wrap-5">
									{stateSelecionados.map((val, key) => {
										return (
											<ItemFotoDesc
												key={key}
												id={val.id}
												email={val.email}
												titulo={val.nome}
												subtitulo={val.segmento}
												foto={val.foto}
												onClick={() => {
													onClickItemLista(val);
												}}
												className="margin-5 cursor-pointer"
												onClose={() => {
													var acao = removerUsuarioLocal(val.id, stateListaAtual);
													if(acao) {
														setStateSelecionados(getAllUsuariosLocal(stateListaAtual));
													}
												}}
											/>
										)
									})}
								</div>
							</div>
						)}
					</div>

				</div>
			</div>
			{after}
		</>
	);
};

export { ListaLocalProfissionaisSelecionados };
