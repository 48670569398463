import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

// Store
import StoreContext from '@/_/contexts/StoreContext';

// css
import './Perfil.css';
import c from './Perfil.module.scss';

// Imagens
import imgIconeErro from '@_img/default/icone-erro.png';
import imgIconeSucesso from '@_img/default/icone-sucesso.png';
import imgUserPic from '@img/userpic.png';
import imgIconePerfil from '@svg/duotone/id-card.svg';
import imgIconeEditar from '@svg/regular/pen-to-square.svg';
import imgIconeCheck from '@svg/solid/check.svg';

// Componentes
import { BYPASS_PERFIL_STEPS, DEV, URL } from "@_config/config";
import { isLogado, salvarSessaoLocal } from "@_func/auth";
import { apiEditarAceites, apiEditarCertificados, apiEditarCompetencias, apiEditarExperiencias, apiEditarFormacoes, apiEditarHabilidades, apiEditarIdiomas, apiEditarPortfolios, apiEditarPremios, apiEditarSegmentos, apiGetAreas, apiGetCertificados, apiGetCompetenciasChecked, apiGetDadosBancarios, apiGetDadosEmpresa, apiGetExperiencias, apiGetFormacoes, apiGetHabilidades, apiGetHabilidadesChecked, apiGetIdiomasChecked, apiGetObjetivosChecked, apiGetPaises, apiGetPortfolios, apiGetPremios, apiGetRedesSociaisFreelancer, apiGetSegmentosChecked, apiGetSenioridades } from "@api/_functions/geral";
import apiFreelancer from "@api/freelancer/freelancer";
import { api } from "@_api/api";
import { pixExibicao } from "@config/pix";
import { BoxWrap2 } from "@cpnt/BoxWrap2/BoxWrap2";
import { BtnAdd } from "@cpnt/BtnAdd/BtnAdd";
import { BtnIconeDesc } from "@cpnt/BtnIconeDesc/BtnIconeDesc";
import Button2 from "@cpnt/Button2/Button2";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Icone from "@cpnt/Icone/Icone";
import ImgTituloDesc from "@cpnt/ImgTituloDesc/ImgTituloDesc";
import Info from "@cpnt/Info/Info";
import LinhaDesc from "@cpnt/LinhaDesc/LinhaDesc";
import LinhaItem from "@cpnt/LinhaItem/LinhaItem";
import { ListaComCheckbox2 } from "@cpnt/ListaComCheckbox2/ListaComCheckbox2";
import { ListaSecao } from "@cpnt/ListaSecao/ListaSecao";
import Loading from "@cpnt/Loading/Loading";
import { PtFoto } from "@cpnt/Partes/PtFoto/PtFoto";
import { PtNotificacoes } from "@cpnt/Partes/PtNotificacao/PtNotificacoes";
import { PtVideo } from "@cpnt/Partes/PtVideo/PtVideo";
import { PortalCartaApresentacaoFormEdicao } from "@cpnt/Portais/PortaisCartasApresentacao/PortalCartaApresentacaoFormEdicao/PortalCartaApresentacaoFormEdicao";
import { PortalDadosBancarioFormEdicao } from "@cpnt/Portais/PortaisDadosBancarios/PortalDadosBancarioFormEdicao/PortalDadosBancarioFormEdicao";
import { PortalEmailAcessoFormEdicao } from "@cpnt/Portais/PortaisDadosDeAcesso/PortalEmailAcessoFormEdicao/PortalEmailAcessoFormEdicao";
import { PortalSenhaAcessoFormEdicao } from "@cpnt/Portais/PortaisDadosDeAcesso/PortalSenhaAcessoFormEdicao/PortalSenhaAcessoFormEdicao";
import { PortalDadosEmpresaFormEdicao } from "@cpnt/Portais/PortaisDadosEmpresa/PortalDadosEmpresaFormEdicao/PortalDadosEmpresaFormEdicao";
import { PortalDisponibilidadeJobs } from "@cpnt/Portais/PortaisDadosPerfil/PortalDisponibilidadeJobs/PortalDisponibilidadeJobs";
import { PortalDisponibilidadeRemuneracaoFormEdicao } from "@cpnt/Portais/PortaisDadosPerfil/PortalDisponibilidadeRemuneracaoFormEdicao/PortalDisponibilidadeRemuneracaoFormEdicao";
import { PortalDadosPessoaisFormEdicao } from "@cpnt/Portais/PortaisDadosPessoais/PortalDadosPessoaisFormEdicao/PortalDadosPessoaisFormEdicao";
import { PortalEnderecoFormEdicao } from "@cpnt/Portais/PortaisEndereco/PortalEnderecoFormEdicao/PortalEnderecoFormEdicao";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { PortalFoto } from "@cpnt/Portais/PortaisGerais/PortalFoto/PortalFoto";
import { PortalPerfilFreelancer } from "@cpnt/Portais/PortaisGerais/PortalPerfil/PortalPerfilFreelancer";
import { PortalObjetivoFormEdicao } from "@cpnt/Portais/PortaisObjetivos/PortalObjetivoFormEdicao/PortalObjetivoFormEdicao";
import Portal from "@cpnt/Portal/Portal";
import Radio2 from "@cpnt/Radio2/Radio2";
import { SecaoCertificados } from "@cpnt/SecaoCertificados/SecaoCertificados";
import { SecaoExperiencias } from "@cpnt/SecaoExperiencias/SecaoExperiencias";
import { SecaoFormacoes } from "@cpnt/SecaoFormacoes/SecaoFormacoes";
import { SecaoPortfolios } from "@cpnt/SecaoPortfolios/SecaoPortfolios";
import { SecaoPremios } from "@cpnt/SecaoPremios/SecaoPremios";
import { SelecaoIdiomasNiveis } from "@cpnt/SelecaoIdiomasNiveis/SelecaoIdiomasNiveis";
import { Select2 } from "@cpnt/Select2/Select2";
import Tip from "@cpnt/Tip/Tip";
import Toggler from "@cpnt/Toggler/Toggler";
import { arrGetCheckeds, blobToFile, caminhoFoto, dataApiParaBr, dataHaQuantoTempo, disponibilidadeFreelancer, extensaoPorMimeType, extensaoString, formatarCNPJ, formatarCPF, formatarDinheiro, getKey, msg, senioridadeFreelancer, validarCnpj, validarCpf, validarDataAPI } from "@func/utils";
import { initFuncExec, setGet } from "@route/routeUtils";
import imgIconeCopy from './copy-icon.svg';
import { LINKS_SECOES_SIDEBAR_FREELANCER } from "./perfilObj";

import { hash } from "@_func/generate";
import { msgs } from "@_func/request";
import { SecaoRedesSociais } from "@cpnt/SecaoRedesSociais/SecaoRedesSociais";
import apiFreelancerSocial from "@api/freelancerSocial/freelancerSocial";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { PortalExclusaoPerfilProfissional } from "@_cpnt/Portais/PortalExclusaoPerfilProfissional/PortalExclusaoPerfilProfissional";

const Perfil = props => {

	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const navigate = useNavigate();
	const getFreelancerRef = useRef(0);
	const permissaoRef = useRef(false);

	/*
	** Funções para executar ao iniciar e sempre que alterar a URL
	** =========================================================== */
	const initFunc = async (getsAtualizados = getsDefault) => {

		var dadosFreelancer = stateDados;

		if(getFreelancerRef.current === 0) {
			setLoadingGetDados(true);
			const retGetDados = await apiFreelancer.getOne.get(store.usuario.id_freelancer);
			// const retGetDados = {};
			_setStateDados(retGetDados);
			if(retGetDados.status === 200) {
				setStateDados(retGetDados.data);
				dadosFreelancer = retGetDados.data;
				permissaoRef.current = true;
				getFreelancerRef.current++;
				setStatePerfilPublico(retGetDados.data.public);
			} else {
				msgs([retGetDados]);
			}
			setLoadingGetDados(false);
		}

		if(permissaoRef.current) {
			var idFreelancer = dadosFreelancer.id;
			initFuncExec([
				{
					func: apiGetPaises,
					param: {setLoadingGetPaises, setStatePaises, showMsg: false},
					condicao: getsAtualizados.secao === 'endereco' || getsAtualizados.secao === 'dados',
				},
				{
					func: apiGetDadosEmpresa,
					param: {idFreelancer, setLoadingGetDadosEmpresa, setStateDadosEmpresa, showMsg: false},
					condicao: getsAtualizados.secao === 'dados-empresa',
				},
				{
					func: apiGetDadosBancarios,
					param: {idFreelancer, setLoadingGetDadosBancarios, setStateDadosBancarios, showMsg: false},
					condicao: getsAtualizados.secao === 'dados-bancarios',
				},
				{
					func: getsHabilidades,
					param: dadosFreelancer,
					condicao: getsAtualizados.secao === 'habilidades',
				},
				{
					func: apiGetIdiomasChecked,
					param: {idFreelancer, setLoadingGetIdiomas, setStateIdiomas, setStateIdiomasNiveis, showMsg: false},
					condicao: getsAtualizados.secao === 'idiomas',
				},
				{
					func: apiGetSegmentosChecked,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetSegmentos, setStateSegmentos, showMsg: false},
					condicao: getsAtualizados.secao === 'segmentos',
				},
				{
					func: apiGetCompetenciasChecked,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetCompetencias, setStateCompetencias, showMsg: false},
					condicao: getsAtualizados.secao === 'competencias',
				},
				{
					func: apiGetExperiencias,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetExperiencias, setStateExperiencias, showMsg: false},
					condicao: getsAtualizados.secao === 'experiencias',
				},
				{
					func: apiGetFormacoes,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetFormacoes, setStateFormacoes, showMsg: false},
					condicao: getsAtualizados.secao === 'formacoes',
				},
				{
					func: apiGetPortfolios,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetPortfolios, setStatePortfolios, showMsg: false},
					condicao: getsAtualizados.secao === 'portfolios',
				},
				{
					func: apiGetCertificados,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetCertificados, setStateCertificados, showMsg: false},
					condicao: getsAtualizados.secao === 'certificados',
				},
				{
					func: apiGetPremios,
					param: {idFreelancer, idArea: dadosFreelancer.id_area, setLoadingGetPremios, setStatePremios, showMsg: false},
					condicao: getsAtualizados.secao === 'premios',
				},
				{
					func: definirDadosAceites,
					param: dadosFreelancer,
					condicao: getsAtualizados.secao === 'aceites',
				},
				{
					func: getRedesSociais,
					param: dadosFreelancer,
					condicao: getsAtualizados.secao === 'redes-sociais',
				},
			], init, location, stateLocation);
		}
	}

	/*
	** @stateLocation
	** Guarda o estado do GET
	**
	** @init
	** Ao iniciar na primeira vez, define como true
	**
	** @getsDefault
	** Tem todos os states iniciais dos campos dos filtros
	**
	** IMPORTATE: não esquecer nem um campo de filtro
	**
	** ================================================== */
	const [init, setInit] = useState(false);
	const [stateLocation, setStateLocation] = useState(false);
	const getsDefault = {
		secao: '',
	};
	const [stateGet, setStateGet] = useState(getsDefault);

	/*
	** getsDefault: parametros get iniciais
	** stateLocation: guarda o state atual, no próximo get fica com o valor antigo
	** funcExec: Função a ser executada no início [useEffect] e também ao alterar o GET
	** ================================================== */
	const _setGet = {
		getsDefault: getsDefault,
		setStateGet: setStateGet,
		stateLocation: stateLocation,
		setStateLocation: setStateLocation,
		location: location,
		funcExec: initFunc,
	};

	/*
	** INIT - define os GETS
	** ================================================== */
	useEffect(() => {
		setGet(_setGet);
		if(!init) setInit(true);
	}, []);

	/*
	** LOCATION - define os GETS ao alterar o location
	** ================================================== */
	useEffect(() => {
		if(init) {
			setGet(_setGet);
		}
	}, [location.search]);

	/*
	** Portais
	** ================================================== */
	const [showPortalObjetivos, setShowPortalObjetivos] = useState(false);
	const [showPortalCartaApresentacao, setShowPortalCartaApresentacao] = useState(false);
	const [showPortalDadosEmpresa, setShowPortalDadosEmpresa] = useState(false);
	const [showPortalDadosPessoais, setShowPortalDadosPessoais] = useState(false);
	const [showPortalDadosBancarios, setShowPortalDadosBancarios] = useState(false);
	const [showPortalEndereco, setShowPortalEndereco] = useState(false);
	const [showPortalDadosJobs, setShowPortalDadosJobs] = useState(false);
	const [showPortalEmail, setShowPortalEmail] = useState(false);
	const [showPortalSenha, setShowPortalSenha] = useState(false);

	const [showPortalHabilidades, setShowPortalHabilidades] = useState(false);
	const [showPortalIdiomas, setShowPortalIdiomas] = useState(false);
	const [showPortalSegmentos, setShowPortalSegmentos] = useState(false);
	const [showPortalCompetencias, setShowPortalCompetencias] = useState(false);
	const [showPortalPerfil, setShowPortalPerfil] = useState(false);
	const [showPortalFoto, setShowPortalFoto] = useState(false);
	const [showPortalDisponibilidade, setShowPortalDisponibilidade] = useState(false);
	const [showPortalConfirmacaoExclusaoPerfil, setShowPortalConfirmacaoExclusaoPerfil] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loadingGetDados, setLoadingGetDados] = useState(true);
	const [loadingEditarDados, setLoadingEditarDados] = useState(false);

	const [loadingGetObjetivos, setLoadingGetObjetivos] = useState(false);
	const [loadingGetHabilidades, setLoadingGetHabilidades] = useState(false);
	const [loadingGetIdiomas, setLoadingGetIdiomas] = useState(false);
	const [loadingGetSegmentos, setLoadingGetSegmentos] = useState(false);
	const [loadingGetCompetencias, setLoadingGetCompetencias] = useState(false);
	const [loadingGetDadosEmpresa, setLoadingGetDadosEmpresa] = useState(false);
	const [loadingGetDadosBancarios, setLoadingGetDadosBancarios] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);
	const [loadingGetSenioridades, setLoadingGetSenioridades] = useState(false);
	const [loadingGetAreas, setLoadingGetAreas] = useState(false);

	const [loadingGetExperiencias, setLoadingGetExperiencias] = useState(false);
	const [loadingGetFormacoes, setLoadingGetFormacoes] = useState(false);
	const [loadingGetCertificados, setLoadingGetCertificados] = useState(false);
	const [loadingGetPremios, setLoadingGetPremios] = useState(false);
	const [loadingGetPortfolios, setLoadingGetPortfolios] = useState(false);
	const [loadingGetRedesSociaisFreelancer, setLoadingGetRedesSociaisFreelancer] = useState(false);

	const [loadingEditarHabilidades, setLoadingEditarHabilidades] = useState(false);
	const [loadingEditarIdiomas, setLoadingEditarIdiomas] = useState(false);
	const [loadingEditarSegmentos, setLoadingEditarSegmentos] = useState(false);
	const [loadingEditarCompetencias, setLoadingEditarCompetencias] = useState(false);
	const [loadingEditarExperiencias, setLoadingEditarExperiencias] = useState(false);
	const [loadingEditarFormacoes, setLoadingEditarFormacoes] = useState(false);
	const [loadingEditarPortfolios, setLoadingEditarPortfolios] = useState(false);
	const [loadingEditarCertificados, setLoadingEditarCertificados] = useState(false);
	const [loadingEditarPremios, setLoadingEditarPremios] = useState(false);
	const [loadingEditarFoto, setLoadingEditarFoto] = useState(false);
	const [loadingEditarVideo, setLoadingEditarVideo] = useState(false);
	const [loadingEditarRedesSociais, setLoadingEditarRedesSociais] = useState(false);

	const [statePerfilPublico, setStatePerfilPublico] = useState(false);
	const [showPortalConfirmacaoPerfilPublico, setShowPortalConfirmacaoPerfilPublico] = useState(false);
	const [loadingAlterarPerfilPublico, setLoadingAlterarPerfilPublico] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({
		// Passo 1
		id: store.usuario.id_freelancer,
		name: '',
		birth_date: '',
		phone: '',
		cpf_cnpj: '',
		id_country: 33, // 33 => Brasil

		zip_code: '',
		street: '',
		number: '',
		complement: '',
		district: '',
		city: '',
		state: '',

		// Passo 3
		availability: '',
		hours_per_week: '',
		price: '',

		// Passo 4
		title: '',
		id_area: null,
		seniority: '',

		// Passo 5
		description: '',

		// Passo 7
		accept_date_payment: true,

		// novo
		public: 0,
	});
	const [_stateDados, _setStateDados] = useState({});

	const [stateHabilidades, setStateHabilidades] = useState([]);
	const [stateIdiomas, setStateIdiomas] = useState([]);
	const [stateIdiomasNiveis, setStateIdiomasNiveis] = useState([]);
	const [stateSegmentos, setStateSegmentos] = useState([]);
	const [stateCompetencias, setStateCompetencias] = useState([]);
	const [stateDadosEmpresa, setStateDadosEmpresa] = useState({});
	const [stateDadosBancarios, setStateDadosBancarios] = useState({});
	const [statePaises, setStatePaises] = useState([]);
	const [stateVideoUrl, setStateVideoUrl] = useState(false);
	const [stateFotoCapturada, setStateFotoCapturada] = useState(false);

	const [stateExperiencias, setStateExperiencias] = useState([]);
	const [stateFormacoes, setStateFormacoes] = useState([]);
	const [stateCertificados, setStateCertificados] = useState([]);
	const [statePremios, setStatePremios] = useState([]);
	const [statePortfolios, setStatePortfolios] = useState([]);
	const [stateRedesSociaisFreelancer, setStateRedesSociaisFreelancer] = useState([]);

	const [stateSenioridades, setStateSenioridades] = useState([]);
	const [stateAreas, setStateAreas] = useState([]);

	// Temp (reseta conforme muda de tela)
	const [stateDadosTempAceites, setStateDadosTempAceites] = useState({
		accept_jobs_mail: 0,
		accept_jobs_whatsapp: 0,
		accept_newsletter: 0,
	});
	const [stateDadosTempHabilidades, setStateDadosTempHabilidades] = useState({
		id_area: '',
		seniority: '',
	});

	/*
	** Salvar vídeo
	** ================================================== */
	const editarVideo = async () => {

		if(loadingEditarVideo) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(!stateVideoUrl || stateVideoUrl === '') {
			msg.info('Nenhuma ação necessária.');
			return;
		};

		var video = false;

		try {
			video = await fetch(stateVideoUrl).then(r => r.blob());
		} catch (error) {
			msg.error(
				<>
					<div>Não foi possível identificar o conteúdo do seu vídeo. Selecione outro vídeo ou com formato diferente.</div>
					<div className="margin-t-10">Caso o erro persista, remova o vídeo e finalize seu cadastro. Depois tente enviar novamente através do seu perfil.</div>
				</>, {
				autoClose: 15000
			});
			return;
		}

		if(!video.name) {
			video = blobToFile(video, `video.${extensaoPorMimeType(video)}`);
		}

		setLoadingEditarVideo(true);

		const dadosFormData = new FormData();

		dadosFormData.append('id_user', store.usuario.id);
		dadosFormData.append('video', video);

		const retUploadVideo = await apiFreelancer.uploadVideo.post(dadosFormData);

		if(retUploadVideo.status === 200) {
			msg.success('Vídeo atualizado com sucesso.');
			setStore({
				...store,
				usuario: {
					...store.usuario,
					video: retUploadVideo.data.url,
				}
			});
			setStateDados({
				...stateDados,
				video: retUploadVideo.data.url,
			});
		} else {
			msgs([retUploadVideo]);
		}

		setLoadingEditarVideo(false);
	}

	/*
	** Salvar foto
	** ================================================== */
	const editarFoto = async e => {

		if(loadingEditarFoto) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(!stateFotoCapturada || stateFotoCapturada === '') {
			msg.info('Nenhuma ação necessária.');
			return;
		}

		if(stateFotoCapturada && stateFotoCapturada !== '') {
			const extensao = extensaoString(stateFotoCapturada);
			if(extensao) {
				var foto = await fetch(stateFotoCapturada).then(r => r.blob());

				if(!foto.name) {
					foto = blobToFile(foto, `foto.${extensaoPorMimeType(foto)}`);
				}

				const dadosFormData = new FormData();

				dadosFormData.append('id_user', store.usuario.id);
				dadosFormData.append('photo', foto);

				setLoadingEditarFoto(true);

				const retUploadFoto = await api.users.uploadPhoto.post(dadosFormData);

				if(retUploadFoto.status === 200) {
					const dadosLogado = isLogado();
					salvarSessaoLocal({
						...dadosLogado,
						usuario: {
							...dadosLogado.usuario,
							photo: retUploadFoto.data.url,
						}
					});
					setStore({
						...store,
						usuario: {
							...dadosLogado.usuario,
							photo: `${retUploadFoto.data.url}?id=${hash()}`
						}
					});
					setStateDados({
						...stateDados,
						photo: `${retUploadFoto.data.url}?id=${hash()}`,
					});
					msg.success('Foto atualizada com sucesso');
					setStateFotoCapturada(false);
				} else {
					msgs([retUploadFoto]);
				}
			} else {
				msg.error('Não foi possível identificar o tipo de arquivo.');
			}
		}

		setLoadingEditarFoto(false);
	};

	/*
	** Salvar habilidades e área
	** ================================================== */
	const editarHabilidades = async () => {
		if(arrGetCheckeds(stateHabilidades).length < 3) {
			msg.error('Selecione no mínimo 3 habilidades nessa área.');
			return;
		}

		if(!stateDadosTempHabilidades.id_area || stateDadosTempHabilidades.id_area === '') {
			msg.error('Selecione sua categoria.');
			return;
		}

		if(!stateDadosTempHabilidades.seniority || stateDadosTempHabilidades.seniority === '') {
			msg.error('Selecione seu nível de conhecimento nessa área.');
			return;
		}

		setLoadingEditarHabilidades(true);
		const retUpdateFreelancer = await apiFreelancer.update.put({
			id: store.usuario.id_freelancer,
			id_area: stateDadosTempHabilidades.id_area,
			seniority: stateDadosTempHabilidades.seniority,
		});
		if(retUpdateFreelancer.status === 200) {
			await apiEditarHabilidades({idFreelancer: store.usuario.id_freelancer, loadingEditarHabilidades, setLoadingEditarHabilidades, stateHabilidades});
			setStateDados({
				...stateDados,
				id_area: stateDadosTempHabilidades.id_area,
				name_area: stateDadosTempHabilidades.name_area,
				seniority: stateDadosTempHabilidades.seniority,
			});
		} else {
			msgs([retUpdateFreelancer]);
		}
		setLoadingEditarHabilidades(false);
	}

	/*
	** Aceites
	** ================================================== */
	const definirDadosAceites = dadosFreelancer => {
		setStateDadosTempAceites({
			accept_jobs_mail: dadosFreelancer.accept_jobs_mail,
			accept_jobs_whatsapp: dadosFreelancer.accept_jobs_whatsapp,
			accept_newsletter: dadosFreelancer.accept_newsletter,
		});
	}

	/*
	** Redes Sociais
	** ================================================== */
	const getRedesSociais = dadosFreelancer => {
		apiGetRedesSociaisFreelancer({idUsuario: dadosFreelancer.id_user, setStateRedesSociaisFreelancer, setLoadingGetRedesSociaisFreelancer, showMsg: false});
	}

	/*
	** Habilidades
	** ================================================== */
	const getsHabilidades = dadosFreelancer => {
		apiGetAreas({setLoadingGetAreas, setStateAreas});
		apiGetHabilidadesChecked({idFreelancer: dadosFreelancer.id, idArea: dadosFreelancer.id_area, setLoadingGetHabilidades, setStateHabilidades, showMsg: false});
		apiGetSenioridades({setLoadingGetSenioridades, setStateSenioridades});
		setStateDadosTempHabilidades({
			id_area: dadosFreelancer.id_area,
			name_area: dadosFreelancer.name_area,
			seniority: dadosFreelancer.seniority,
		});
	}

	const cpfCnpjOuDocumento = () => {
		if(validarCpf(stateDados.cpf_cnpj, false)) {
			return <LinhaItem titulo="CPF" desc={`${formatarCPF(stateDados.cpf_cnpj)}`} alt />
		}

		if(validarCnpj(stateDados.cpf_cnpj, false)) {
			<LinhaItem titulo="CNPJ" desc={`${formatarCNPJ(stateDados.cpf_cnpj)}`} alt />
		}

		<LinhaItem titulo="Documento" desc={stateDados.cpf_cnpj || '(não informado)'} alt />
	}

	const scrollTo = () => {
		if(window.outerWidth <= 980) {
			scroller.scrollTo('perfil', {
				duration: 800,
				delay: 0,
				smooth: 'ease',
				offset: -20,
			});
		}
	}

	// const alterarPerfilPublico = async (status) => {

	// 	if(loadingAlterarPerfilPublico) {
	// 		msg.warning('Por favor, aguarde...');
	// 		return false;
	// 	}

	// 	setLoadingAlterarPerfilPublico(true);

	// 	const ret = await apiFreelancer.update.put({
	// 		id: store.usuario.id_freelancer,
	// 		public: status,
	// 	});

	// 	if(ret.status === 200) {
	// 		msg.success('Visibilidade do perfil alterada com sucesso');
	// 		setStatePerfilPublico(status);
	// 		setShowPortalConfirmacaoPerfilPublico(false);
	// 	} else {
	// 		msgs([ret]);
	// 	}

	// 	setLoadingAlterarPerfilPublico(false);
	// }

	const editarRedesSociais = async () => {

		if(loadingEditarRedesSociais) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingEditarRedesSociais(true);

		const ret = await apiFreelancerSocial.insertMany.post({
			id_user: store.usuario.id,
			dados: stateRedesSociaisFreelancer.map((val => {
				return {
					...val,
					type: 0,
				}
			}))
		});

		if(ret.status === 200) {
			msg.success('Redes sociais atualizadas com sucesso');
		} else {
			msgs([ret]);
		}

		setLoadingEditarRedesSociais(false);
	}

	return (
		<>
			<div className="pg-perfil base-full">
				<div className="conteudo-box">
					<div className="titulo-box titulo-box-res">
						<div className="flex flex-center-v">
							<h3 className="titulo">Perfil</h3>
							{BYPASS_PERFIL_STEPS ? <Link to="/perfil/passo-a-passo" className="a margin-l-20 fs-15">[Passo a passo]</Link> : null}
							{DEV ? <Link to={`/freelancer/${store.usuario.id_freelancer}`} className="a margin-l-20 fs-15">[Perfil público (DEV)]</Link> : null}
						</div>
					</div>

					{loadingGetDados ? (
						<Loading
							texto="Carregando dados, aguarde..."
							wrap="padding-30"
						/>
					) : (
						_stateDados.status === 200 ? (
							<div className="pg-perfil-wrapper">
								{!stateDados.finish && !BYPASS_PERFIL_STEPS ? (
									<div className="pg-perfil-header">
										<div className="base-m">
											<div className="base-content">
												<div className="pg-perfil-header-inner">
													<div className="box-txt">
														<h3>Olá, {stateDados.name}</h3>
														<div>Parece que você ainda não finalizou o passo a passo do seu perfil. Para acessar essa seção e liberar o menu de navegação, finalize seu cadastro.</div>
														<div className="margin-t-15"><Link to="/perfil/passo-a-passo">Clique aqui para finalizar</Link></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									<>
										<div className="pg-perfil-header">
											<div className="base-m">
												<div className="base-content">
													<div className="pg-perfil-header-inner">
														<div>
															<ImgTituloDesc
																titulo={`${stateDados.name}`}
																desc={
																	<div className="fs-14 desc">
																		{stateDados.title && stateDados.title !== '' ? (
																			<div className="strong">{stateDados.title}</div>
																		) : null}
																		<div className="margin-t-10">
																			<div>Categoria: {stateDados.name_area}</div>
																			<div>Nível de conhecimento: {stateDados.seniority_level || '(não informado)'}</div>
																			<div>ID de profissional: {stateDados.id}</div>
																		</div>
																	</div>
																}
																img={stateDados.photo ? caminhoFoto(stateDados.photo).url : imgUserPic}
																classNameTitulo="titulo strong"
																classNameDesc="td-desc"
																classNameImgBox="foto-wrap"
																responsivo={true}
																tamanho="110px"
																classNameBg="bg"
																preview={false}
																classNameImg="cursor-pointer"
																onClickImg={e => setShowPortalFoto(true)}
															/>
														</div>
														<div className="pg-perfil-header-dir">
															<BtnIconeDesc
																titulo="Visualizar perfil"
																desc="Veja como seu perfil é visualizado"
																icone={imgIconePerfil}
																iconeCor="#2596e1"
																classNameTitulo="color-blue-alt"
																className="hover-underline"
																onClick={e => setShowPortalPerfil(true)}
																alt
															/>
															{stateDados.active ? (
																<BtnIconeDesc
																	classNameWrap="margin-t-15"
																	titulo="Disponível para novos jobs"
																	desc="Clique para editar"
																	icone={imgIconeSucesso}
																	iconeCor="#229d3e"
																	classNameTitulo="color-green"
																	className="hover-underline"
																	onClick={e => setShowPortalDisponibilidade(true)}
																	alt
																/>
															) : (
																<BtnIconeDesc
																	classNameWrap="margin-t-15"
																	titulo="Indisponível para novos jobs"
																	desc="Clique para editar"
																	icone={imgIconeErro}
																	iconeCor="#E74C3C"
																	classNameTitulo="color-red"
																	className="hover-underline"
																	onClick={e => setShowPortalDisponibilidade(true)}
																	alt
																/>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="pg-perfil-inner">
											<div className="base-m">
												<div className="base-content">
													<div className="pg-perfil-conteudo-box">
														<div className="pt-perfil-sidebar">
															<FiltrosWrap
																textoShowFiltros="Exibir menus"
																textoHideFiltros="Ocultar menus"
															>
																{LINKS_SECOES_SIDEBAR_FREELANCER.map((secaoLoop, secaoKey) => {
																	return (
																		<div className="pt-perfil-sidebar-secao" key={secaoKey}>
																			<h3>{secaoLoop.titulo}</h3>
																			<div className="pt-perfil-sidebar-secao-conteudo">
																				<ul>
																					{secaoLoop.links.map((val, key) => {
																						if(!val) return <></>;
																						return (
																							<li key={key}>
																								<Link onClick={scrollTo} to={`/perfil?secao=${val.link}`} className={`a ${stateGet.secao === val.link || (stateGet.secao === '' && val.link === 'resumo') ? 'pt-perfil-sidebar-item-ativo' : ''}`}>{val.texto}</Link>
																							</li>
																						)
																					})}
																				</ul>
																			</div>
																		</div>
																	)
																})}
															</FiltrosWrap>
														</div>
														<div id="perfil" className="pg-perfil-conteudo">
															<div className="pg-perfil-conteudo-inner">
																<div className="pg-perfil-inner-1">
																	{(!stateGet.secao || stateGet.secao === '' || stateGet.secao === 'resumo') && (
																		<BoxWrap2
																			titulo="Resumo do perfil"
																			classNameWrap="pg-perfil-box"
																			alt
																		>
																			<div>
																				<h3 className="margin-0">Sobre você</h3>
																				<div className="sep-t margin-t-10 padding-t-10">
																					{stateDados.description && stateDados.description !== '' ? (
																						<div className="citacao no-wrap-line">{stateDados.description}</div>
																					) : (
																						<>Nenhuma descrição adicionada.</>
																					)}
																				</div>
																			</div>
																			<div className="margin-t-25">
																				<h3 className="margin-0">Dados pessoais</h3>
																				<div className="sep-t margin-t-10 padding-t-10">
																					<LinhaItem titulo="Nome" desc={stateDados.name || '(não informado)'} alt />
																					<LinhaItem titulo="Título" desc={stateDados.title || '(não informado)'} alt />
																					<LinhaItem titulo="E-mail" desc={stateDados.email} alt />
																					<LinhaItem titulo="Data de nascimento" desc={stateDados.birth_date && stateDados.birth_date !== '' ? (`${dataApiParaBr(stateDados.birth_date)}${validarDataAPI(stateDados.birth_date) ? ` (${dataHaQuantoTempo(stateDados.birth_date)})` : ''}`) : '(não informado)'} alt />
																					{cpfCnpjOuDocumento()}
																					<LinhaItem titulo="Localidade" desc={`${stateDados.city} / ${stateDados.state}`} alt />
																				</div>
																			</div>
																			<div className="margin-t-25">
																				<h3 className="margin-0">Informações de jobs</h3>
																				<div className="sep-t margin-t-10 padding-t-10">
																					<LinhaItem titulo="Disponibilidade" desc={stateDados.active ? <span className="color-green">Disponível</span> : <span className="color-red">Indisponível</span>} alt />
																					<LinhaItem titulo="Senioridade" desc={stateDados.seniority_level} alt />
																					<LinhaItem titulo="Jobs no total" desc={stateDados.tasks && stateDados.tasks.tasks_total ? stateDados.tasks.tasks_total : '(Nenhum até o momento)'} alt />
																					{/* <LinhaItem titulo="Valor hora" desc={formatarDinheiro(stateDados.price, 'R$')} alt /> */}
																				</div>
																			</div>
																			<div className="margin-t-25">
																				<h3 className="margin-0">Outras informações</h3>
																				<div className="sep-t margin-t-10 padding-t-10">
																					<LinhaItem titulo="ID de profissional" desc={stateDados.id} alt />
																					<LinhaItem titulo="Data de cadastro" desc={`${dataApiParaBr(stateDados.created_at)} (há ${dataHaQuantoTempo(stateDados.created_at)})`} alt />
																					<LinhaItem titulo="Última atualização" desc={`${dataApiParaBr(stateDados.updated_at)} (há ${dataHaQuantoTempo(stateDados.updated_at)})`} alt />
																				</div>
																			</div>
																		</BoxWrap2>
																	)}
																	{/* {stateGet.secao === 'perfil-publico' && (
																		<BoxWrap2
																			titulo="Perfil público"
																			classNameWrap="pg-perfil-box"
																			alt
																		>
																			<div>
																				<div><strong>Este é o link para o seu perfil público:</strong></div>
																				<div className="inline-flex flex-center-v">
																					<a href={`${URL}/freelancer/${store.usuario.id_freelancer}`} target="_blank" rel="noreferrer">
																						{URL}/freelancer/{store.usuario.id_freelancer}
																					</a>

																					<Tip tip={
																						<div
																							onClick={() => {
																								navigator.clipboard.writeText(`${URL}/freelancer/${store.usuario.id_freelancer}`);
																								msg.success('Link copiado com sucesso');
																							}}
																							className="cursor-pointer margin-l-5"
																						>
																							<Icone imagem={imgIconeCopy} tamanho="20px" cor="#282541" />
																						</div>}>Copiar link do perfil público
																					</Tip>
																				</div>

																				<div className="margin-t-15"><strong>Ativar ou desativar perfil público:</strong></div>
																				<div>
																					<Toggler
																						_={{
																							checked: statePerfilPublico,
																							onChange: e => {
																								setShowPortalConfirmacaoPerfilPublico(true);
																								// setStatePerfilPublico(!statePerfilPublico);
																							},
																						}}
																						className="margin-t-10"
																					/>
																				</div>
																			</div>
																		</BoxWrap2>
																	)} */}
																	{stateGet.secao === 'dados' && (
																		<BoxWrap2
																			titulo="Dados pessoais"
																			btnTitulo="Editar dados"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																			onClickBtn={() => setShowPortalDadosPessoais(true)}
																		>
																			<LinhaItem
																				titulo="Nome completo"
																				desc={stateDados.name && stateDados.name !== '' ? stateDados.name : '(não informado)'}
																				alt
																			/>
																			<LinhaItem
																				titulo="Data de nascimento"
																				desc={stateDados.birth_date && stateDados.birth_date !== '' ? (`${dataApiParaBr(stateDados.birth_date)}${validarDataAPI(stateDados.birth_date) ? ` (${dataHaQuantoTempo(stateDados.birth_date)})` : ''}`) : '(não informado)'}
																				className="sep-t margin-t-10 padding-t-10"
																				alt
																			/>
																			<div className="sep-t margin-t-10 padding-t-10">
																				{cpfCnpjOuDocumento()}
																			</div>
																			<LinhaItem
																				titulo="País"
																				desc={getKey(statePaises, stateDados.id_country, 'id').name || '(não informado)'}
																				className="sep-t margin-t-10 padding-t-10"
																				alt
																			/>
																			<LinhaItem
																				titulo="Telefone"
																				desc={stateDados.phone}
																				className="sep-t margin-t-10 padding-t-10"
																				alt
																			/>
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'apresentacao' && (
																		<BoxWrap2
																			titulo="Carta de apresentação"
																			btnTitulo="Editar dados"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																			onClickBtn={() => setShowPortalCartaApresentacao(true)}
																			alt
																		>
																			{stateDados.description && stateDados.description !== '' ? (
																				<div className="citacao no-wrap-line">{stateDados.description}</div>
																			) : (
																				<>Nenhuma descrição adicionada.</>
																			)}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'jobs' && (
																		<BoxWrap2
																			titulo="Dados para os Jobs"
																			btnTitulo="Editar dados"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																			onClickBtn={() => setShowPortalDadosJobs(true)}
																		>
																			<LinhaItem
																				titulo="Título profissional"
																				desc={stateDados.title}
																				alt
																			/>
																			{/* <LinhaItem
																				titulo="Valor hora"
																				desc={formatarDinheiro(stateDados.price, 'R$')}
																				className="sep-t margin-t-10 padding-t-10"
																				alt
																			/> */}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'acessos' && (
																		<BoxWrap2
																			titulo="Acesso"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																		>
																			<LinhaItem
																				titulo="E-mail"
																				desc={
																					<>
																						{stateDados.email}
																						<span className="a strong margin-l-20" onClick={() => setShowPortalEmail(true)}>alterar e-mail</span>
																					</>
																				}
																				alt
																			/>
																			<LinhaItem
																				titulo="Senha"
																				desc={
																					<>
																						••••••••
																						<span className="a strong margin-l-20" onClick={() => setShowPortalSenha(true)}>alterar senha</span>
																					</>
																				}
																				className="sep-t margin-t-10 padding-t-10"
																				alt
																			/>
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'foto' && (
																		<BoxWrap2
																			titulo="Foto de perfil"
																			classNameWrap="pg-perfil-box"
																			alt
																		>
																			<PtFoto
																				stateDados={stateDados}
																				setStateDados={setStateDados}
																				stateFotoCapturada={stateFotoCapturada}
																				setStateFotoCapturada={setStateFotoCapturada}
																			/>
																			{stateFotoCapturada && stateFotoCapturada !== '' ? (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: editarFoto
																						}}
																						loading={loadingEditarFoto}
																					/>
																				</div>
																			) : null}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'video' && (
																		<BoxWrap2
																			titulo="Vídeo de apresentação"
																			classNameWrap="pg-perfil-box"
																		>
																			<PtVideo
																				stateDados={stateDados}
																				setStateDados={setStateDados}
																				stateVideoUrl={stateVideoUrl}
																				setStateVideoUrl={setStateVideoUrl}
																			/>
																			{stateVideoUrl && stateVideoUrl !== '' ? (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: editarVideo
																						}}
																						loading={loadingEditarVideo}
																					/>
																				</div>
																			) : null}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'dados-empresa' && (
																		<BoxWrap2
																			titulo="Dados da sua empresa"
																			btnTitulo="Editar dados"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																			onClickBtn={() => setShowPortalDadosEmpresa(true)}
																		>
																			{loadingGetDadosEmpresa ? (
																				<Loading alt />
																			) : (
																				Object.keys(stateDadosEmpresa).length ? (
																					<div>
																						<LinhaItem
																							titulo="CNPJ"
																							desc={formatarCNPJ(stateDadosEmpresa.cnpj)}
																							alt
																						/>
																						<LinhaItem
																							titulo="Razão Social"
																							desc={stateDadosEmpresa.razao_social}
																							className="sep-t margin-t-10 padding-t-10"
																							alt
																						/>
																						<LinhaItem
																							titulo="Nome Fantasia"
																							desc={stateDadosEmpresa.fantasy_name}
																							className="sep-t margin-t-10 padding-t-10"
																							alt
																						/>
																						<LinhaItem
																							titulo="Endereço da Sede"
																							desc={stateDadosEmpresa.address}
																							className="sep-t margin-t-10 padding-t-10"
																							alt
																						/>
																					</div>
																				) : (
																					<div>Nenhum informação encontrada. <BtnAdd titulo="Adicionar" onClick={e => setShowPortalDadosEmpresa(true)} /></div>
																				)
																			)}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'dados-bancarios' && (
																		<BoxWrap2
																			titulo="Dados bancários"
																			btnTitulo="Editar dados"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																			onClickBtn={() => setShowPortalDadosBancarios(true)}
																		>
																			{loadingGetDadosBancarios ? (
																				<Loading alt />
																			) : (
																				Object.keys(stateDadosBancarios).length ? (
																					<div>
																						<LinhaItem
																							titulo="Tipo de pagamento selecionado"
																							desc={stateDadosBancarios.payment_type}
																							alt
																						/>
																						{/* Dados bancários */}
																						{stateDadosBancarios.id_payment_type === 1 ? (
																							<div>
																								<LinhaItem
																									titulo="Banco"
																									desc={stateDadosBancarios.name}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="Títular da conta"
																									desc={stateDadosBancarios.full_name}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="CPF ou CNPJ do títular"
																									desc={stateDadosBancarios.cpf}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="Agência"
																									desc={stateDadosBancarios.agency}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="Conta"
																									desc={stateDadosBancarios.account}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																							</div>
																						) : null}
																						{/* PIX */}
																						{stateDadosBancarios.id_payment_type === 4 ? (
																							<div>
																								<LinhaItem
																									titulo="Tipo de chave"
																									desc={pixExibicao(stateDadosBancarios.pix_type)}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="Chave"
																									desc={stateDadosBancarios.pix_value}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																							</div>
																						) : null}
																						{/* Internacional */}
																						{stateDadosBancarios.id_payment_type === 3 ? (
																							<div>
																								<LinhaItem
																									titulo="Full name or account holder"
																									desc={stateDadosBancarios.full_name}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="SWIFT / BIC code"
																									desc={stateDadosBancarios.swift_bic_code}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																								<LinhaItem
																									titulo="IBAN / Account number"
																									desc={stateDadosBancarios.iban_account_number}
																									className="sep-t margin-t-10 padding-t-10"
																									alt
																								/>
																							</div>
																						) : null}
																					</div>
																				) : (
																					<div>Nenhum informação encontrada. <BtnAdd titulo="Adicionar" onClick={e => setShowPortalDadosBancarios(true)} /></div>
																				)
																			)}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'endereco' && (
																		<BoxWrap2
																			titulo="Endereço para contato"
																			btnTitulo="Editar dados"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																			onClickBtn={() => setShowPortalEndereco(true)}
																		>
																			<div>
																				<LinhaItem
																					titulo="País"
																					desc={getKey(statePaises, stateDados.id_country, 'id').name || '(não informado)'}
																					alt
																				/>
																				<LinhaItem
																					titulo="Logradouro"
																					desc={stateDados.street && stateDados.street !== '' ? stateDados.street : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																				<LinhaItem
																					titulo="Número"
																					desc={stateDados.number && stateDados.number !== '' ? stateDados.number : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																				<LinhaItem
																					titulo="Complemento"
																					desc={stateDados.complement && stateDados.complement !== '' ? stateDados.complement : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																				<LinhaItem
																					titulo="Bairro"
																					desc={stateDados.district && stateDados.district !== '' ? stateDados.district : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																				<LinhaItem
																					titulo="Cidade"
																					desc={stateDados.city && stateDados.city !== '' ? stateDados.city : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																				<LinhaItem
																					titulo="Estado"
																					desc={stateDados.state && stateDados.state !== '' ? stateDados.state : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																				<LinhaItem
																					titulo="CEP"
																					desc={stateDados.zip_code && stateDados.zip_code !== '' ? stateDados.zip_code : '(não informado)'}
																					className="sep-t margin-t-10 padding-t-10"
																					alt
																				/>
																			</div>
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'redes-sociais' && (
																		<BoxWrap2
																			titulo="Redes Sociais"
																			btnImg={imgIconeEditar}
																			classNameWrap="pg-perfil-box"
																		>
																			<SecaoRedesSociais
																				subtitulo="Informe as redes sociais que você usa para fins profissionais. Isso nos ajuda a conhecer melhor o seu trabalho."
																				stateRedesSociais={stateRedesSociaisFreelancer}
																				setStateRedesSociais={setStateRedesSociaisFreelancer}
																				loadingGetRedesSociais={loadingGetRedesSociaisFreelancer}
																				classNameBox="box-1"
																			/>
																			{!loadingGetRedesSociaisFreelancer && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: editarRedesSociais,
																						}}
																						loading={loadingEditarRedesSociais}
																					/>
																				</div>
																			)}
																		</BoxWrap2>
																	)}
																	{/* {DEV ? (
																		<div className="margin-15 flex">
																			<div className="a">Excluir perfil</div>
																		</div>
																	) : null} */}
																	{stateGet.secao === 'habilidades' && (
																		<BoxWrap2
																			titulo="Área e Habilidades"
																			classNameWrap="pg-perfil-box"
																		>
																			<Select2
																				label="Categoria"
																				req
																				tip="Qual área você tem experiência e está focado atualmente."
																				_={{
																					value: stateDadosTempHabilidades.id_area,
																					options: stateAreas,
																					onChange: async e => {
																						setStateDadosTempHabilidades({
																							...stateDadosTempHabilidades,
																							id_area: e.value,
																							name_area: e.label,
																						});
																						const retGetHabilidades = await apiGetHabilidades({
																							idArea: e.value,
																							setLoadingGetHabilidades: setLoadingGetHabilidades,
																							setStateHabilidades: setStateHabilidades,
																						});
																						if(retGetHabilidades.status === 200) {
																							msg.info('Selecione suas habilidades nessa área.');
																						}
																						setShowPortalHabilidades(true);
																					}
																				}}
																				loading={loadingGetAreas || loadingGetHabilidades || loadingEditarHabilidades}
																			/>
																			<Tip
																				tip={
																					<h3 className="margin-t-20 margin-b-0">
																						<span>Qual seu nível de experiência nessa área?</span>
																						<div className="q"></div>
																					</h3>
																				}
																				info
																			>
																				As habilidades correspondem às suas competências técnicas. Elas se relacionam ao conhecimento que você adquiriu com suas experiências e formações profissionais. Por exemplo: graduação, curso técnico, domínio de outros idiomas, metodologias de trabalho e especializações.
																			</Tip>
																			<div className="cpnt-passo3-box-check margin-t-15 relative">
																				{loadingEditarHabilidades ? (
																					<Loading absolute alt />
																				) : null}
																				{loadingGetSenioridades ? (
																					<div className="inline-flex">
																						<Loading alt />
																					</div>
																				) : (
																					stateSenioridades.map((val, key) => {
																						return (
																							<div key={key} className="margin-t-5">
																								<Radio2
																									label={senioridadeFreelancer(val.value)}
																									_={{
																										checked: val.value === stateDadosTempHabilidades.seniority ? true : false,
																										onChange: () => {
																											setStateDadosTempHabilidades({
																												...stateDadosTempHabilidades,
																												seniority: val.value,
																											});
																										}
																									}}
																								/>
																							</div>
																						)
																					})
																				)}
																			</div>
																			<ListaSecao
																				titulo="Habilidades nessa área"
																				subtitulo="Escolha no mínimo 3 e no máximo 10"
																				textoNenhumItemEncontrado="Nenhuma habilidade selecionada"
																				classNameWrap="margin-t-20"
																				stateDados={stateHabilidades}
																				setStateDados={setStateHabilidades}
																				keyTitulo="name"
																				onClickAdd={() => setShowPortalHabilidades(true)}
																				loading={loadingGetHabilidades || loadingEditarHabilidades}
																			/>
																			{!loadingGetHabilidades && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: editarHabilidades,
																						}}
																						loading={loadingEditarHabilidades}
																					/>
																				</div>
																			)}
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'idiomas' && (
																		<ListaSecao
																			titulo="Idiomas"
																			classNameTituloBox="box-alt-titulo"
																			subtitulo="Escolha no mínimo 1"
																			textoNenhumItemEncontrado="Nenhum idioma selecionado"
																			stateDados={stateIdiomas}
																			setStateDados={setStateIdiomas}
																			subLabel="name_level"
																			textoSubLabelInvalido="informe o nível"
																			onClickAdd={() => setShowPortalIdiomas(true)}
																			loading={loadingGetIdiomas}
																			after={
																				!loadingGetIdiomas && (
																					<div className="pg-perfil-btn-box">
																						<Button2
																							_={{
																								value: 'Salvar dados',
																								onClick: e => apiEditarIdiomas({idFreelancer: store.usuario.id_freelancer, loadingEditarIdiomas, setLoadingEditarIdiomas, stateIdiomas})
																							}}
																							loading={loadingEditarIdiomas}
																						/>
																					</div>
																				)
																			}
																		/>
																	)}
																	{stateGet.secao === 'segmentos' && (
																		<ListaSecao
																			titulo="Segmentos de interesse"
																			classNameTituloBox="box-alt-titulo"
																			subtitulo="Escolha no mínimo 3 e no máximo 10"
																			textoNenhumItemEncontrado="Nenhum segmento selecionado"
																			stateDados={stateSegmentos}
																			setStateDados={setStateSegmentos}
																			keyTitulo="name"
																			onClickAdd={() => setShowPortalSegmentos(true)}
																			loading={loadingGetSegmentos}
																			after={
																				!loadingGetSegmentos && (
																					<div className="pg-perfil-btn-box">
																						<Button2
																							_={{
																								value: 'Salvar dados',
																								onClick: e => apiEditarSegmentos({idFreelancer: store.usuario.id_freelancer, loadingEditarSegmentos, setLoadingEditarSegmentos, stateSegmentos})
																							}}
																							loading={loadingEditarSegmentos}
																						/>
																					</div>
																				)
																			}
																		/>
																	)}
																	{stateGet.secao === 'competencias' && (
																		<ListaSecao
																			titulo="Competências"
																			classNameTituloBox="box-alt-titulo"
																			subtitulo="Escolha no máximo 5"
																			textoNenhumItemEncontrado="Nenhuma competência selecionada"
																			stateDados={stateCompetencias}
																			setStateDados={setStateCompetencias}
																			keyTitulo="name"
																			onClickAdd={() => setShowPortalCompetencias(true)}
																			loading={loadingGetCompetencias}
																			after={
																				!loadingGetCompetencias && (
																					<div className="pg-perfil-btn-box">
																						<Button2
																							_={{
																								value: 'Salvar dados',
																								onClick: e => apiEditarCompetencias({idFreelancer: store.usuario.id_freelancer, loadingEditarCompetencias, setLoadingEditarCompetencias, stateCompetencias})
																							}}
																							loading={loadingEditarCompetencias}
																						/>
																					</div>
																				)
																			}
																		/>
																	)}
																	{stateGet.secao === 'experiencias' && (
																		<>
																			<SecaoExperiencias
																				stateExperiencias={stateExperiencias}
																				setStateExperiencias={setStateExperiencias}
																				loadingGetExperiencias={loadingGetExperiencias}
																				classNameTituloBox="box-alt-titulo"
																				classNameListaWrap=""
																				classNameBox="box-1"
																			/>
																			{!loadingGetExperiencias && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: e => apiEditarExperiencias({idFreelancer: store.usuario.id_freelancer, loadingEditarExperiencias, setLoadingEditarExperiencias, stateExperiencias})
																						}}
																						loading={loadingEditarExperiencias}
																					/>
																				</div>
																			)}
																		</>
																	)}
																	{stateGet.secao === 'formacoes' && (
																		<>
																			<SecaoFormacoes
																				stateFormacoes={stateFormacoes}
																				setStateFormacoes={setStateFormacoes}
																				loadingGetFormacoes={loadingGetFormacoes}
																				classNameTituloBox="box-alt-titulo"
																				classNameListaWrap=""
																				classNameBox="box-1"
																			/>
																			{!loadingGetFormacoes && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: e => apiEditarFormacoes({idFreelancer: store.usuario.id_freelancer, loadingEditarFormacoes, setLoadingEditarFormacoes, stateFormacoes})
																						}}
																						loading={loadingEditarFormacoes}
																					/>
																				</div>
																			)}
																		</>
																	)}
																	{stateGet.secao === 'certificados' && (
																		<>
																			<SecaoCertificados
																				stateCertificados={stateCertificados}
																				setStateCertificados={setStateCertificados}
																				loadingGetCertificados={loadingGetCertificados}
																				classNameTituloBox="box-alt-titulo"
																				classNameListaWrap=""
																				classNameBox="box-1"
																			/>
																			{!loadingGetCertificados && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: e => apiEditarCertificados({idFreelancer: store.usuario.id_freelancer, loadingEditarCertificados, setLoadingEditarCertificados, stateCertificados})
																						}}
																						loading={loadingEditarCertificados}
																					/>
																				</div>
																			)}
																		</>
																	)}
																	{stateGet.secao === 'premios' && (
																		<>
																			<SecaoPremios
																				statePremios={statePremios}
																				setStatePremios={setStatePremios}
																				loadingGetPremios={loadingGetPremios}
																				classNameTituloBox="box-alt-titulo"
																				classNameListaWrap=""
																				classNameBox="box-1"
																			/>
																			{!loadingGetPremios && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: e => apiEditarPremios({idFreelancer: store.usuario.id_freelancer, loadingEditarPremios, setLoadingEditarPremios, statePremios})
																						}}
																						loading={loadingEditarPremios}
																					/>
																				</div>
																			)}
																		</>
																	)}
																	{stateGet.secao === 'portfolios' && (
																		<>
																			<SecaoPortfolios
																				statePortfolios={statePortfolios}
																				setStatePortfolios={setStatePortfolios}
																				loadingGetPortfolios={loadingGetPortfolios}
																				classNameTituloBox="box-alt-titulo"
																				classNameListaWrap=""
																				classNameBox="box-1"
																			/>
																			{!loadingGetPortfolios && (
																				<div className="pg-perfil-btn-box">
																					<Button2
																						_={{
																							value: 'Salvar dados',
																							onClick: e => apiEditarPortfolios({idFreelancer: store.usuario.id_freelancer, loadingEditarPortfolios, setLoadingEditarPortfolios, statePortfolios})
																						}}
																						loading={loadingEditarPortfolios}
																					/>
																				</div>
																			)}
																		</>
																	)}
																	{stateGet.secao === 'aceites' && (
																		<BoxWrap2
																			titulo="Gerenciar notificações"
																			classNameWrap="pg-perfil-box"
																		>
																			<PtNotificacoes
																				stateDados={stateDadosTempAceites}
																				setStateDados={setStateDadosTempAceites}
																			/>
																			<div className="pg-perfil-btn-box">
																				<Button2
																					_={{
																						value: 'Salvar dados',
																						onClick: e => apiEditarAceites({
																							idFreelancer: store.usuario.id_freelancer,
																							loadingEditarDados,
																							setLoadingEditarDados,
																							stateDados: {
																								accept_jobs_mail: stateDadosTempAceites.accept_jobs_mail ? 1 : 0,
																								accept_jobs_whatsapp: stateDadosTempAceites.accept_jobs_whatsapp ? 1 : 0,
																								accept_newsletter: stateDadosTempAceites.accept_newsletter ? 1 : 0,
																							},
																							cb: e => {
																								setStateDados({
																									...stateDados,
																									...e,
																								});
																							}
																						})
																					}}
																					loading={loadingEditarDados}
																				/>
																			</div>
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'ajuda' && (
																		<BoxWrap2
																			titulo="Central de ajuda"
																			classNameWrap="pg-perfil-box fs-16"
																		>
																			<div>
																				<h3 className="margin-b-5">Está com dúvidas? Acesse nossa central de ajuda:</h3>
																				<div>
																					<a href="https://comunidadecrowd.zendesk.com/hc/pt-br" target="_blank" rel="noreferrer" className="a">
																						https://comunidadecrowd.zendesk.com/hc/pt-br
																					</a>
																				</div>
																			</div>
																			<div>
																				<h3 className="margin-b-5">Principais dúvidas:</h3>
																				<ul>
																					<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/360058118853-Como-eu-vejo-os-briefings-publicados-" target="_blank" rel="noreferrer" className="a">Como eu vejo as propostas de jobs na plataforma?</a></li>
																					<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/360058147073-Como-fa%C3%A7o-para-ser-selecionado-para-um-briefing-" target="_blank" rel="noreferrer" className="a">Como faço para ser selecionado para uma proposta de job?</a></li>
																					<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/360058147093-Fui-selecionado-para-um-briefing-Como-devo-proceder-" target="_blank" rel="noreferrer" className="a">Fui selecionado para um job. Como devo proceder?</a></li>
																					<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/360056265654-O-que-%C3%A9-um-card-" target="_blank" rel="noreferrer" className="a">O que é um job?</a></li>
																					<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/360056293754-Por-que-eu-tenho-que-emitir-Nota-Fiscal-para-receber-pelo-meu-trabalho-" target="_blank" rel="noreferrer" className="a">Por que eu tenho que emitir Nota Fiscal para receber pelo meu trabalho?</a></li>
																					<li><a href="https://comunidadecrowd.zendesk.com/hc/pt-br/articles/360056293354-Quando-o-pagamento-%C3%A9-efetuado-" target="_blank" rel="noreferrer" className="a">Quando o pagamento é efetuado?</a></li>
																				</ul>
																			</div>
																			<div>
																				<h3 className="margin-b-5">Não encontrou o que precisa?</h3>
																				<div>
																					Entre em contato conosco: <a href="https://comunidadecrowd.zendesk.com/hc/pt-br/requests/new" target="_blank" rel="noreferrer" className="a">envie sua solicitação ao suporte clicando aqui</a>. Na sua mensagem, informe o ID da Proposta de Job ou do Job relacionado à sua dúvida.
																				</div>
																			</div>
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'termos' && (
																		<BoxWrap2
																			titulo="Termos de uso"
																			classNameWrap="pg-perfil-box fs-16"
																		>
																			<div>
																				<div className="desc">Você pode acessar nossos termos de uso sempre que precisar através do link:</div>
																				<div>
																					<Link to="/termos-de-uso">{window.location.origin}/termos-de-uso</Link>
																				</div>
																			</div>
																		</BoxWrap2>
																	)}
																	{stateGet.secao === 'politica' && (
																		<BoxWrap2
																			titulo="Política de privacidade"
																			classNameWrap="pg-perfil-box fs-16"
																		>
																			<div>
																				<div className="desc">Você pode acessar nossa política de privacidade sempre que precisar através do link:</div>
																				<div>
																					<Link to="https://crowd.br.com/politica-de-privacidade-da-crowd/">https://crowd.br.com/politica-de-privacidade-da-crowd/</Link>
																				</div>
																			</div>
																		</BoxWrap2>
																	)}
																	{/* {stateGet.secao === 'excluir-perfil' && (
																		<BoxWrap2
																			titulo="Excluir perfil de forma definitiva"
																			classNameWrap="pg-perfil-box fs-16"
																		>
																			<div className="box-txt">
																				<div className="desc">Se você não pretende mais continuar cadastrado na plataforma da CROWD</div>
																				<div className="margin-v-15">
																					<Btn
																						_={{
																							value: 'Excluir meu perfil',
																							onClick: () => setShowPortalConfirmacaoExclusaoPerfil(true)
																						}}
																					/>
																				</div>
																				<div className="desc color-red"><strong>Aviso:</strong> antes da exclusão será necessário finalizar todas as pendências como jobs em execução e pagamentos, caso contrário, seu perfil não será excluído.</div>
																			</div>
																		</BoxWrap2>
																	)} */}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)
					)}
				</div>
			</div>

			<PortalEmailAcessoFormEdicao
				id={store.usuario.id}
				idFreelancer={store.usuario.id_freelancer}
				tipo="freelancer"
				show={showPortalEmail}
				onClose={() => setShowPortalEmail(false)}
				desc="Insire o e-mail que você deseja utilizar para acessar a plataforma e também para receber as mensagens e propostas de novos jobs."
				onSuccess={e => {
					setStateDados({
						...stateDados,
						email: e.dados,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalSenhaAcessoFormEdicao
				id={store.usuario.id}
				idFreelancer={store.usuario.id_freelancer}
				tipo="freelancer"
				show={showPortalSenha}
				onClose={() => setShowPortalSenha(false)}
				onSuccess={e => {
					setShowPortalSenha(false);
				}}
				closeOnEsc={false}
			/>

			<PortalDisponibilidadeJobs
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalDisponibilidade}
				onClose={() => setShowPortalDisponibilidade(false)}
				onSuccess={e => {
					setStateDados({
						...stateDados,
						active: e.dados,
					});
					setShowPortalDisponibilidade(false);
				}}
				closeOnEsc={false}
			/>

			<PortalDisponibilidadeRemuneracaoFormEdicao
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalDadosJobs}
				onClose={() => setShowPortalDadosJobs(false)}
				onSuccess={e => {
					setShowPortalDadosJobs(false);
					setStateDados(e.dados);
				}}
				closeOnEsc={false}
			/>

			<PortalDadosPessoaisFormEdicao
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalDadosPessoais}
				onClose={() => setShowPortalDadosPessoais(false)}
				onSuccess={e => {
					setShowPortalDadosPessoais(false);
					setStateDados({
						...stateDados,
						...e.dados
					});
				}}
				closeOnEsc={false}
			/>

			<PortalDadosEmpresaFormEdicao
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalDadosEmpresa}
				onClose={() => setShowPortalDadosEmpresa(false)}
				onSuccess={e => {
					setShowPortalDadosEmpresa(false);
					setStateDadosEmpresa(e.dados);
				}}
				closeOnEsc={false}
			/>

			<PortalDadosBancarioFormEdicao
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalDadosBancarios}
				onClose={() => setShowPortalDadosBancarios(false)}
				onSuccess={e => {
					setShowPortalDadosBancarios(false);
					setStateDadosBancarios({
						...stateDadosBancarios,
						...e.dados,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalEnderecoFormEdicao
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalEndereco}
				onClose={() => setShowPortalEndereco(false)}
				onSuccess={e => {
					setShowPortalEndereco(false);
					setStateDados({
						...stateDados,
						...e.dados
					});
				}}
				closeOnEsc={false}
			/>

			<PortalCartaApresentacaoFormEdicao
				idFreelancer={store.usuario.id_freelancer}
				show={showPortalCartaApresentacao}
				onClose={() => setShowPortalCartaApresentacao(false)}
				onSuccess={e => {
					setShowPortalCartaApresentacao(false);
					setStateDados({
						...stateDados,
						description: e.dados,
					});
				}}
				closeOnEsc={false}
			/>

			<Portal
				titulo="Selecione suas habilidades"
				show={showPortalHabilidades}
				onClose={() => setShowPortalHabilidades(false)}
				className="w-600"
			>
				<ListaComCheckbox2
					stateItens={stateHabilidades}
					setStateItens={setStateHabilidades}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalHabilidades(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<Portal
				titulo="Selecione os idiomas que você tem conhecimento"
				show={showPortalIdiomas}
				onClose={() => setShowPortalIdiomas(false)}
				className="w-600"
			>
				<SelecaoIdiomasNiveis
					itens={stateIdiomas}
					itensSub={stateIdiomasNiveis}
					onChange={setStateIdiomas}
				/>
				<div className="form-bottom">
					<Button2
						_={{
							value: 'Fechar',
							onClick: e => setShowPortalIdiomas(false)
						}}
					/>
				</div>
			</Portal>

			<Portal
				titulo="Selecione os segmentos de interesse"
				show={showPortalSegmentos}
				onClose={() => setShowPortalSegmentos(false)}
				className="w-600"
			>
				<div className="fs-14 padding-b-25">Gosta de trabalhar somente em projetos de Educação? Moda? Varejo? Agora é a hora de escolher as áreas que você deseja receber jobs. Selecione até 10 segmentos de seu interesse.</div>
				<ListaComCheckbox2
					stateItens={stateSegmentos}
					setStateItens={setStateSegmentos}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalSegmentos(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<Portal
				titulo="Selecione suas competências"
				show={showPortalCompetencias}
				onClose={() => setShowPortalCompetencias(false)}
				className="w-600"
			>
				<div className="fs-14 padding-b-25">Sua vivência influencia suas competências e demonstram quem você é. Por isso, selecione até 5 itens na lista abaixo.</div>
				<ListaComCheckbox2
					stateItens={stateCompetencias}
					setStateItens={setStateCompetencias}
					after={
						<div className="form-bottom">
							<Button2
								_={{
									value: 'Fechar',
									onClick: e => setShowPortalCompetencias(false)
								}}
							/>
						</div>
					}
				/>
			</Portal>

			<PortalPerfilFreelancer
				id={store.usuario.id_freelancer}
				show={showPortalPerfil}
				onClose={() => setShowPortalPerfil(false)}
			/>

			<PortalFoto
				show={showPortalFoto}
				foto={stateDados.photo}
				onClose={() => {
					setShowPortalFoto(false);
				}}
			/>

			{/* <PortalConfirmacao
				show={showPortalConfirmacaoPerfilPublico}
				titulo={`Deseja realmente tornar o seu perfil ${statePerfilPublico ? 'privado' : 'público'}?`}
				desc={statePerfilPublico ? (
					<>
						<div>Ao tornar o seu perfil <span className="color-red">privado</span>, ele não será visível para os mecanismos de buscas, e você também não conseguirá compartilhá-lo nas redes sociais.</div>
						<div className="margin-t-15">Deseja continuar?</div>
					</>
				) : (
					<>
						<div>Ao tornar o seu perfil <span className="color-green">público</span>, ele será visível para os mecanismos de buscas, e você também conseguirá compartilhá-lo nas redes sociais.</div>
						<div className="margin-t-15">Deseja continuar?</div>
					</>
				)}
				onClose={e => {
					setShowPortalConfirmacaoPerfilPublico(false);
				}}
				onClickCancelar={e => {
					setShowPortalConfirmacaoPerfilPublico(false);
				}}
				onClickConfirmar={() => {
					alterarPerfilPublico(statePerfilPublico ? 0 : 1);
				}}
				loadingConfirmar={loadingAlterarPerfilPublico}
			/> */}

			<PortalExclusaoPerfilProfissional
				show={showPortalConfirmacaoExclusaoPerfil}
				onClose={e => {
					setShowPortalConfirmacaoExclusaoPerfil(false);
				}}
				onClickCancelar={e => {
					setShowPortalConfirmacaoExclusaoPerfil(false);
				}}
				onClickConfirmar={() => {
					// alterarPerfilPublico(statePerfilPublico ? 0 : 1);
				}}
				// loadingConfirmar={loadingAlterarPerfilPublico}
			/>

		</>
	);
};

export { Perfil };
