import React, { useEffect, useState } from 'react';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções

import { api } from "@_api/api";
import { Btn } from "@_cpnt/Btn/Btn/Btn";
import { data } from "@_func/date";
import { msgs, msgStatus } from "@_func/request";
import Estrelas from "@cpnt/Estrelas/Estrelas";
import PortalFreelancerAvaliacoes from "../PortalFreelancerAvaliacoes/PortalFreelancerAvaliacoes";
import c from './PortalFreelancerAvaliacao.module.scss';

const PortalFreelancerAvaliacao = props => {
	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = c['portal'],
		onClickBtn,
	} = props;

	const [loading, setLoading] = useState(true);
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});
	const [showPortalTodasAvaliacoes, setShowPortalTodasAvaliacoes] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		initDelay();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const initDelay = () => {
		if(show) {
			setLoading(true);
			setStateDados({});
			setTimeout(() => {
				inicializarGets();
			}, 500);
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const ret = await api.tasks.getOne.get(id);

		_setStateDados(msgStatus(ret));

		if(ret.status === 200) {
			setStateDados(ret.data);
		} else {
			msgs([ret]);
		}

		setLoading(false);
	}

	return (
		<>
			<Portal
				titulo={'Avaliação do job'}
				show={show}
				closeOnEsc={closeOnEsc}
				onClose={() => {
					onClose();
				}}
				className={portalClassName}
			>
				{loading ? (
					<Loading
						texto="Carregando dados, aguarde..."
						wrap="padding-30"
					/>
				) : (
					<div className={wrap}>
						<div className={className}>
							{_stateDados.status === 200 ? (
								<div>
									<div className={`${c['box']}`}>
										<div className={`${c['info']}`}>
											<div><strong>Job:</strong> {stateDados.title}</div>
											<div><strong>Aprovado em:</strong> {data.full({data: stateDados.approval_at})}</div>
											<div><strong>Avaliado por:</strong> {stateDados.rating_user_name || '(não avaliado)'}</div>
										</div>
										<div className={`${c['dados']}`}>
											<div className={`${c['total']}`}>{stateDados.rating_stars ? `${stateDados.rating_stars} de 5` : <strong className={`${c['nao-avaliado']}`}>Não avaliado</strong>}</div>
											<div className={`${c['avaliacoes']}`}>
												<Estrelas
													qtd={stateDados.rating_stars}
													tamanho="120px"
													wrap="flex flex-center"
													corAtivo="#FDA63F"
												/>
											</div>
										</div>
										{stateDados.rating_stars ? (
											<div className={`${c['descricao']}`}>
												{stateDados.rating_comment && stateDados.rating_comment !== '' ? stateDados.rating_comment : (stateDados.rating_stars ? '(nenhum comentário enviado)' : '(não avaliado)')}
											</div>
										) : (
											<div className={`${c['descricao']}`}>
												<div>Esse job ainda não foi avaliado.</div>
												<Btn
													_={{
														value: 'Avaliar agora',
														onClick: () => onClickBtn(id)
													}}
													className="margin-t-15"
												/>
											</div>
										)}
									</div>
									<div className="form-bottom">
										<Btn
											_={{
												value: 'Visualizar todas',
												onClick: () => {
													onClose();
													setShowPortalTodasAvaliacoes(true);
												}
											}}
											tipo="alt"
										/>
										<Btn
											_={{
												value: 'Fechar',
												onClick: () => {
													onClose();
												},
											}}
										/>
									</div>
								</div>
							) : (
								<Info
									texto={_stateDados.msg}
									icone="erro"
								/>
							)}
						</div>
					</div>
				)}
			</Portal>

			<PortalFreelancerAvaliacoes
				show={showPortalTodasAvaliacoes}
				id={stateDados.id_freelancer}
				onClose={() => {
					setShowPortalTodasAvaliacoes(false);
				}}
				closeOnEsc
			/>
		</>
	);
};

export { PortalFreelancerAvaliacao };
