import React from 'react';

// css
import './Foto.css';

// Imagens
import imgUserPicDefault from '@img/userpic.png';

// Funções
import { cssBg } from '@func/utils';

const Foto = props => {
	const {
		wrap = '',
		onClick,
		className = '',
		imagem,
		placeholder = imgUserPicDefault,
		onClose,
		alt,
		tamanho,
	} = props;

	return (
		<div className={`cpnt-foto-wrap ${wrap}`}>
			<div className={`cpnt-foto ${className} ${onClick ? 'cursor-pointer' : ''}`} {...(onClick ? {onClick: onClick} : {})}>
				<div className={`cpnt-foto-img ${alt ? 'cpnt-foto-img-alt' : ''}`} {...(tamanho ? {style: {width: tamanho, height: tamanho}} : {})}>
					<div className="bg fill" style={cssBg(placeholder)}></div>
					<div className="bg fill" style={cssBg(imagem)}></div>
				</div>
			</div>
			{onClose && <div className="x x-small" onClick={onClose}></div>}
		</div>
	);
};

export default Foto;